import React, { Component } from 'react';
import InvoiceDetailsBase, {
  mapStateToProps,
  mapDispatchToProps
} from '../../sites/Invoice/details';
import Preloader from '../../components/Preloader';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import DeleteIcon from '@material-ui/icons/Delete';
import { InvoiceModel } from '../../helper/models';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { deleteInvoice, updateInvoice } from '../../actions/invoice';
import { updateInvoiceNumber } from '../../helper/number-gen';
import DeleteModal from '../../components/Modal/DeleteModal';
import InvoiceItemsCard from '../../components/Cards/InvoiceItemsCard';
import CustomerCard from '../../components/Cards/InvoiceDetailCards/CustomerCard';
import StatusCard from '../../components/Cards/InvoiceDetailCards/StatusCard';
import MailCard from '../../components/Cards/InvoiceDetailCards/MailCard';
import InvoiceStartModal from '../../components/InvoiceStart/modal';
import InvoiceMarkPayedModal from '../../components/InvoiceMarkPayed/modal';
import TemplateRenderer from '../../components/TemplateRenderer';

let thumbImg;
const styles = theme => ({
  state: {
    position: 'absolute',
    left: 10,
    top: 10
  },

  main: {
    margin: '30px auto',
    maxWidth: 1200,
    width: '90%',
    display: 'flex',
    justifyContent: 'space-around'
  },

  thumb: {
    position: 'relative',
    boxSizing: 'border-box',
    width: '50%',
    minWidth: 400,
    maxWidth: 500,
    margin: 10
  },

  pdf: {
    width: '100%',
    padding: 20
  },

  center: {
    textAlign: 'center'
  },

  deleteBtn: {
    color: 'rgb(255, 114, 114)'
  },

  backBtn: {
    position: 'absolute',
    top: -40,
    left: 0
  },

  thumbImg: {
    width: '100%',
    height: 'auto'
  },

  leftIcon: {
    marginRight: theme.spacing.unit
  },

  invoiceInfo: {
    width: '40%'
  }
});

class InvoiceDetails extends InvoiceDetailsBase {
  constructor(props) {
    super(props);
    this.state.thumbLoaded = false;
    this.state.thumbUrl = false;
    this.state.startModalOpen = false;
    this.state.startingInvoice = false;
    this.state.markInvoicePayedModalOpen = false;
    this.state.markInvoicePayedModal = false;
    this.state.deleteModalOpen = false;
  }

  componentDidMount() {
    this.updateThumbnailFromInvoice();
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateThumbnailFromInvoice();
  }

  openMarkInvoicePayedModal = () => {
    this.setState({ markInvoicePayedModalOpen: true });
  };

  markInvoicePayedModalCancel = () => {
    this.setState({ markInvoicePayedModalOpen: false });
  };

  openStartInvoiceModal = () => {
    this.setState({ startModalOpen: true });
  };

  startModalCancel = () => {
    this.setState({ startModalOpen: false });
  };

  startInvoice = data => {
    let { startData, invoiceData } = data;
    let updatePayload = Object.assign(invoiceData, {
      startData,
      type: 'running'
    });
    this.setState({ startingInvoice: true });
    this.props.dispatch(
      updateInvoice(this.state.invoice.id, updatePayload, err => {
        if (err) {
          this.setState({ startingInvoice: false, startingInvoiceErr: err });
        } else {
          updateInvoiceNumber(invoiceData.invoiceId)
            .then(() => {
              this.setState({ startingInvoice: false, startModalOpen: false });
            })
            .catch(err => {
              this.setState({
                startingInvoice: false,
                startingInvoiceErr: err
              });
            });
        }
      })
    );
  };

  markInvoicePayed = data => {
    let updatePayload = { payments: [data], type: 'finished' };
    this.setState({ markingInvoicePayed: true });
    this.props.dispatch(
      updateInvoice(this.state.invoice.id, updatePayload, err => {
        if (err) {
          this.setState({
            markInvoicePayedModalOpen: false,
            markingInvoicePayed: false,
            markingInvoicePayedErr: err
          });
        } else {
          this.setState({
            markInvoicePayedModalOpen: false,
            markingInvoicePayed: false
          });
        }
      })
    );
  };

  updateThumbnailFromInvoice() {
    if (this.props.initialLoadDone && this.state.invoice.data().thumbnail) {
      let url = this.state.invoice.data().thumbnail.url;
      if (this.state.thumbUrl !== url) this.loadNewThumbnail(url);
    } else if (this.state.thumbLoaded) {
      this.setState({ thumbLoaded: false });
    }
  }

  loadNewThumbnail(url) {
    this.setState({ thumbLoaded: false, thumbUrl: url });
    thumbImg = new Image();
    thumbImg.onload = () => {
      this.setState({ thumbLoaded: true });
    };
    thumbImg.src = url;
  }

  render() {
    let { classes, account } = this.props;
    let {
      startModalOpen,
      startingInvoice,
      markInvoicePayedModalOpen,
      markingInvoicePayed,
      thumbLoaded,
      thumbUrl
    } = this.state;

    // If loading
    if (!this.props.initialLoadDone) {
      return <Preloader />;
    }

    // Get Invoice
    let invoice = this.state.invoice;

    // If not found
    if (!invoice && this.props.initialLoadDone) {
      return (
        <Redirect
          to={{ pathname: '/404', state: { from: this.props.location } }}
        />
      );
    } else if (!invoice) {
      return <Preloader />;
    }
    let data = InvoiceModel(invoice.data());
    let mail = data.mails && data.mails.length > 0 ? data.mails[0] : null;
    let mailShouldSend = data.startData && data.startData.sendMail;
    let showThumbnail =
      !!data.thumbnail && (!data._reRender && data.renderStatus === 'done');

    return (
      <div className={classes.main}>
        <div className={classes.thumb}>
          <Button
            className={classes.backBtn}
            color="secondary"
            onClick={this.goBack.bind(this)}
          >
            <KeyboardArrowLeftIcon className={classes.leftIcon} /> Zurück
          </Button>
          {
            <Paper className={classes.pdf}>
              <TemplateRenderer
                data={data}
                thumbnail={showThumbnail ? data.thumbnail.url : false}
              />
            </Paper>
          }
        </div>

        <div className={classes.invoiceInfo}>
          <StatusCard
            status={data.type}
            onStartInvoice={this.openStartInvoiceModal}
            onMarkInvoiceAsPayed={this.openMarkInvoicePayedModal}
            onChangeInvoice={this.startChange}
            editMode={this.state.editMode}
            onSave={this.saveChanges}
            onDiscard={this.cancelChanges}
            saving={this.state.saving}
          />
          <CustomerCard
            customer={this.state.customer}
            readOnly={!this.state.editMode}
            selectedAddress={data.data.selectedAddress}
            loading={!this.props.customerInitialLoadDone}
          />
          {data.type !== 'draft' ? (
            <MailCard mail={mail} shouldSend={mailShouldSend} />
          ) : null}
          <InvoiceItemsCard
            readOnly={!this.state.editMode}
            noTitle
            onChange={this.onItemsChange}
            items={
              this.state.editMode ? this.state.changedItems : data.data.items
            }
          />
          {data.type === 'draft' ? (
            <div className={classes.center}>
              <Button
                className={classes.deleteBtn}
                onClick={() => {
                  this.setState({ deleteModalOpen: true });
                }}
              >
                <DeleteIcon className={classes.leftIcon} /> Löschen
              </Button>
            </div>
          ) : null}
        </div>

        <DeleteModal
          open={this.state.deleteModalOpen}
          ok={this.deleteInvoice}
          onClose={() => {
            this.setState({ deleteModalOpen: false });
          }}
        />
        <InvoiceStartModal
          subscription={account.data().subscriptionData}
          starting={startingInvoice}
          invoice={invoice}
          open={startModalOpen}
          onClose={this.startModalCancel}
          onStart={this.startInvoice}
        />
        <InvoiceMarkPayedModal
          markingAsPayed={markingInvoicePayed}
          invoice={invoice}
          open={markInvoicePayedModalOpen}
          onClose={this.markInvoicePayedModalCancel}
          onMarkPayed={this.markInvoicePayed}
        />
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InvoiceDetails)
);
