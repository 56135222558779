import React, { Component } from 'react';
import InvoiceMenu from './menus/invoice-menu';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  menuButton: {
    marginLeft: '-14px'
  }
});

class AppBarMenu extends Component {
  render() {
    if (this.props.history.location.pathname === '/invoices') {
      return <InvoiceMenu {...this.props} />;
    }
    return null;
  }
}

export default withStyles(styles)(AppBarMenu);
