import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styles from '../../pageStyles';
import img1 from './yoga.jpg';

class TaxInfo extends Component {
  state = {
    'invoiceData.taxNumber': '',
    'invoiceData.vatIndentNumber': '',
    'invoiceData.taxOffice': ''
  };

  componentDidMount() {
    this.props.onStatusChange(true);
  }

  onChange = key => e => {
    this.setState({
      [key]: e.target.value
    });
    this.props.onChange(this.state);
  };

  render() {
    let { classes } = this.props;
    return (
      <React.Fragment>
        <img src={img1} className={classes.image} />
        <h1 className={classes.h1}>Steuerinfos</h1>
        <h2 className={classes.h2}>Kannst du auch später nachtragen 👌</h2>
        <TextField
          autoFocus
          label={'Steuernummer'}
          placeholder={'181/815/08155'}
          type="text"
          value={this.state['invoiceData.taxNumber']}
          onChange={this.onChange('invoiceData.taxNumber')}
          fullWidth
        />
        <TextField
          autoFocus
          label={'USt.-Indentnummer'}
          placeholder={'DE999999999'}
          type="text"
          value={this.state['invoiceData.vatIndentNumber']}
          onChange={this.onChange('invoiceData.vatIndentNumber')}
          fullWidth
        />
        <TextField
          autoFocus
          label={'Finanzamt'}
          placeholder={'Finanzamt München Abt. II / III'}
          type="text"
          value={this.state['invoiceData.taxOffice']}
          onChange={this.onChange('invoiceData.taxOffice')}
          fullWidth
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TaxInfo);
