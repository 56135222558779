import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import async from 'async';
import DeleteModal from '../../components/Modal/DeleteModal';
import InvoiceInformationBase from '../../sites/Settings/invoice-information';
import InvoiceTemplateBase from '../../sites/Settings/invoice-template';
import InvoiceTemplateCard from '../../components/Cards/InvoiceTemplateCard';
import InvoiceInformationCards from '../../components/Cards/InvoiceInformationCards';
import Preloader from '../../components/Preloader';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  // Global overwritten styles from index.js
  desktopWrapper: {},
  wrap: {},
  actionBar: {},
  noChange: {},
  content: {},

  // Custom Styles
  flex: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '20px 0',
    height: 'calc(100% - 40px)'
  },
  invoiceTemplateCard: {
    width: '35%',
    height: '100%',
    overflowY: 'auto'
  },
  invoiceInformationCard: {
    width: '65%',
    height: '100%',
    overflowY: 'auto'
  }
});

class InvoiceTemplate extends InvoiceTemplateBase {
  render() {
    let { initalLoadDone, templates } = this.state;
    let { classes } = this.props;
    let templateContent;

    if (!initalLoadDone) {
      templateContent = <Preloader />;
    } else {
      templateContent = (
        <div className={classes.invoiceTemplateCard + ' scrollbar-hidden'}>
          {templates.map(template => {
            let d = template.data();
            return (
              <InvoiceTemplateCard
                used={this.isSelected(template)}
                key={template.id}
                img={d.thumbnail}
                title={d.title}
                description={d.description}
                onActivate={this.onActivate(template)}
                loading={this.state.loading}
              />
            );
          })}
        </div>
      );
    }

    return templateContent;
  }
}

class Invoice extends InvoiceInformationBase {
  render() {
    let {
      name,
      street,
      city,
      notice,
      institute,
      iban,
      bic,
      tel,
      fax,
      email,
      managingDirector,
      taxNumber,
      vatIndentNumber,
      taxOffice,
      saving,
      uploadingLogo,
      removingLogo,
      _logoUrl,
      saveVisible
    } = this.state;
    let { classes } = this.props;

    return (
      <div className={classes.wrap}>
        <div className={classes.actionBar}>
          {saveVisible ? (
            <React.Fragment>
              <Button onClick={this.save} variant="contained" color="primary">
                Speichern
              </Button>
              &nbsp;
              <Button onClick={this.discard} color="secondary">
                Verwerfen
              </Button>
              {this.state.saving ? <Preloader /> : null}
            </React.Fragment>
          ) : (
            <span className={classes.noChange}>Einstellungen aktuell</span>
          )}
        </div>
        <div className={classes.flex}>
          <InvoiceTemplate {...this.props} />
          <div className={classes.invoiceInformationCard + ' scrollbar-hidden'}>
            <InvoiceInformationCards
              noCard
              name={name}
              street={street}
              city={city}
              notice={notice}
              institute={institute}
              iban={iban}
              bic={bic}
              tel={tel}
              fax={fax}
              email={email}
              managingDirector={managingDirector}
              taxNumber={taxNumber}
              vatIndentNumber={vatIndentNumber}
              taxOffice={taxOffice}
              uploadingLogo={uploadingLogo}
              removingLogo={removingLogo}
              logoUrl={_logoUrl}
              onRemoveLogo={() => {
                this.setState({ removeLogoDialogOpen: true });
              }}
              onChange={this.onChange}
            />
          </div>
        </div>

        <DeleteModal
          open={this.state.removeLogoDialogOpen}
          ok={this.removeLogo}
          onClose={() => {
            this.setState({ removeLogoDialogOpen: false });
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Invoice);
