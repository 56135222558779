import React, { Component } from 'react';
import firebase from '../../../services/firebase';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Typography from '@material-ui/core/Typography';
import './styles.css';

const styles = theme => ({
  card: {
    background: theme.palette.primary.dark
  },

  content: {
    position: 'relative',
    color: theme.palette.common.white
  },

  closeBtn: {
    color: theme.palette.common.white,
    position: 'absolute',
    right: 0,
    top: 0
  },

  headline: {
    color: theme.palette.common.white
  },

  text: {
    color: theme.palette.grey['50']
  }
});

class CacheCardSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: '',
      visible: props.visible == false ? false : true,
      currentUser: firebase.auth().currentUser
    };
  }

  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.visible && !nextProps.visible) {
      this.setState({
        className: ' animated zoomOut'
      });
      setTimeout(() => {
        this.setState({
          visible: false
        });
      }, 180);
    }
  }

  render() {
    if (!this.state.visible) {
      return '';
    }

    const { classes } = this.props;

    return (
      <Card className={'simple-card ' + classes.card + this.state.className}>
        <CardContent className={classes.content}>
          <IconButton
            onClick={this.close.bind(this)}
            className={classes.closeBtn}
            color="secondary"
            aria-label="Menu"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" component="h2" className={classes.headline}>
            Superklasse !
          </Typography>
          <Typography className={classes.text} variant="body1">
            Du kannst MyVoiz ab sofort auch offline nutzen!
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(CacheCardSuccess);
