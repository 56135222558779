import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PageWrapper from '../PageWrapper';

export default class PrivateRoute extends Component {
  renderElem = props => {
    let {
      component: Component,
      hasAuth,
      updateHeaderContent,
      animateWrapper: AnimateWrapper = false,
      ...args
    } = this.props;

    if (hasAuth !== true) {
      return (
        <Redirect
          to={{ pathname: '/login', state: { from: this.props.location } }}
        />
      );
    }

    let AnimatedComponent = AnimateWrapper ? (
      <AnimateWrapper
        component={Component}
        componentArgs={Object.assign({}, this.props, props)}
      />
    ) : (
      <Component {...Object.assign({}, this.props, props)} />
    );
    return (
      <PageWrapper
        component={AnimatedComponent}
        beforeMount={() => {
          updateHeaderContent(null);
        }}
      />
    );
  };

  render() {
    let { component, hasAuth, animateWrapper, ...args } = this.props;

    return <Route {...args} render={this.renderElem} />;
  }
}
