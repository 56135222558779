import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MailIcon from '@material-ui/icons/Mail';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import './intro.css';

const ICONSIZE = { width: 226, height: 226 };

const styles = theme => ({
  slide1: {
    background: theme.palette.primary.light,
    color: theme.palette.common.white
  },
  slide2: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  slide3: {
    background: theme.palette.primary.dark,
    color: theme.palette.common.white
  },
  bigIcon: {
    display: 'block',
    margin: '0 auto',
    padding: '16vh 0 0 0'
  },
  headline: {
    textAlign: 'center',
    padding: '20px 20px'
  }
});

class Intro extends Component {
  state = {
    activeStep: 0,
    totalSteps: 3
  };

  onChangeIndex(index) {
    this.setState({
      activeStep: index
    });
  }

  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1
    });
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  };

  takeOff = () => {
    localStorage.setItem('introSeen', 1);
    this.props.history.push('/');
  };

  render() {
    const { classes, theme } = this.props;

    let nextButton;
    if (this.state.activeStep !== this.state.totalSteps - 1) {
      nextButton = (
        <Button size="small" onClick={this.handleNext}>
          Weiter
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      );
    } else {
      nextButton = (
        <Button size="small" color="primary" onClick={this.takeOff.bind(this)}>
          Starten
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      );
    }

    return (
      <div className="intro-wrapper">
        <SwipeableViews
          index={this.state.activeStep}
          onChangeIndex={this.onChangeIndex.bind(this)}
        >
          <div className={'slide ' + classes.slide1}>
            <MailIcon style={ICONSIZE} className={classes.bigIcon} />
            <h2 className={classes.headline}>
              Informiere Kunden mit Emails oder Briefen
            </h2>
          </div>
          <div className={'slide ' + classes.slide2}>
            <VisibilityIcon style={ICONSIZE} className={classes.bigIcon} />
            <h2 className={classes.headline}>
              Behalte Überblick über Rechnungen & Fälligkeiten
            </h2>
          </div>
          <div className={'slide ' + classes.slide3}>
            <FlightTakeoffIcon style={ICONSIZE} className={classes.bigIcon} />
            <h2 className={classes.headline}>Starte jetzt kostenlos!</h2>
          </div>
        </SwipeableViews>
        <MobileStepper
          type="dots"
          steps={this.state.totalSteps}
          position="static"
          activeStep={this.state.activeStep}
          className="stepper"
          nextButton={nextButton}
          backButton={
            <Button
              size="small"
              onClick={this.handleBack}
              disabled={this.state.activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Zurück
            </Button>
          }
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Intro);
