import client from '../helper/client';
import request from '../helper/request';
import firebase from './firebase';
import Firestore from './Firestore';
import Handlebars from 'handlebars';
import _ from 'lodash';

export default {
  isInit: false,
  snapshot: null,
  error: null,
  currDevice: null,
  apiClient: null,
  templates: {},
  _templateIdCache: {},

  __afterUpdate() {
    // Reset curr device cache
    this.currDevice = null;

    // Update Localstorage
    localStorage.setItem(
      'trustedDevice_' + firebase.auth().currentUser.uid,
      client.getFingerprintFromCache() +
        '_' +
        (this.getDevice('trusted') ? '1' : '0')
    );
    if (Firestore.currentlyPersistent && !this.getDevice('trusted')) {
      // Please Reload to disable persistance
    } else if (!Firestore.currentlyPersistent && this.getDevice('trusted')) {
      //  Please Reload to enable Persistance
    }
  },

  init() {
    return new Promise((resolve, reject) => {
      // Update Settings
      this.setupSettings()
        .then(() => {
          this.isInit = true;
          resolve();
        })
        .catch(error => {
          this.isInit = false;
          reject(error);
        });
    });
  },

  authorize() {
    return new Promise((resolve, reject) => {
      // Register Device
      this.registerDevice()
        .then(() => {
          this.isAuthorized = true;
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  isTrustedDeviceOfflineCheck() {
    let data = localStorage.getItem(
      'trustedDevice_' + firebase.auth().currentUser.uid
    );
    if (data) {
      let [clientId, trusted] = data.split('_');
      if (
        clientId === client.getFingerprintFromCache(false) &&
        trusted === '1'
      ) {
        return true;
      }
    }
    return false;
  },

  getTemplate(key = null) {
    return this.templates[key];
  },

  get(key = null, defaultValue = null) {
    if (!this.snapshot) {
      return null;
    }
    let data = this.snapshot.data();
    return key ? _.get(data, key, defaultValue) : data;
  },

  updateKey(key, value) {
    return this.update({
      [key]: value
    });
  },

  update(payload) {
    let _payload = Object.assign(
      { _userid: firebase.auth().currentUser.uid },
      payload
    );
    return new Promise((resolve, reject) => {
      Firestore.db()
        .collection('settings')
        .doc(this.snapshot.id)
        .update(_payload)
        .then(() => {
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  registerDevice() {
    return new Promise((resolve, reject) => {
      let fingerprint = client.getFingerprintFromCache();
      if (this.deviceRegistered(fingerprint)) {
        resolve();
      } else {
        let arr = this.get('devices');
        arr.push({
          fingerprint,
          trusted: false,
          openTrustModal: true,
          cacheCardSuccessVisible: false
        });
        this.updateKey('devices', arr)
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  },

  updateCurrentDevice(changes) {
    let arr = this.get('devices').map(dev => {
      if (dev.fingerprint === client.getFingerprintFromCache()) {
        return Object.assign(dev, changes);
      }
      return dev;
    });

    return this.updateKey('devices', arr);
  },

  getDevice(key = null) {
    if (!this.snapshot) {
      return null;
    }
    if (!this.currDevice) {
      this.currDevice = _.find(this.get('devices'), dev => {
        return dev.fingerprint === client.getFingerprintFromCache();
      });
    }

    return this.currDevice && key && key in this.currDevice
      ? this.currDevice[key]
      : this.currDevice;
  },

  setDeviceOpenTrustModal(state) {
    return this.updateCurrentDevice({ openTrustModal: state });
  },

  setDeviceOpenTrustModalSuccess(state) {
    return this.updateCurrentDevice({ cacheCardSuccessVisible: state });
  },

  setDeviceTrust(state) {
    return this.updateCurrentDevice({
      trusted: state,
      openTrustModal: false,
      cacheCardSuccessVisible: state
    });
  },

  setDeviceAsTrusted() {
    return this.setDeviceTrust(true);
  },

  setDeviceAsUntrusted() {
    return this.setDeviceTrust(false);
  },

  deviceRegistered(devFingerprint) {
    let registered = false;
    this.get('devices').forEach(device => {
      if (device.fingerprint === devFingerprint) {
        registered = true;
      }
    });
    return registered;
  },

  setupSettings() {
    let initialResolved = false;
    return new Promise((resolve, reject) => {
      var unsubscribe = Firestore.db()
        .collection('settings')
        .where('_userid', '==', firebase.auth().currentUser.uid)
        .onSnapshot(async querySnapshot => {
          if (querySnapshot.docs.length < 1) {
            if (!initialResolved) {
              // to run promise only once
              initialResolved = true;
              unsubscribe();
              reject('Not found');
            }
            return;
          }

          if (querySnapshot.docs[0].data().initialVerification !== true) {
            if (!initialResolved) {
              // to run promise only once
              initialResolved = true;
              unsubscribe();
              reject('Unverified');
            }
            return;
          }

          this.snapshot = querySnapshot.docs[0];
          await this.updateTemplateCache();
          this.__afterUpdate();
          if (!initialResolved) {
            initialResolved = true;
            resolve(this.snapshot);
          }
        });
    });
  },

  //////////////////////
  // Invoice Template //
  //////////////////////

  async updateTemplateCache() {
    const invoiceTemplateType = 'invoice-template';
    const invoiceTemplateId = this.get('invoiceTemplateId') || null;
    if (invoiceTemplateId === this._templateIdCache[invoiceTemplateType]) {
      return;
    }
    this._templateIdCache[invoiceTemplateType] = invoiceTemplateId;
    await this.loadTemplate(invoiceTemplateType, invoiceTemplateId);
  },

  async loadTemplate(type, templateId = null) {
    if (templateId) {
      const snapshot = await Firestore.db()
        .doc(`templates/${templateId}`)
        .get();
      if (!snapshot) {
        await this.loadDefaultTemplate(type);
      }
      this.templates[type] = Handlebars.compile(snapshot.data().html);
    } else {
      await this.loadDefaultTemplate(type);
    }
  },

  async loadDefaultTemplate(type) {
    const querySnapshot = await Firestore.db()
      .collection('templates')
      .where('type', '==', 'invoice-template')
      .where('public', '==', true)
      .where('isDefault', '==', true)
      .get();
    if (querySnapshot.empty) {
      return this.setState({ error: new Error('failed to load template') });
    }
    this.templates[type] = Handlebars.compile(
      querySnapshot.docs[0].data().html
    );
  },

  ///////////////
  // Bank Data //
  ///////////////

  saveBankData(provider, bankCredentials, additionalData) {
    return localStorage.setItem(
      'bankCredentials_' + firebase.auth().currentUser.uid + '_' + provider,
      JSON.stringify({ bankCredentials, additionalData })
    );
  },

  removeBankData(provider) {
    return localStorage.removeItem(
      'bankCredentials_' + firebase.auth().currentUser.uid + '_' + provider
    );
  },

  getBankData(provider) {
    return JSON.parse(
      localStorage.getItem(
        'bankCredentials_' + firebase.auth().currentUser.uid + '_' + provider
      )
    );
  },

  //////////////
  // API Keys //
  //////////////

  getAPIToken(cb) {
    return Firestore.db()
      .collection('apitoken')
      .where('_userid', '==', firebase.auth().currentUser.uid)
      .onSnapshot(cb);
  },

  addAPIToken() {
    return request
      .getClient()
      .then(client => client.__cloudfunctions.createAPIToken());
  }
};
