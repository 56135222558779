import React, { Component } from 'react';
import firebase from '../../../services/firebase';
import { convertToCash } from '../../../helper/calc';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Bar } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';

const chartOptions = {
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 20,
      bottom: 0
    }
  },

  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
        },
        ticks: {
          fontColor: 'white'
        }
      }
    ],
    yAxes: [
      {
        display: false
      }
    ]
  },

  legend: {
    display: false,

    labels: {
      // This more specific font property overrides the global property
      fontColor: 'white'
    }
  },

  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        return convertToCash(tooltipItem.yLabel) + ' €';
      }
    }
  }
};

const styles = theme => ({
  card: {
    background: theme.palette.primary['600']
  },

  content: {
    position: 'relative',
    color: theme.palette.common.white
  },

  headline: {
    color: theme.palette.common.white
  }
});

class TotalSalesChartCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: ['August', 'September', 'Oktober'],
        datasets: [
          {
            label: 'Einnahmen',
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
            borderColor: 'rgba(255, 255, 255, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 255, 255, 0.6)',
            hoverBorderColor: 'rgba(255, 255, 255, 1)',
            data: [59, 65, 59]
          }
        ]
      }
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <Card className={'simple-card ' + classes.card}>
        <CardContent className={classes.content}>
          <Typography variant="h5" component="h2" className={classes.headline}>
            Einnahmen
          </Typography>
          <Bar data={this.state.data} options={chartOptions} />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(TotalSalesChartCard);
