import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CircularProgress from '@material-ui/core/CircularProgress';
import Overlay from '../Overlay';
import PayDateCard from '../Cards/InvoiceMarkPayedCards/PayDateCard';
import PayNoticeCard from '../Cards/InvoiceMarkPayedCards/PayNoticeCard';
import PayAmountCard from '../Cards/InvoiceMarkPayedCards/PayAmountCard';
import { getItemsTotal } from '../../helper/calc';
import InvoiceMarkPayedBase from './index';

export default class InvoiceMarkPayedOverlay extends InvoiceMarkPayedBase {
  render() {
    if (!this.props.visible) return null;

    return (
      <Overlay
        realAppBar
        className="animated zoomIn"
        title="Bezahlt markieren"
        backIcon={<CloseIcon />}
        rightButtonIcon={
          this.props.markingAsPayed ? (
            <CircularProgress size={24} className="button-preloader" />
          ) : (
            <DoneAllIcon />
          )
        }
        rightButtonDisabled={this.props.markingAsPayed ? true : false}
        onBack={this.props.onBack || (() => {})}
        rightButtonEvent={() => {
          this.props.onMarkPayed(this.state);
        }}
      >
        <PayAmountCard amount={this.state.amount} />
        <PayDateCard date={this.state.date} onChange={this.onChange('date')} />
        <PayNoticeCard
          notice={this.state.notice}
          onChange={this.onChange('notice')}
        />
      </Overlay>
    );
  }
}
