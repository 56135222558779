import React, { Component } from 'react';
import ComponentWithHeader from '../../components/ComponentWithHeader';
import Typography from '@material-ui/core/Typography';
import Preloader from '../../components/Preloader';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { connect } from 'react-redux';
import { gotoCustomer, gotoCustomerAdd } from '../../helper/transition';
import { CustomerModel } from '../../helper/models';
import { withStyles } from '@material-ui/core/styles';
import ColorHash from 'color-hash';
import ContentCard from '../../components/Desktop/ContentCard';
var colorHash = new ColorHash();

const styles = theme => ({
  flex: {
    flex: 1
  },

  addBtn: {
    marginLeft: '10px'
  },

  leftIcon: {
    marginRight: 10
  }
});

class Customer extends ComponentWithHeader {
  state = {
    search: ''
  };

  headerProps = {
    searchKey: 'search'
  };

  openCustomer(id) {
    gotoCustomer(this.props.history, id);
  }

  filteredCustomers() {
    let { customers } = this.props;
    let { search } = this.state;
    if (!search) {
      return customers;
    }
    search = search.toLowerCase();
    return customers.filter(customer => {
      let data = customer.data();
      let fullName = (data.firstname + ' ' + data.lastname).toLowerCase();
      return fullName.indexOf(search) > -1 || data.cid.indexOf(search) > -1;
    });
  }

  renderHeader() {
    let { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant="h6" color="secondary">
          Kunden
        </Typography>
        <Button
          className={classes.addBtn}
          color="primary"
          onClick={() => gotoCustomerAdd(this.props.history)}
        >
          <PersonAddIcon className={classes.leftIcon} />
          Kunden hinzufügen
        </Button>
      </React.Fragment>
    );
  }

  render() {
    let { classes } = this.props;

    if (!this.props.initialLoadDone) {
      return <Preloader />;
    }

    let lastChar = '';
    let listItems = this.filteredCustomers()
      .map(record => {
        return { record, data: CustomerModel(record.data()) };
      })
      .sort((a, b) => {
        if (a.data.char > b.data.char) {
          return 1;
        }
        if (a.data.char < b.data.char) {
          return -1;
        }
        return 0;
      })
      .map(customerObject => {
        let { record: customer, data } = customerObject;
        let { color: backgroundColor } = data;
        let currChar = data.char !== lastChar ? data.char : ' ';
        lastChar = data.char;
        return (
          <ListItem
            button
            key={customer.id}
            onClick={this.openCustomer.bind(this, customer.id)}
          >
            <Avatar className="char-overview-avatar">{currChar}</Avatar>
            <Avatar style={{ backgroundColor }}>{data.char}</Avatar>
            <ListItemText
              primary={data.firstname + ' ' + data.lastname}
              secondary={'Kundenummer: ' + data.cid}
            />
          </ListItem>
        );
      });

    return <ContentCard content={<List>{listItems}</List>} />;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    initialLoadDone: state.customer.initialLoadDone,
    customers: state.customer.customers
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Customer)
);
