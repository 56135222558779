import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CircularProgress from '@material-ui/core/CircularProgress';
import Overlay from '../Overlay';
import PayDateCard from '../Cards/InvoiceMarkPayedCards/PayDateCard';
import PayNoticeCard from '../Cards/InvoiceMarkPayedCards/PayNoticeCard';
import PayAmountCard from '../Cards/InvoiceMarkPayedCards/PayAmountCard';
import { getItemsTotal } from '../../helper/calc';

export default class InvoiceMarkPayedBase extends Component {
  state = {
    amount: 0,
    date: new Date(),
    notice: ''
  };

  componentDidUpdate() {
    if (this.props.invoice) {
      let amount = getItemsTotal(this.props.invoice.data().data.items);
      if (amount !== this.state.amount) {
        this.setState({ amount });
      }
    }
  }

  onChange = key => val => {
    this.setState({ [key]: val });
  };
}
