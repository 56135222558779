import Fingerprint2 from 'fingerprintjs2';

export default {
  _fingerprint2: new Fingerprint2(),
  _fingerprintCache: null,

  getFingerprintFromCache(withRand = true) {
    if (this._fingerprintCache) {
      return withRand
        ? this._fingerprintCache
        : this._fingerprintCache.split('_')[0];
    } else {
      throw new Error('no fingerprint in cache available!');
    }
  },

  getInitialFingerprint() {
    return new Promise((resolve, reject) => {
      // Load Fingerprint from cache
      if (this._fingerprintCache) {
        return resolve(this._fingerprintCache);
      }

      // Load Fingerprint from Localstorage & make Trust
      if (localStorage.getItem('deviceId')) {
        let fingerprint = localStorage.getItem('deviceId');
        return this.trustedFingerprint(fingerprint).then(trusted => {
          if (!trusted) {
            console.log('Untrusted fingerprint');
          }

          this._fingerprintCache = fingerprint;
          resolve(fingerprint);
        });
      }

      // Generate new Fingerprint
      this.updateFingerprint().then(fingerprint => {
        this._fingerprintCache = fingerprint;
        return resolve(fingerprint);
      });
    });
  },

  updateFingerprint() {
    return new Promise((resolve, reject) => {
      this._fingerprintCache = null;
      this._fingerprint2.get(fingerprint => {
        localStorage.setItem('deviceId', fingerprint);
        resolve(fingerprint);
      });
    });
  },

  trustedFingerprint(fingerprint) {
    return new Promise((resolve, reject) => {
      this._fingerprint2.get(cfingerprint => {
        resolve(fingerprint === cfingerprint);
      });
    });
  },

  makeid(len = 8) {
    var text = '';
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < len; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
};
