import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InvoiceAddBase, {
  mapStateToProps,
  mapDispatchToProps
} from '../../sites/Invoice/add';
import Preloader from '../../components/Preloader';
import SelectCustomerCard from '../../components/Cards/SelectCustomerCard';
import InvoiceItemsCard from '../../components/Cards/InvoiceItemsCard';
import ContentCard from '../../components/Desktop/ContentCard';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = theme => ({
  btn: {
    marginTop: '-12px',
    marginRight: '10px'
  },

  container: {
    display: 'flex'
  },

  pane: {
    width: '50%'
  },

  flex: {
    flex: 1
  }
});

class InvoiceAdd extends InvoiceAddBase {
  render() {
    let { classes } = this.props;

    // If loading
    if (!this.props.initialLoadDone) {
      return <Preloader />;
    }

    let savePreloader = this.state.saving && (
      <CircularProgress size={24} className="button-preloader gray" />
    );

    return (
      <ContentCard
        topBarContent={
          <React.Fragment>
            <IconButton
              className={classes.btn}
              color="primary"
              onClick={this.goBack.bind(this)}
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography variant="h6" color="secondary">
              Neuer Entwurf
            </Typography>

            <span className={classes.flex} />

            <Button
              color="primary"
              disabled={this.state.saving}
              onClick={this.addAndClose.bind(this)}
            >
              Erstellen
              {savePreloader}
            </Button>
          </React.Fragment>
        }
        content={
          <div className={classes.container}>
            <div className={classes.pane}>
              <SelectCustomerCard
                shadowless
                onChange={this.customerChanged.bind(this)}
                customers={this.props.customers}
              />
            </div>
            <div className={classes.pane}>
              <InvoiceItemsCard
                shadowless
                noCard
                onChange={this.itemsChanged.bind(this)}
              />
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InvoiceAdd)
);
