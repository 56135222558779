import React, { Component } from 'react';
import ListItem from '../default';

export default class TitleListItem extends Component {
  render() {
    let props = Object.assign(
      {
        primary: this.props.data,
        placeholder: 'Titel',
        secondary: ''
      },
      this.props
    );

    return <ListItem {...props} />;
  }
}
