import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export default class Index extends Component {
  state = {
    introSeen: localStorage.getItem('introSeen')
  };

  render() {
    return this.state.introSeen ? (
      <Redirect
        to={{ pathname: '/login', state: { from: this.props.location } }}
      />
    ) : (
      <Redirect
        to={{ pathname: '/intro', state: { from: this.props.location } }}
      />
    );
  }
}
