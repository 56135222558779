import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MailTemplateBase from '../../sites/Settings/mail-template';
import MailInformationBase from '../../sites/Settings/mail-information';
import _ from 'lodash';
import Firestore from '../../services/Firestore';
import Preloader from '../../components/Preloader';
import InvoiceTemplateCard from '../../components/Cards/InvoiceTemplateCard';
import Settings from '../../services/Settings';
import Button from '@material-ui/core/Button';
import MailInformationCards from '../../components/Cards/MailInformationCards';

const styles = theme => ({
  // Global overwritten styles from index.js
  desktopWrapper: {},
  wrap: {},
  actionBar: {},
  noChange: {},
  content: {},

  // Custom Styles
  flex: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '20px 0',
    height: 'calc(100% - 40px)'
  },
  mailTemplateCard: {
    width: '35%',
    height: '100%',
    overflowY: 'auto'
  },
  mailInformationCard: {
    width: '65%',
    height: '100%',
    overflowY: 'auto'
  }
});

class MailTemplate extends MailTemplateBase {
  render() {
    let { classes } = this.props;
    let { initalLoadDone, templates } = this.state,
      content;

    if (!initalLoadDone) {
      content = <Preloader />;
    } else {
      content = (
        <div className={classes.mailTemplateCard + ' scrollbar-hidden'}>
          {templates.map(template => {
            let d = template.data();
            return (
              <InvoiceTemplateCard
                used={this.isSelected(template)}
                key={template.id}
                img={d.thumbnail}
                title={d.title}
                description={d.description}
                onActivate={this.onActivate(template)}
                loading={this.state.loading}
              />
            );
          })}
        </div>
      );
    }

    return content;
  }
}

class Mail extends MailInformationBase {
  render() {
    let {
      subject,
      text,
      senderName,
      senderMail,
      saving,
      saveVisible
    } = this.state;
    let { classes } = this.props;
    return (
      <div className={classes.wrap}>
        <div className={classes.actionBar}>
          {saveVisible ? (
            <React.Fragment>
              <Button onClick={this.save} variant="contained" color="primary">
                Speichern
              </Button>
              &nbsp;
              <Button onClick={this.discard} color="secondary">
                Verwerfen
              </Button>
              {saving ? <Preloader /> : null}
            </React.Fragment>
          ) : (
            <span className={classes.noChange}>Einstellungen aktuell</span>
          )}
        </div>
        <div className={classes.flex}>
          <MailTemplate {...this.props} />
          <div className={classes.mailInformationCard + ' scrollbar-hidden'}>
            <MailInformationCards
              noCard
              subject={subject}
              text={text}
              senderName={senderName}
              senderMail={senderMail}
              onChange={this.onChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Mail);
