import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import MailIcon from '@material-ui/icons/Mail';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Avatar from '@material-ui/core/Avatar';
import Moment from 'react-moment';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  card: {
    position: 'relative'
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  dateText: {
    marginTop: 12,
    fontSize: 18,
    color: theme.palette.text.secondary
  },
  mailInactive: {
    backgroundColor: theme.palette.grey['400'],
    color: 'white'
  },
  mailPending: {
    backgroundColor: theme.palette.primary.light,
    color: 'white'
  },
  mailActive: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  }
});

class MailCard extends Component {
  state = {
    loading: false
  };

  resendEmail = () => {
    this.setState({
      loading: true
    });
  };

  render() {
    const { loading } = this.state;
    const { classes, mail, shouldSend } = this.props;

    let mailStatus = mail ? mail.status : null;
    let title = 'E-Mail Benachrichtigung';
    let subtitle = '';
    let icon = <MailIcon />;
    let avatarClass = 'mailInactive';

    let canSendMail = false;

    if (mailStatus === 'opened') {
      subtitle = (
        <div>
          Geöffnet{' '}
          <Moment interval={30000} fromNow locale="de">
            {mail.openedAt.toDate()}
          </Moment>
        </div>
      );
      avatarClass = 'mailActive';
    } else if (mailStatus === 'sent' || mailStatus === 'pending') {
      subtitle = (
        <div>
          Gesendet{' '}
          <Moment interval={30000} fromNow locale="de">
            {mail.sentAt.toDate()}
          </Moment>
        </div>
      );
      canSendMail = mail.sentAt.toDate() < Date.now() - 1000 * 60 * 60 * 24 * 1;
      avatarClass = 'mailActive';
    } else if (mailStatus === null) {
      if (shouldSend) {
        subtitle = <div>Wird versendet ...</div>;
        avatarClass = 'mailPending';
      } else {
        subtitle = <div>Wird versendet ...</div>;
        avatarClass = 'mailInactive';
        // canSendMail = true;
      }
    }

    return (
      <Card className={'simple-card ' + classes.card}>
        <CardHeader
          avatar={
            <Avatar className={classes[avatarClass]} aria-label="Mail">
              {icon}
            </Avatar>
          }
          title={title}
          subheader={subtitle}
        />
        {canSendMail ? (
          <CardActions>
            <Button
              onClick={this.resendEmail}
              color="secondary"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress color="secondary" size={20} />
              ) : mailStatus ? (
                'E-Mail erneut senden'
              ) : (
                'E-Mail senden'
              )}
            </Button>
          </CardActions>
        ) : null}
      </Card>
    );
  }
}

export default withStyles(styles)(MailCard);
