import React, { Component } from 'react';
import BusinessIcon from '@material-ui/icons/Business';
import ListItem from '../default';

export default class CompanyListItem extends Component {
  render() {
    let props = Object.assign(
      {
        icon: <BusinessIcon />,
        primary: this.props.data,
        placeholder: 'Unternehmen',
        secondary: ''
      },
      this.props
    );

    return <ListItem {...props} />;
  }
}
