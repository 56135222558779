import React, { Component } from 'react';
import MUIAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import preloadImage from '../../helper/preload';
import Preloader from '../../components/Preloader';
import './styles.css';

export default class Overlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      imageLoading: false,
      headerClassName: '',
      backgroundImage: props.backgorundImage || false,
      backgroundColor: props.backgorundColor || false
    };
  }

  componentDidMount() {
    if (!this.props.realAppBar) {
      this.refs.overlay.onscroll = () => {
        let visibleHeaderHeight =
          this.refs.header.offsetHeight +
          this.refs.header.getClientRects()[0].top;

        if (visibleHeaderHeight < 80 && !this.props.noColorChange) {
          this.setState({
            headerClassName: 'blue'
          });
        } else {
          this.setState({
            headerClassName: ''
          });
        }

        if (visibleHeaderHeight < this.refs.appbar.offsetHeight) {
          this.refs.appbar.style.top =
            visibleHeaderHeight - this.refs.appbar.offsetHeight + 'px';
        } else {
          this.refs.appbar.style.top = '0px';
        }
      };

      // Image Preloading
      if (this.props.backgroundImage || this.props.hasBackgroundImage) {
        this.setState({
          imageLoading: true
        });
      }
      if (this.props.backgroundImage) {
        this.loadBackgroundImage(this.props.backgroundImage);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.backgroundImage !== nextProps.backgroundImage) {
      this.loadBackgroundImage(nextProps.backgroundImage);
    }
  }

  loadBackgroundImage(image) {
    if (image.hasOwnProperty('$$typeof')) {
      return this.setState({
        imageComponent: image,
        image: null,
        imageLoading: false
      });
    }
    this.setState({
      imageComponent: null,
      imageLoading: true
    });
    preloadImage(image).then(() => {
      this.setState({
        imageComponent: null,
        imageLoading: false,
        image: image
      });
    });
  }

  onBack() {
    if ('onBack' in this.props) {
      this.props.onBack();
    }
  }

  render() {
    let backIcon = this.props.backIcon || <ArrowBackIcon />;

    let headElem;
    if (this.props.realAppBar) {
      headElem = (
        <AppBar className="overlay-appbar">
          <Toolbar>
            <IconButton
              onClick={this.onBack.bind(this)}
              className="white-btn real-app-bar-back-button"
            >
              {backIcon}
            </IconButton>
            <Typography variant="h6" color="inherit" className="flex">
              {this.props.title || ''}
            </Typography>
            {this.props.rightButtonIcon ? (
              <IconButton
                color="inherit"
                onClick={this.props.rightButtonEvent}
                disabled={this.props.rightButtonDisabled || false}
              >
                {this.props.rightButtonIcon}
              </IconButton>
            ) : this.props.rightButtonText ? (
              <Button
                color="inherit"
                onClick={this.props.rightButtonEvent}
                disabled={this.props.rightButtonDisabled || false}
              >
                {this.props.rightButtonText}
              </Button>
            ) : (
              ''
            )}
          </Toolbar>
        </AppBar>
      );
    } else {
      let backgroundStyle = {};
      let imageComponent = null;
      if (this.props.hasBackgroundImage) {
        if (this.state.imageComponent) {
          const imgCompWrapStyles = {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          };
          imageComponent = (
            <div style={imgCompWrapStyles}>{this.state.imageComponent}</div>
          );
        } else {
          backgroundStyle.background = `url('${this.state.image}')`;
        }
      } else if (this.props.backgroundColor) {
        backgroundStyle.background = this.props.backgroundColor;
      }
      if (this.props.backgroundFilter) {
        backgroundStyle.filter = 'brightness(60%)';
      }
      if (this.props.backgroundCover) {
        backgroundStyle.backgroundSize = 'cover';
      }
      if (this.props.backgroundTop) {
        backgroundStyle.backgroundPositionY = 'top';
      }

      let fullHeaderClass =
        'bigimage ' +
        (this.props.smallHeader ? 'small ' : '') +
        this.state.headerClassName;

      headElem = [
        <div key="appbar" ref="appbar" className="app-bar-ov">
          <div className="left">
            <IconButton onClick={this.onBack.bind(this)} className="white-btn">
              {backIcon}
            </IconButton>
          </div>
          <div className="right">{this.props.rightButtons}</div>
        </div>,
        <header
          key="header"
          style={backgroundStyle}
          className={fullHeaderClass}
          onClick={this.props.onImageClick || (() => {})}
          ref="header"
        >
          {this.state.imageLoading ? <Preloader absolute dark /> : ''}
          <Typography
            className={'title' + (this.props.subtitle ? ' with-sub' : '')}
          >
            {this.props.title}
          </Typography>
          <Typography className="subtitle">{this.props.subtitle}</Typography>
          <div className="color-overlay" />
          {imageComponent}
        </header>
      ];
    }

    return (
      <div
        ref="overlay"
        className={
          'overlay background-color' +
          (this.props.className ? ` ${this.props.className}` : '')
        }
      >
        {headElem}
        <section
          className={
            this.props.realAppBar
              ? this.props.smallTopMargin
                ? 'top-margin-small'
                : 'top-margin'
              : ''
          }
        >
          {this.props.children}
        </section>
      </div>
    );
  }
}
