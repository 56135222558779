import React, { Component } from 'react';
import firebase from '../../../../services/firebase';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  buttonBar: {
    display: 'flex',
    justifyContent: 'space-around'
  }
});

class StatusCard extends Component {
  state = {
    customer: null,
    loading: false
  };

  currStatus = () => {
    switch (this.props.status) {
      case 'running':
        return 1;

      case 'finished':
        return 3;

      case 'draft':
      default:
        return 0;
    }
  };

  render() {
    const { classes, status, editMode, saving } = this.props;

    return (
      <Card className="simple-card">
        <CardContent>
          <Stepper activeStep={this.currStatus()}>
            <Step key="draft">
              <StepLabel>Entwurf</StepLabel>
            </Step>
            <Step key="running">
              <StepLabel>Laufend</StepLabel>
            </Step>
            <Step key="finished">
              <StepLabel>Fertig</StepLabel>
            </Step>
          </Stepper>
          <div className={classes.buttonBar}>
            {status === 'draft' && !editMode ? (
              <React.Fragment>
                <Button
                  onClick={this.props.onStartInvoice}
                  variant="contained"
                  color="primary"
                >
                  Rechnung starten
                </Button>
                <Button onClick={this.props.onChangeInvoice} color="secondary">
                  Entwurf ändern
                </Button>
              </React.Fragment>
            ) : (
              ''
            )}
            {status === 'draft' && editMode ? (
              <React.Fragment>
                <Button
                  disabled={saving}
                  onClick={this.props.onSave}
                  variant="contained"
                  color="primary"
                >
                  {saving ? (
                    <CircularProgress size={24} className="button-preloader" />
                  ) : (
                    'Speichern'
                  )}
                </Button>
                <Button
                  disabled={saving}
                  onClick={this.props.onDiscard}
                  color="secondary"
                >
                  Verwerfen
                </Button>
              </React.Fragment>
            ) : (
              ''
            )}
            {status === 'running' ? (
              <Button
                onClick={this.props.onMarkInvoiceAsPayed}
                variant="contained"
                color="primary"
              >
                Als bezahlt markieren
              </Button>
            ) : (
              ''
            )}
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(StatusCard);
