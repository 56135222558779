import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { checkPasswordSafe } from '../../../helper/calc';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class ChangePasswordDialog extends Component {
  state = {
    loading: false,
    newPassword: '',
    newPasswordRepeat: '',
    password: '',
    wrongPassErr: false,
    unsafePassErr: false,
    repeatPassErr: false
  };

  onChangeNewPassword = event => {
    this.setState({ newPassword: event.target.value });
  };

  onChangeNewPasswordRepeat = event => {
    this.setState({ newPasswordRepeat: event.target.value });
  };

  onChangePassword = event => {
    this.setState({ password: event.target.value });
  };

  onClose = () => {
    if (this.state.loading) {
      return;
    }
    this.setState({
      password: '',
      email: '',
      wrongPassErr: false,
      unsafePassErr: false,
      repeatPassErr: false
    });
    this.props.onClose();
  };

  onSubmit = () => {
    let { newPassword, newPasswordRepeat, password } = this.state;
    this.setState({
      loading: true,
      wrongPassErr: false,
      unsafePassErr: false,
      repeatPassErr: false
    });

    if (!checkPasswordSafe(newPassword)) {
      return this.setState({ unsafePassErr: true, loading: false });
    }

    if (newPassword !== newPasswordRepeat) {
      return this.setState({ repeatPassErr: true, loading: false });
    }

    this.props
      .onAuth(password)
      .then(() => {
        this.setState({
          newPassword: '',
          password: '',
          newPasswordRepeat: '',
          loading: false
        });
        this.props.onSubmit(newPassword);
      })
      .catch(err => {
        this.setState({ loading: false, wrongPassErr: true });
      });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">E-Mail ändern</DialogTitle>
        <DialogContent>
          <TextField
            error={this.state.unsafePassErr}
            margin="dense"
            id="newpassword"
            label="Neues Passwort (min. 6 Zeichen)"
            type="password"
            fullWidth
            value={this.state.newPassword}
            onChange={this.onChangeNewPassword}
          />
          <TextField
            error={this.state.repeatPassErr}
            margin="dense"
            id="newpasswordrepeat"
            label="Neues Passwort wiederholen"
            type="password"
            fullWidth
            value={this.state.newPasswordRepeat}
            onChange={this.onChangeNewPasswordRepeat}
          />
          <TextField
            error={this.state.wrongPassErr}
            margin="dense"
            id="password"
            label="Dein altes Passwort"
            type="password"
            fullWidth
            value={this.state.password}
            onChange={this.onChangePassword}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.loading}
            onClick={this.onClose}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            disabled={this.state.loading}
            onClick={this.onSubmit}
            color="primary"
          >
            Ändern
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
