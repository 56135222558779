import React from 'react';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { withStyles } from '@material-ui/core/styles';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import config from '../../config/constants';

const suggestions = [];

function renderInput(inputProps) {
  const { classes, autoFocus, value, ref, ...other } = inputProps;

  return (
    <TextField
      autoFocus={autoFocus}
      className={classes.textField}
      value={value}
      inputRef={ref}
      InputProps={{
        classes: {
          input: classes.input
        },
        ...other
      }}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.description, query);
  const parts = parse(suggestion.description, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={index} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong key={index} style={{ fontWeight: 500 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.description;
}

function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 &&
          suggestion.description.toLowerCase().slice(0, inputLength) ===
            inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  suggestionsContainerOpen: {
    zIndex: 1,
    position: 'absolute',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  textField: {
    width: '100%'
  }
});

class AddressAutocomplete extends React.Component {
  state = {
    value: '',
    suggestions: []
  };

  getAutocompleteService() {
    if (this.autocompleteService) {
      return this.autocompleteService;
    }

    if (this.props.googleMaps) {
      this.autocompleteService = new this.props.googleMaps.places.AutocompleteService();
      return this.autocompleteService;
    }

    return null;
  }

  getPlacesService() {
    if (this.placesService) {
      return this.placesService;
    }

    if (this.props.googleMaps) {
      this.placesService = new this.props.googleMaps.places.PlacesService(
        new this.props.googleMaps.Map(document.createElement('div'))
      );
      return this.placesService;
    }

    return null;
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    let autocompleteService = this.getAutocompleteService();
    if (autocompleteService) {
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ['address'],
          componentRestrictions: { country: 'de' }
        },
        (predictions/*, status*/) => {
          predictions = predictions ? predictions : [];
          this.setState({
            suggestions: predictions
          });
        }
      );
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = (event, { newValue }) => {
    if (this.props.onChange) {
      newValue = this.props.onChange(newValue) || newValue;
    }
    this.setState({
      value: newValue
    });
  };

  onSelected = suggestion => {
    if (this.props.onSelected) {
      this.getPlacesService().getDetails(
        { placeId: suggestion.place_id },
        (place, status) => {
          this.props.onSelected(place);
        }
      );
    }

    // return getSuggestionValue(suggestion);
    return this.props.value || this.state.value;
  };

  render() {
    const { classes } = this.props;

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderInputComponent={renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={this.onSelected}
        renderSuggestion={renderSuggestion}
        inputProps={{
          classes,
          placeholder: 'Addresse',
          value: this.props.value || this.state.value,
          onChange: this.handleChange
        }}
      />
    );
  }
}

const AddressAutocompleteWithStyles = withStyles(styles)(AddressAutocomplete);

export default (props) => {
  let { libraries, apiKey } = config.googleMap;
  return <ReactGoogleMapLoader
    params={{
      key: apiKey,
      libraries: libraries.join(","),
    }}
    render={googleMaps => <AddressAutocompleteWithStyles googleMaps={googleMaps} {...props} /> }
  />;
}
  
