import Settings from '../services/Settings';

export function nextInvoiceNumber() {
  return (
    Settings.get('generalSettings.invoice.prefix', 'R') +
    Settings.get('generalSettings.invoice.number', '100001') +
    Settings.get('generalSettings.invoice.suffix', '')
  );
}

export function nextCustomerNumber() {
  return (
    Settings.get('generalSettings.customer.prefix', 'K') +
    Settings.get('generalSettings.customer.number', '100001') +
    Settings.get('generalSettings.customer.suffix', '')
  );
}

export function updateInvoiceNumber(setNumber) {
  let prefix = Settings.get('generalSettings.invoice.prefix', 'R');
  let number = Settings.get('generalSettings.invoice.number', '100001');
  let suffix = Settings.get('generalSettings.invoice.suffix', '');
  if (setNumber === `${prefix}${number}${suffix}`) {
    return Settings.updateKey(
      'generalSettings.invoice.number',
      parseInt(number) + 1
    );
  } else {
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  }
}

export function updateCustomerNumber(setNumber) {
  let prefix = Settings.get('generalSettings.customer.prefix', 'R');
  let number = Settings.get('generalSettings.customer.number', '100001');
  let suffix = Settings.get('generalSettings.customer.suffix', '');
  if (setNumber === `${prefix}${number}${suffix}`) {
    return Settings.updateKey(
      'generalSettings.customer.number',
      parseInt(number) + 1
    );
  } else {
    return new Promise((resolve, reject) => {
      resolve(null);
    });
  }
}
