import React, { Component } from 'react';
import firebase from '../../services/firebase';
import axios from 'axios';
import { deleteInvoice, updateInvoice } from '../../actions/invoice';
import { connect } from 'react-redux';
import {
  gotoInvoices,
  gotoInvoiceStart,
  gotoInvoiceMarkPayed,
  gotoDriveSetup
} from '../../helper/transition';
import { InvoiceModel } from '../../helper/models';
import download from '../../helper/download';
import GApi from '../../services/GApi';

export default class InvoiceDetailsBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceSendVisible: false,
      fullscreenImage: false,
      deleting: false,
      changedItems: null,
      editMode: false,
      saving: false,
      backupRunning: false,
      driveSetupDialogOpen: false
    };
    this.driveBackupInitialChecked = false;
  }

  componentWillMount() {
    this.updateStateInvoice(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialLoadDone) {
      this.updateStateInvoice(nextProps);
    }
  }

  deleteInvoice = () => {
    this.setState({ deleting: true });
    this.props.dispatch(
      deleteInvoice(this.state.invoice.id, err => {
        if (err) {
          this.setState({ deleting: false });
        } else {
          gotoInvoices(this.props.history);
        }
      })
    );
  };

  updateStateInvoice(props) {
    // Search Invoice and set to state.invoice
    let invoice = props.invoices.find(invoice => {
      return invoice.id === props.match.params.id;
    });
    if (invoice) {
      this.setState({ invoice });
      // Load Invoice Stuff here after Invoice is loaded :P
      if (props.customerInitialLoadDone) {
        this.updateStateCustomer(props, invoice.data().customer.id);
      }

      // Check for drive backup still existing
      if (
        invoice.data().additionalData.driveBackup &&
        invoice.data().additionalData.driveBackup.fileId &&
        !this.driveBackupChecked
      ) {
        this.driveBackupInitialChecked = true;
        this.checkDriveBackup(invoice);
      }
    } else {
      // Invoice not found :O todo: better error handling
    }
  }

  checkDriveBackup(invoice) {
    invoice = invoice || this.state.invoice;
    let fileId = invoice.data().additionalData.driveBackup.fileId;
    GApi.getFile(fileId)
      .then(res => {
        if (res.result.trashed) {
          this.resetDriveBackup();
        }
      })
      .catch(err => {
        if (err.status === 404) {
          this.resetDriveBackup();
        }
      });
  }

  resetDriveBackup(invoice) {
    invoice = invoice || this.state.invoice;
    return this.props.dispatch(
      updateInvoice(invoice.id, {
        'additionalData.driveBackup': null
      })
    );
  }

  updateStateCustomer(props, id) {
    // Search Customer and set to state.customer
    let customer = props.customers.find(customer => {
      return customer.id === id;
    });
    if (customer) {
      this.setState({ customer });
    } else {
      // Customer does not exists anymore. ToDo: some kinda stuf maybe
    }
  }

  goBack() {
    if (this.props.location.state && this.props.location.state.goBack) {
      return this.props.history.goBack();
    }
    gotoInvoices(this.props.history, { animate: true });
  }

  fullscreenInvoice = show => () => {
    this.setState({ fullscreenImage: show });
  };

  sendInvoice = show => () => {
    this.setState({ invoiceSendVisible: show });
  };

  onItemsChange = changedItems => {
    this.setState({ changedItems });
  };

  saveChanges = () => {
    let { invoice, changedItems: items } = this.state;
    let data = Object.assign(InvoiceModel(invoice.data()).data, { items });
    this.setState({ saving: true });
    this.props.dispatch(
      updateInvoice(invoice.id, { _reRender: true, data }, () => {
        this.setState({
          changedItems: null,
          editMode: false,
          saving: false
        });
      })
    );
  };

  cancelChanges = () => {
    this.setState({
      changedItems: null,
      editMode: false
    });
  };

  startChange = () => {
    this.setState({
      changedItems: InvoiceModel(this.state.invoice.data()).data.items,
      editMode: true
    });
  };

  backup = async () => {
    try {
      this.setState({ backupRunning: true, backupError: null });
      let { invoice } = this.state;
      let data = invoice.data();
      if (!data.pdf) {
        throw new Error('PDF is not generated yet.');
      }
      let url = data.pdf.url;
      let name = 'Rechnung ' + (data.invoiceId ? data.invoiceId : '(Entwurf)');

      let response = await axios.get(url, { responseType: 'blob' });
      let pdf = response.data;
      let res = await GApi.uploadFile(pdf, name);
      return await new Promise(resolve => {
        this.props.dispatch(
          updateInvoice(
            invoice.id,
            {
              'additionalData.driveBackup.fileId': res.result.id,
              'additionalData.driveBackup.createdAt': firebase.firestore.FieldValue.serverTimestamp()
            },
            () => {
              this.setState({ backupRunning: false });
              resolve();
            }
          )
        );
      });
    } catch (e) {
      if (e.status === 401) {
        this.setState({
          backupRunning: false,
          backupError: null,
          driveSetupDialogOpen: true
        });
      } else {
        this.setState({ backupRunning: false, backupError: e });
      }
    }
  };

  driveSetupDialogClose = () => {
    this.setState({ driveSetupDialogOpen: false });
  };

  gotoDriveSetup = () => {
    gotoDriveSetup(this.props.history);
  };

  print = url => () => {
    if (!url) return;
    let data = this.state.invoice.data();
    let title =
      data.type === 'draft'
        ? 'Rechnungsentwurf'
        : 'Rechnung #' + data.invoiceId;

    try {
      let gadget = new window.cloudprint.Gadget();
      gadget.setPrintDocument('url', title, url);
      gadget.openPrintDialog();
    } catch (e) {
      // dont know .. do none i guess !?
    }
  };

  download = (url, fileName) => () => {
    download(url, fileName);
  };
}

export function mapStateToProps(state, ownProps) {
  return {
    initialAccountLoadDone: state.account.initialLoadDone,
    account: state.account.account,
    initialLoadDone: state.invoice.initialLoadDone,
    invoices: state.invoice.invoices,
    customerInitialLoadDone: state.customer.initialLoadDone,
    customers: state.customer.customers
  };
}

export function mapDispatchToProps(dispatch) {
  return { dispatch };
}
