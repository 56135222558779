import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import posed from 'react-pose';
import Settings from '../../../services/Settings';
import Intro from './pages/Intro';
import CompanyName from './pages/CompanyName';
import Address from './pages/Address';
import CEO from './pages/CEO';
import TaxInfo from './pages/TaxInfo';
import Outro from './pages/Outro';
import './styles.css';

const Page = posed.div({
  visible: { opacity: 1, x: 0, delay: 250 },
  hidden: { opacity: 0, x: '100%' }
});

const Pages = [Intro, CompanyName, Address, CEO, TaxInfo, Outro];

export default class SetupDialog extends Component {
  state = {
    loading: false,
    page: 0,
    data: {},
    status: {}
  };

  back = () => {
    this.setState({ loading: false, page: this.state.page - 1 });
  };

  continue = () => {
    let newPage = this.state.page + 1;
    if (newPage === Pages.length) {
      this.props.onClose();
    } else {
      this.setState({ loading: false, page: this.state.page + 1 });
    }
  };

  onPageChange = index => val =>
    this.setState({ data: Object.assign(this.state.data, { [index]: val }) });

  onStatusChange = index => val =>
    this.setState({
      status: Object.assign(this.state.status, { [index]: !!val })
    });

  save = () => {
    this.setState({ loading: true });
    let saveObject = Object.keys(this.state.data).reduce(
      (res, key) => {
        return Object.assign(res, this.state.data[key]);
      },
      { setupDone: true }
    );

    console.log(saveObject);
    Settings.update(saveObject).then(() => {
      this.continue();
    });
  };

  render() {
    if (!this.props.open) {
      return null;
    }

    let { page, loading, status } = this.state;

    let pages = Pages.map((P, index) => (
      <Page
        key={'page-' + index}
        className={'setup-page'}
        pose={page === index ? 'visible' : 'hidden'}
      >
        <P
          onChange={this.onPageChange(index)}
          onStatusChange={this.onStatusChange(index)}
        />
      </Page>
    ));

    let actions = (
      <DialogActions>
        {page !== 0 && page !== Pages.length - 1 ? (
          <Button disabled={loading} onClick={this.back} color="primary">
            Zurück
          </Button>
        ) : null}
        {page === Pages.length - 1 ? (
          <Button
            disabled={loading || !status[page]}
            onClick={this.continue}
            color="primary"
          >
            {loading ? '...' : 'Zum Dashboard'}
          </Button>
        ) : page === Pages.length - 2 ? (
          <Button
            disabled={loading || !status[page]}
            onClick={this.save}
            color="primary"
          >
            {loading ? '...' : 'Fertig'}
          </Button>
        ) : (
          <Button
            disabled={loading || !status[page]}
            onClick={this.continue}
            color="primary"
          >
            {loading ? '...' : 'Weiter'}
          </Button>
        )}
      </DialogActions>
    );

    return (
      <Dialog
        className="setup-page-no-x-scroll"
        fullScreen
        open={this.props.open}
        onClose={this.onClose}
      >
        <DialogContent>{pages}</DialogContent>
        {actions}
      </Dialog>
    );
  }
}
