import React, { Component } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import ListItem from '../default';

export default class EmailListItem extends Component {
  render() {
    let props = Object.assign(
      {
        icon: <EmailIcon />,
        primary: this.props.data,
        placeholder: 'Email',
        secondary: ''
      },
      this.props
    );

    return <ListItem {...props} />;
  }
}
