import React, { Component } from 'react';
import firebase from '../../../services/firebase';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import SettingsIcon from '@material-ui/icons/Settings';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import defaultUserLogo from '../../../img/user.png';

const styles = theme => ({
  avatarWrap: {
    position: 'relative'
  },

  onlineIndicator: {
    borderRadius: '50%',
    background: 'green',
    width: 6,
    height: 6,
    position: 'absolute',
    bottom: 2,
    right: 2,
    border: '2px solid #f7f7f7'
  }
});

class UserButton extends Component {
  state = {
    auth: true,
    anchorEl: null,
    currentUser: firebase.auth().currentUser
  };

  handleChange = (event, checked) => {
    this.setState({ auth: checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  transition = path => () => {
    this.props.history.push(path);
    this.handleClose();
  };

  logout = () => {
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
        window.location.reload(); // otherwise firestore error cuz of persistance
      })
      .catch(function(error) {
        // An error happened.
      });
    this.handleClose();
  };

  render() {
    let { classes } = this.props;
    let { auth, anchorEl } = this.state;
    let open = !!anchorEl;
    let photo = this.state.currentUser.photoURL || defaultUserLogo;

    return (
      <div>
        <IconButton
          aria-owns={open ? 'menu-appbar-user' : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
        >
          <div className={classes.avatarWrap}>
            <Avatar src={photo} className="avatar" />
            <span className={classes.onlineIndicator} />
          </div>
        </IconButton>
        <Menu
          id="menu-appbar-user"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem button onClick={this.transition('/settings/subscription')}>
            <ListItemIcon>
              <AllInclusiveIcon />
            </ListItemIcon>
            <ListItemText primary="Abonnement" />
          </MenuItem>

          <MenuItem onClick={this.transition('/settings/user')}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Konto-Einstellungen" />
          </MenuItem>

          <MenuItem onClick={this.logout}>
            <ListItemIcon>
              <KeyboardArrowLeftIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(UserButton);
