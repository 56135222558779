import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import promise from 'redux-promise-middleware';

// Reducers
import customerReducer from './reducers/customer';
import invoiceReducer from './reducers/invoice';
import accountReducer from './reducers/account';
import statsReducer from './reducers/stats';
const reducers = combineReducers({
  customer: customerReducer,
  invoice: invoiceReducer,
  account: accountReducer,
  stats: statsReducer
});

// Middleware
const middleware = applyMiddleware(promise(), thunk, logger);

export default createStore(reducers, middleware);
