import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { connect } from 'react-redux';
import TotalSalesChart from '../../components/TotalSalesChart';
import BigCashNumber from '../../components/BigCashNumber';
import IconNumberCard from '../../components/Cards/IconNumberCard';
import ActionButton from '../../components/ActionButton';
import DoneIcon from '@material-ui/icons/Done';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Settings from '../../services/Settings';
import { getRemainingInvoiceDays, getItemsTotal } from '../../helper/calc';
import { gotoInvoices, gotoInvoiceAdd } from '../../helper/transition';
import { desktopWrapper } from '../../helper/styles';

const styles = theme => ({
  desktopWrapper,
  container: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-around'
  },
  card: {
    width: '30%'
  },
  cardBig: {
    width: '63%'
  },
  headline: {
    margin: '20px 10px 10px 10px',
    fontSize: 14,
    fontWeight: 'lighter',
    color: '#c3c3c3',
    display: 'flex',
    alignItems: 'center'
  },
  headlineIcon: {
    width: 32,
    height: 32,
    marginRight: '10px'
  }
});

class Dashboard extends Component {
  render() {
    let { classes } = this.props;
    let runningInvoices = this.props.invoices.filter(inv => {
      return inv.data().type === 'running';
    });
    let invoicesRunning = runningInvoices.length;
    let invoicesOverdue = runningInvoices.filter(inv => {
      return getRemainingInvoiceDays(inv.data()) < 0;
    }).length;
    let invoicesOverdueColor = invoicesOverdue > 0 ? '#ff8c8c' : '#6ec37a';
    let invoicesOverdueIcon =
      invoicesOverdue > 0 ? PriorityHighIcon : SentimentVerySatisfiedIcon;
    let invoiceRunningValue = _.sumBy(runningInvoices, inv => {
      return getItemsTotal(inv.data().data.items);
    });

    return (
      <div className={classes.desktopWrapper}>
        <BigCashNumber value={invoiceRunningValue} title="Fällig (Gesamt)" />
        <div className={classes.container}>
          <IconNumberCard
            onClick={() => gotoInvoices(this.props.history)}
            className={classes.card}
            icon={DoneIcon}
            number={invoicesRunning}
            title={'Laufende Rechnungen'}
          />
          <IconNumberCard
            onClick={() => gotoInvoices(this.props.history)}
            className={classes.card}
            icon={invoicesOverdueIcon}
            number={invoicesOverdue}
            title={'Überfällige Rechnungen'}
            color={invoicesOverdueColor}
          />
          <ActionButton
            onClick={() => {
              gotoInvoiceAdd(this.props.history);
            }}
            className={classes.cardBig}
            title="Neue Rechnung erstellen"
          />
        </div>
        <h3 className={classes.headline}>
          <ShowChartIcon className={classes.headlineIcon} /> Übersicht der
          Umsätze
        </h3>
        <TotalSalesChart invoices={this.props.invoices} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    initialLoadDone: state.invoice.initialLoadDone,
    invoices: state.invoice.invoices
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);
