import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import EmailVerifyCard from '../../components/Cards/EmailVerifyCard';
import ShareCard from '../../components/Cards/ShareCard';
import CacheCardSuccess from '../../components/Cards/CacheCardSuccess';
import CacheCard from '../../components/Cards/CacheCard';
import CurrentInvoiceStats from '../../components/CurrentInvoiceStats';
import BigCashNumber from '../../components/BigCashNumber';
import TotalSalesChartCard from '../../components/Cards/TotalSalesChartCard';
import PersistenceReloadModal from '../../components/Modal/PersistenceReloadModal';
import Settings from '../../services/Settings';
import { getRemainingInvoiceDays, getItemsTotal } from '../../helper/calc';
import { gotoInvoices } from '../../helper/transition';

class Dashboard extends Component {
  autoUserUpdate = null;

  constructor(props) {
    super(props);
    this.state = {
      invoicesOverdue: null,
      invoicesRunning: null,
      cacheCardLoading: false,
      PersistenceReloadModalVisible: false,
      cacheCardVisible:
        Settings.getDevice('openTrustModal') && !Settings.getDevice('trusted'),
      cacheCardSuccessVisible: Settings.getDevice('cacheCardSuccessVisible')
    };
    this.updateUser();
  }

  componentDidUpdate() {
    if (!this.props.user.emailVerified && !this.autoUserUpdate) {
      this.startAutoUserRefresh();
    } else if (this.props.user.emailVerified && this.autoUserUpdate) {
      this.stopAutoUserRefresh();
    }
  }

  updateUser() {
    this.props.user.reload().then(() => {
      this.forceUpdate();
    });
  }

  startAutoUserRefresh() {
    this.autoUserUpdate = setInterval(() => {
      this.updateUser();
    }, 5000);
  }

  stopAutoUserRefresh() {
    clearTimeout(this.autoUserUpdate);
    this.autoUserUpdate = null;
  }

  enableOfflineUse() {
    this.setState({
      cacheCardLoading: true
    });
    Settings.setDeviceAsTrusted().then(() => {
      this.setState({
        PersistenceReloadModalVisible: true,
        cacheCardLoading: false,
        cacheCardVisible: false
      });
    });
  }

  closeCacheCardSuccess() {
    Settings.setDeviceOpenTrustModalSuccess(false);
    this.setState({
      cacheCardSuccessVisible: false
    });
  }

  closeCacheCard() {
    Settings.setDeviceOpenTrustModal(false);
    this.setState({
      cacheCardVisible: false
    });
  }

  render() {
    let runningInvoices = this.props.invoices.filter(inv => {
      return inv.data().type === 'running';
    });
    let invoicesRunning = runningInvoices.length;
    let invoicesOverdue = runningInvoices.filter(inv => {
      return getRemainingInvoiceDays(inv.data()) < 0;
    }).length;
    let invoiceRunningValue = _.sumBy(runningInvoices, inv => {
      return getItemsTotal(inv.data().data.items);
    });

    return (
      <div className="top-padding">
        <EmailVerifyCard visible={!this.props.user.emailVerified} />
        <CacheCardSuccess
          visible={this.state.cacheCardSuccessVisible}
          onClose={this.closeCacheCardSuccess.bind(this)}
        />
        <CacheCard
          loading={this.state.cacheCardLoading}
          visible={this.state.cacheCardVisible}
          onClose={this.closeCacheCard.bind(this)}
          onSubmit={this.enableOfflineUse.bind(this)}
        />
        <BigCashNumber value={invoiceRunningValue} title="Fällig (Gesamt)" />
        <ShareCard history={this.props.history} />
        <CurrentInvoiceStats
          overdue={invoicesOverdue}
          running={invoicesRunning}
          onRunningClick={() => {
            gotoInvoices(this.props.history, { tab: 1 });
          }}
          onOverdueClick={() => {
            gotoInvoices(this.props.history, { tab: 1 });
          }}
        />
        <PersistenceReloadModal
          open={this.state.PersistenceReloadModalVisible}
          onClose={() =>
            this.setState({ PersistenceReloadModalVisible: false })
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    initialLoadDone: state.invoice.initialLoadDone,
    invoices: state.invoice.invoices
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
