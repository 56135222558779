import React, { Component } from 'react';
import _ from 'lodash';
import Firestore from '../../services/Firestore';
import Settings from '../../services/Settings';

export default class InvoiceTemplateBase extends Component {
  state = {
    loading: false,
    templates: [],
    initalLoadDone: false
  };

  componentDidMount() {
    this.updateStateFromSettings();
    this.unsub = Firestore.db()
      .collection('templates')
      .where('public', '==', true)
      .where('type', '==', 'invoice-template')
      .onSnapshot(snapshot => {
        this.setState({
          initalLoadDone: true,
          templates: _.sortBy(snapshot.docs, d => {
            return (d.data().public ? 'A' : 'B') + d.data().title;
          })
        });
      });
  }

  componentWillUnmount() {
    this.unsub();
  }

  goBack = () => {
    this.props.history.goBack();
  };

  updateStateFromSettings() {
    this.setState({
      invoiceTemplateId: Settings.get('invoiceTemplateId', null)
    });
  }

  isSelected(template) {
    let { invoiceTemplateId } = this.state;
    return (
      template.id === invoiceTemplateId ||
      (!invoiceTemplateId && template.data().isDefault)
    );
  }

  onActivate = template => () => {
    this.setState({ loading: true });
    Settings.update({ invoiceTemplateId: template.id }).then(() => {
      this.updateStateFromSettings();
      this.setState({ loading: false });
    });
  };
}
