import React, { Component } from 'react';
import _ from 'lodash';
import './App.css';
import 'animate.css';
import { Router } from 'react-router-dom';
import { firebaseAuth } from './services/firebase';
import createBrowserHistory from 'history/createBrowserHistory';

import Routes from './config/routes';
import DesktopApp from './components/Desktop/App';
import AppBar from './components/AppBar';
import Drawer from './components/Drawer';
import Preloader from './components/Preloader';
import Settings from './services/Settings';
import Storage from './services/Storage';
import Firestore from './services/Firestore';
import client from './helper/client';
import request from './helper/request';
import GApi from './services/GApi';
import Runtime from './services/Runtime';
import SetupDialog from './components/Dialogs/SetupDialog';
import BetaDialog from './components/Dialogs/BetaDialog';
import StartupErrorBox from './components/StartupErrorBox';
import AppBarMenu from './components/AppBarMenu';
import { getAllData } from './helper/dataHelper';

import ReactGA from 'react-ga';
import { isBrowser, isMobile } from 'react-device-detect';
import GApiService from './services/GApi.js';

const emailAuthProviderId = 'password';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setupOpen: true,
      betaDialogVisible: true,
      userUpdateCycle: 0,
      hasAuth: false,
      loading: true,
      hasUnverifiedAuth: false, // is true when user is logged in but email isnt verified
      hasRealUserAccount: false, // true if user has a real user account with password
      history: createBrowserHistory() /*createHashHistory()*/
    };
  }

  betaDialogClose = () => {
    window.localStorage.setItem('betaDialogDismissed', true);
    this.setState({ betaDialogVisible: false });
  };

  componentWillMount() {
    this.setState({
      betaDialogVisible: !window.localStorage.getItem('betaDialogDismissed')
    });
  }

  componentDidMount() {
    this.state.history.listen((location, action) => {
      ReactGA.pageview(location.pathname);
    });

    Promise.all([GApi.init(), client.getInitialFingerprint()]).then(() => {
      this.unlistenHistory = this.state.history.listen((location, action) => {
        if (this.refs.drawer) {
          this.refs.drawer.close();
        }
      });

      this.removeListener = firebaseAuth().onAuthStateChanged(this.userUpdate);
    });
  }

  userUpdate = (user, retry = 0) => {
    // Sett error to null
    this.setState({ error: null });

    // Breadcrumb
    window.Raven.captureBreadcrumb({
      message: 'AuthState Changed',
      category: 'action',
      data: {
        user
      }
    });

    // Close Drawer if open
    if (this.refs.drawer) {
      this.refs.drawer.close();
    }

    // If logged in
    if (user) {
      ///////////////////////////////
      // User is authenticated now //
      ///////////////////////////////

      window.Raven.setUserContext({
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        id: user.uid
      });

      ReactGA.set({
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        id: user.uid
      });

      // Check if user has real account already
      if (!_.find(user.providerData, { providerId: emailAuthProviderId })) {
        return this.setState({
          user,
          loading: false,
          hasRealUserAccount: false
        });
      }

      // Setup Firestore
      Firestore.init()
        .then(() => {
          // Check if user is already installed & initialVerified
          Settings.init()
            .then(() => {
              // Has Auth now
              Settings.authorize()
                .then(() => {
                  // Initialize Storage
                  Storage.init();

                  // preload all data
                  getAllData().then(() => {
                    // Al Setup done, load App
                    this.setState({
                      user,
                      hasRealUserAccount: true,
                      hasUnverifiedAuth: false,
                      hasAuth: true,
                      loading: false
                    });
                  });
                })
                .catch(err => {
                  window.Raven.captureException(err);
                  this.setState({ error: err });
                });
            })
            .catch(err => {
              // Check if email email verified
              if (!user.emailVerified) {
                return this.setState({
                  user,
                  loading: false,
                  hasUnverifiedAuth: true,
                  hasRealUserAccount: true
                });
              }

              // Initial Verification
              if (err === 'Unverified') {
                request.getClient().then(client => {
                  client.__cloudfunctions
                    .initialVerify()
                    .then(() => {
                      this.userUpdate(user);
                    })
                    .catch(err => {
                      return window.setTimeout(() => {
                        if (retry > 2) {
                          window.location.reload();
                        } else {
                          this.userUpdate(user, ++retry);
                        }
                      }, 2000);
                    });
                });
              } else {
                window.Raven.captureException(err);
                this.setState({ error: err });
              }
            });
        })
        .catch(err => {
          window.Raven.captureException(err);
          this.setState({ error: err });
        });
    } else {
      /////////////////////////////////
      // User is Unauthenticated now //
      /////////////////////////////////

      // Todo: better GAPI Concept
      GApiService.gapi.auth2.getAuthInstance().signOut();

      window.Raven.setUserContext();
      ReactGA.set({});

      this.setState({
        user: null,
        hasRealUserAccount: false,
        hasUnverifiedAuth: false,
        hasAuth: false,
        loading: false
      });
    }
  };

  updateHeaderContentHandler = HeaderContent => {
    this.setState({
      HeaderContent
    });
  };

  refreshHandler = () => {
    return new Promise(() => {
      window.location.reload();
    });
  };

  setupDialogClose = () => {
    this.setState({ setupOpen: false });
  };

  render() {
    // If loading show preloaders
    if (this.state.error) {
      return (
        <StartupErrorBox
          refreshHandler={this.refreshHandler}
          error={this.state.error}
        />
      );
    }

    if (this.state.loading) {
      return <Preloader />;
    }

    let MainContent = (
      <main className={this.state.hasAuth ? 'loggedin' : ''}>
        <Routes
          updateHeaderContent={this.updateHeaderContentHandler}
          userUpdateCycle={this.state.userUpdateCycle}
          user={this.state.user}
          hasAuth={this.state.hasAuth}
          hasUnverifiedAuth={this.state.hasUnverifiedAuth}
          hasRealUserAccount={this.state.hasRealUserAccount}
        />
        <BetaDialog
          open={this.state.betaDialogVisible}
          onClose={this.betaDialogClose}
        />
        {this.state.hasAuth && Settings.get('setupDone', true) === false ? (
          <SetupDialog
            open={this.state.setupOpen}
            onClose={this.setupDialogClose}
          />
        ) : null}
      </main>
    );

    let { HeaderContent } = this.state;

    Runtime.device = isMobile ? 'mobile' : 'desktop';
    let appContent = '';
    if (isMobile) {
      // Mobile App

      // AppBar right sided Buttons
      let appBarMenu = <AppBarMenu history={this.state.history} />;

      // Menu Only Visible if logged in
      let menu = !this.state.hasAuth
        ? []
        : [
            <Drawer ref="drawer" history={this.state.history} key="drawer" />,
            <AppBar
              menuToggle={() => this.refs.drawer.open()}
              menu={appBarMenu}
              key="appbar"
            />
          ];

      appContent = (
        <div className="App">
          {menu}
          {MainContent}
        </div>
      );
    } else {
      // Content
      let content = this.state.hasAuth ? (
        <DesktopApp
          headerContent={HeaderContent}
          content={MainContent}
          history={this.state.history}
        />
      ) : (
        MainContent
      );

      // Desktop App
      appContent = <div className="App Desktop">{content}</div>;
    }

    return <Router history={this.state.history}>{appContent}</Router>;
  }
}

export default App;
