import React, { Component } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import logo from '../../img/logo.svg';
import google from '../../img/google.svg';
import facebook from '../../img/facebook.svg';

export default function register(classes) {
  return (
    <div className={classes.box}>
      <img className={classes.logo} src={logo} />
      <h1 className={classes.headline}>MyVoiz</h1>

      <div className={classes.socialButtons}>
        <ButtonBase
          className={[classes.socialButton, classes.gplus].join(' ')}
          onClick={this.loginByGoogle.bind(this)}
        >
          <img className={classes.socialButtonImg} src={google} />
        </ButtonBase>

        <ButtonBase
          className={[classes.socialButton, classes.fb].join(' ')}
          onClick={this.loginByFacebook.bind(this)}
        >
          <img className={classes.socialButtonImg} src={facebook} />
        </ButtonBase>
      </div>

      <div className="login-error">{this.state.registerError.text || ''}</div>

      <TextField
        id="regemail"
        value={this.state.reg_email || ''}
        className={classes.textfield}
        label="Email"
        onChange={this.changeReg('email')}
        margin="normal"
      />
      <TextField
        id="regpassword"
        value={this.state.reg_password || ''}
        className={classes.textfield}
        label="Passwort"
        type="password"
        onChange={this.changeReg('password')}
        margin="normal"
      />
      <TextField
        id="regpassword2"
        value={this.state.reg_passwordRepeat || ''}
        className={classes.textfield}
        label="Passwort wiederholen"
        type="password"
        onChange={this.changeReg('passwordRepeat')}
        margin="normal"
      />
      <br />
      <br />
      <Button
        disabled={this.state.registerInProgress}
        className={classes.loginButton}
        onClick={this.register}
      >
        {this.state.registerInProgress ? 'Registrieren ...' : 'Registrieren'}
      </Button>
      <div className={classes.registeracccontainer}>
        <Button
          onClick={this.setMode(this.status.login)}
          disabled={this.state.registerInProgress}
          color="secondary"
          className={classes.forgotPasswd}
        >
          Schon einen Account? Hier zum Login.
        </Button>
      </div>
    </div>
  );
}
