import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import GeneralBase from '../../sites/Settings/general';
import Overlay from '../../components/Overlay';
import DefaultInputDialog from '../../components/Dialogs/DefaultInputDialog';
import DynamicNumber from '../../components/DynamicNumber';
import StraightenIcon from '@material-ui/icons/Straighten';
import GetAppIcon from '@material-ui/icons/GetApp';
import Preloader from '../../components/Preloader';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  card: {
    position: 'relative'
  },
  smallH3: {
    fontSize: 14,
    fontWeight: 'normal',
    margin: '5px 0 10px 0',
    color: '#717171'
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  chip: {
    margin: '0 5px 5px 0'
  },
  belowChipContainer: {
    marginTop: 15,
    textAlign: 'center'
  }
});

class General extends GeneralBase {
  render() {
    const { classes } = this.props;
    const { units, newUnitText, newUnitDialogOpen } = this.state;

    return (
      <Overlay
        title="Allgemeine Einstellungen"
        onBack={this.goBack}
        realAppBar
        smallTopMargin
      >
        <List>
          <ListItem>
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <ListItemText
              primary="Offline Cache"
              secondary="Benötigt neuladen der App"
            />
            <ListItemSecondaryAction>
              <Switch
                onChange={this.changePersistent}
                checked={this.state.persistent}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>

        <Card className={'simple-card ' + classes.card}>
          <CardHeader title="Nummerierung" />
          <CardContent>
            <h3 className={classes.smallH3}>Kundennummer</h3>
            <DynamicNumber
              onChange={this.onChange('customer')}
              prefix={this.state.customer.prefix}
              number={this.state.customer.number}
              suffix={this.state.customer.suffix}
            />
            <br />
            <h3 className={classes.smallH3}>Rechnungsnummer</h3>
            <DynamicNumber
              onChange={this.onChange('invoice')}
              prefix={this.state.invoice.prefix}
              number={this.state.invoice.number}
              suffix={this.state.invoice.suffix}
            />
          </CardContent>
        </Card>

        <Card className={'simple-card ' + classes.card}>
          <CardHeader title="Einheiten" />
          <CardContent>
            <div className={classes.chipContainer}>
              {units.map((unit, index) => {
                return (
                  <Chip
                    className={classes.chip}
                    key={index}
                    icon={<StraightenIcon />}
                    label={unit}
                    onDelete={this.deleteChip(index)}
                    color="primary"
                  />
                );
              })}
            </div>
            <div className={classes.belowChipContainer}>
              <Button onClick={this.newUnitOpen} color="primary">
                Hinzufügen
              </Button>
            </div>
            <DefaultInputDialog
              open={newUnitDialogOpen}
              title="Einheit Hinzufügen"
              label="Einheit"
              placeholder="Kilogramm"
              value={newUnitText}
              onChange={this.newUnitOnChange}
              onSubmit={this.newUnitOnSubmit}
              onCancel={this.newUnitOnCancel}
              onClose={this.newUnitOnCancel}
            />
          </CardContent>
        </Card>
        <Fab
          onClick={this.save}
          className={'default-fab animated ' + this.state.animation}
          color="primary"
          aria-label="save"
        >
          <SaveIcon />
        </Fab>
        {this.state.saving ? <Preloader /> : null}
      </Overlay>
    );
  }
}

export default withStyles(styles)(General);
