import React, { Component } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import logo from '../../img/logo.svg';
import google from '../../img/google.svg';
import facebook from '../../img/facebook.svg';

export default function login(classes) {
  return (
    <div className={classes.box}>
      <img className={classes.logo} src={logo} />
      <h1 className={classes.headline}>MyVoiz</h1>

      <div className={classes.socialButtons}>
        <ButtonBase
          className={[classes.socialButton, classes.gplus].join(' ')}
          onClick={this.loginByGoogle.bind(this)}
        >
          <img className={classes.socialButtonImg} src={google} />
        </ButtonBase>

        <ButtonBase
          className={[classes.socialButton, classes.fb].join(' ')}
          onClick={this.loginByFacebook.bind(this)}
        >
          <img className={classes.socialButtonImg} src={facebook} />
        </ButtonBase>
      </div>

      <TextField
        id="email"
        value={this.state.email || ''}
        className={classes.textfield}
        label="Email"
        onChange={this.changeEmail.bind(this)}
        margin="normal"
        error={this.state.loginError ? true : false}
      />

      <TextField
        id="password"
        value={this.state.password || ''}
        className={classes.textfield}
        label="Passwort"
        type="password"
        onChange={this.changePassword.bind(this)}
        margin="normal"
        error={this.state.loginError ? true : false}
      />
      <div className={classes.optcontainer}>
        <Button
          onClick={this.setMode(this.status.resetPassword)}
          disabled={this.state.loginInProgress}
          color="secondary"
          className={classes.forgotPasswd}
        >
          Password vergessen
        </Button>
      </div>
      <Button
        disabled={this.state.loginInProgress}
        className={classes.loginButton}
        onClick={this.loginByMail.bind(this)}
      >
        {this.state.loginInProgress ? 'Login ...' : 'Login'}
      </Button>
      <div className={classes.registeracccontainer}>
        <Button
          onClick={this.setMode(this.status.register)}
          disabled={this.state.loginInProgress}
          color="secondary"
          className={classes.forgotPasswd}
        >
          Kein Account? Hier direkt Registrieren.
        </Button>
      </div>
    </div>
  );
}
