import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { withStyles } from '@material-ui/core/styles';
import { searchCustomers } from '../../helper/search';
import Avatar from '@material-ui/core/Avatar';
import './styles.css';

const suggestions = [];

function renderInput(inputProps) {
  const { classes, autoFocus, value, ref, ...other } = inputProps;

  return (
    <TextField
      autoFocus={autoFocus}
      className={classes.textField}
      value={value}
      inputRef={ref}
      InputProps={{
        classes: {
          input: classes.input
        },
        ...other
      }}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion._search, query);
  const parts = parse(suggestion._search, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <Avatar className="menu-avatar">
        {suggestion.firstname ? suggestion.firstname.substr(0, 1) : '?'}
      </Avatar>
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={index} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong key={index} style={{ fontWeight: 500 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion._search;
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  suggestionsContainerOpen: {
    zIndex: 1,
    position: 'absolute',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  textField: {
    width: '100%'
  }
});

class CustomerAutocomplete extends React.Component {
  state = {
    value: '',
    suggestions: []
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: searchCustomers(this.props.customers, value)
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = (event, { newValue }) => {
    if (this.props.onChange) {
      newValue = this.props.onChange(newValue) || newValue;
    }
    this.setState({
      value: newValue
    });
  };

  onSelected = suggestion => {
    if (this.props.onSelected) {
      this.props.onSelected(
        _.find(this.props.customers, customer => {
          return customer.id === suggestion.__id;
        })
      );
    }

    return getSuggestionValue(suggestion);
  };

  render() {
    const { classes } = this.props;

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderInputComponent={renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={this.onSelected}
        renderSuggestion={renderSuggestion}
        inputProps={{
          classes,
          placeholder: 'Kunde',
          value: this.props.value || this.state.value,
          onChange: this.handleChange
        }}
      />
    );
  }
}

CustomerAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomerAutocomplete);
