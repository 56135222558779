import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import FolderIcon from '@material-ui/icons/Folder';
import SettingsIcon from '@material-ui/icons/Settings';

const styles = theme => ({
  itemText: {
    opacity: 0,
    transition: 'opacity 0.15s ease'
  },

  visible: {
    opacity: 1
  },

  listitem: {
    paddingLeft: 23
  }
});

class Menu extends Component {
  state = {
    expanded: false
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.expanded && !this.props.expanded) {
      this.setState({ expanded: false });
    }
    if (!prevProps.expanded && this.props.expanded) {
      setTimeout(() => {
        if (this.props.expanded) this.setState({ expanded: true });
      }, 200);
    }
  }

  transition = path => () => {
    this.props.history.push(path);
  };

  render() {
    let { classes } = this.props;
    let { expanded } = this.state;

    return (
      <List component="nav">
        <ListItem
          className={classes.listitem}
          onClick={this.transition('/dashboard')}
          button
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          {this.props.expanded ? (
            <ListItemText
              className={
                classes.itemText + (expanded ? ' ' + classes.visible : '')
              }
              primary="Dashboard"
            />
          ) : (
            ''
          )}
        </ListItem>
        <ListItem
          className={classes.listitem}
          onClick={this.transition('/customers')}
          button
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          {this.props.expanded ? (
            <ListItemText
              className={
                classes.itemText + (expanded ? ' ' + classes.visible : '')
              }
              primary="Kunden"
            />
          ) : (
            ''
          )}
        </ListItem>
        <ListItem
          className={classes.listitem}
          onClick={this.transition('/invoices')}
          button
        >
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          {this.props.expanded ? (
            <ListItemText
              className={
                classes.itemText + (expanded ? ' ' + classes.visible : '')
              }
              primary="Rechnungen"
            />
          ) : (
            ''
          )}
        </ListItem>
        <ListItem
          className={classes.listitem}
          onClick={this.transition('/settings')}
          button
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          {this.props.expanded ? (
            <ListItemText
              className={
                classes.itemText + (expanded ? ' ' + classes.visible : '')
              }
              primary="Einstellungen"
            />
          ) : (
            ''
          )}
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles)(Menu);
