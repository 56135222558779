import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import Preloader from '../../components/Preloader';
import loginBackground from '../../img/loginBackground.png';
import google from '../../img/google.svg';
import facebook from '../../img/facebook.svg';
import LoginBase from '../../sites/Login';
import './styles.css';
// const VERIFICATIONMAIL_COOLDOWN = 300000;

const styles = theme => ({
  background: { backgroundImage: 'url(' + loginBackground + ')' },
  input: {
    color: 'white'
  },
  inputLabel: {
    color: theme.palette.primary.light
  }
});

class Login extends LoginBase {
  render() {
    let inputProps = {
      classes: {
        input: this.props.classes.input
      }
    };

    let currentStatus = this.getCurrentStatus(this.status.overview);

    if (currentStatus === this.status.loading) {
      return (
        <div className="login-wrap">
          <div className="bg" />
          <div className="ov">
            <Preloader />
          </div>
        </div>
      );
    }

    if (currentStatus === this.status.loggedInAsNotRegisteredUser) {
      // if user is logged in but email isnt verified
      let user = this.props.user;
      let { emailDate } = this.state;

      return (
        <div className="login-wrap">
          <div className="bg" />
          <div className="ov">
            <div className="box">
              <h3>Setze ein Password</h3>

              {this.state.realAccountError ? (
                <div className="login-error">
                  {this.state.realAccountError || ''}
                </div>
              ) : null}

              <TextField
                id="raccpassword"
                value={this.state.realAccountPassword || ''}
                className="input"
                InputProps={inputProps}
                label="Passwort"
                type="password"
                onChange={this.changeRealAccountPassword.bind(this)}
                margin="normal"
                error={this.state.realAccountError ? true : false}
              />
              <TextField
                id="raccpasswordrepeat"
                value={this.state.realAccountPasswordRepeat || ''}
                className="input"
                InputProps={inputProps}
                label="Passwort wiederholen"
                type="password"
                onChange={this.changeRealAccountPasswordRepeat.bind(this)}
                margin="normal"
                error={this.state.realAccountError ? true : false}
              />
              <br />
              <br />
              <ButtonBase
                disabled={this.state.realAccountLoading}
                className="overview-button login-button"
                color="secondary"
                onClick={this.createRealAccount.bind(this)}
              >
                {this.state.realAccountLoading ? '...' : 'Registrieren'}
              </ButtonBase>

              <Button
                disabled={this.state.loggingOutAndDeleteing}
                color="secondary"
                onClick={this.logoutAndDelete.bind(this)}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      );
    }

    if (currentStatus === this.status.unverified) {
      // if user is logged in but email isnt verified
      let user = this.props.user;
      let { emailDate } = this.state;

      return (
        <div className="login-wrap">
          <div className="bg" />
          <div className="ov">
            <div className="box">
              <h3>Verifiziere deine Email</h3>
              <p>
                Wir haben dir eine Email an {user.email} gesandt. Bitte klicke
                auf den Link in der Email um die Registrierung abzuschließen.
              </p>
              <br />
              <Button
                disabled={emailDate ? true : false}
                variant="contained"
                color="primary"
                onClick={this.sendVerificationMail}
              >
                Email nocheinmal senden&nbsp;
                {emailDate ? (
                  <Moment
                    interval={30000}
                    onChange={this.emailCooldDownUpdated}
                    fromNow
                    locale="de"
                  >
                    {emailDate}
                  </Moment>
                ) : null}
              </Button>
              <br />
              <br />
              <br />
              <Button variant="contained" color="primary" onClick={this.reload}>
                Aktualisieren
              </Button>
            </div>

            <Button color="secondary" onClick={this.logout}>
              Logout
            </Button>
          </div>
        </div>
      );
    }

    ////////////////////
    // Reset Password //
    ////////////////////

    if (currentStatus === this.status.resetPassword) {
      return (
        <div className="login-wrap">
          <div className="bg" />
          <div className="ov">
            <IconButton
              className="backbutton"
              color="inherit"
              onClick={this.setMode(this.status.overview)}
            >
              <BackIcon />
            </IconButton>

            <div className="box">
              <TextField
                id="resetemail"
                value={this.state.resetEmail || ''}
                className="input"
                InputProps={inputProps}
                label="Email"
                onChange={this.changeResetMail.bind(this)}
                margin="normal"
                error={this.state.resetError ? true : false}
              />

              <div className="btns">
                <ButtonBase
                  disabled={this.state.resetPasswordInProgress}
                  className="overview-button register-button"
                  color="secondary"
                  onClick={this.resetPassword}
                >
                  {this.state.resetPasswordInProgress
                    ? '...'
                    : 'Password zurücksetzen'}
                </ButtonBase>

                <Button
                  disabled={this.state.loginInProgress}
                  color="secondary"
                  onClick={this.setMode(this.status.login)}
                >
                  Zurück zum Login
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    ////////////////////
    // Reset Password //
    ////////////////////

    if (currentStatus === this.status.resetPasswordDone) {
      return (
        <div className="login-wrap">
          <div className="bg" />
          <div className="ov">
            <IconButton
              className="backbutton"
              color="inherit"
              onClick={this.setMode(this.status.overview)}
            >
              <BackIcon />
            </IconButton>

            <div className="box">
              <h2>Erfolgreich!</h2>
              <p>
                Wir haben dir eine Email gesandt, in welcher erklärt ist wie du
                dein Passwort zurücksetzen kannst.
              </p>
              <div className="btns">
                <Button
                  disabled={this.state.loginInProgress}
                  color="secondary"
                  onClick={this.setMode(this.status.login)}
                >
                  Zurück zum Login
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    //////////////
    // Overview //
    //////////////

    if (currentStatus === this.status.overview) {
      return (
        <div className="login-wrap">
          <div className="bg" />
          <div className={this.props.classes.background + ' ov overview'}>
            <div className="btns">
              <ButtonBase
                className="overview-button register-button"
                color="secondary"
                onClick={this.setMode(this.status.register)}
              >
                Registrieren
              </ButtonBase>

              <ButtonBase
                className="overview-button login-button"
                color="secondary"
                onClick={this.setMode(this.status.login)}
              >
                Login
              </ButtonBase>
            </div>
          </div>
        </div>
      );
    }

    // If user is not logged in and not overview
    let cardContent;

    ///////////
    // Login //
    ///////////

    if (currentStatus === this.status.login) {
      cardContent = (
        <form className="login">
          {this.state.loginError ? (
            <div className="login-error">{this.state.loginError || ''}</div>
          ) : null}

          <TextField
            id="email"
            value={this.state.email || ''}
            className="input"
            InputProps={inputProps}
            label="Email"
            onChange={this.changeEmail.bind(this)}
            margin="normal"
            error={this.state.loginError ? true : false}
          />
          <TextField
            id="password"
            value={this.state.password || ''}
            className="input"
            InputProps={inputProps}
            label="Passwort"
            type="password"
            onChange={this.changePassword.bind(this)}
            margin="normal"
            error={this.state.loginError ? true : false}
          />

          <div className="login-buttons">
            <ButtonBase
              disabled={this.state.loginInProgress}
              className="overview-button login-button"
              color="secondary"
              onClick={this.loginByMail.bind(this)}
            >
              {this.state.loginInProgress ? 'Login ...' : 'Login'}
            </ButtonBase>

            <Button
              disabled={this.state.loginInProgress}
              color="secondary"
              onClick={this.setMode(this.status.resetPassword)}
            >
              Passwort vergessen
            </Button>
          </div>
        </form>
      );
    }

    //////////////
    // Register //
    //////////////

    if (currentStatus === this.status.register) {
      cardContent = (
        <form>
          <div className="login-error">
            {this.state.registerError.text || ''}
          </div>

          <TextField
            id="regemail"
            value={this.state.reg_email || ''}
            className="input"
            InputProps={inputProps}
            label="Email"
            onChange={this.changeReg('email')}
            margin="normal"
          />
          <TextField
            id="regpassword"
            value={this.state.reg_password || ''}
            className="input"
            InputProps={inputProps}
            label="Passwort"
            type="password"
            onChange={this.changeReg('password')}
            margin="normal"
          />
          <TextField
            id="regpassword2"
            value={this.state.reg_passwordRepeat || ''}
            className="input"
            InputProps={inputProps}
            label="Passwort wiederholen"
            type="password"
            onChange={this.changeReg('passwordRepeat')}
            margin="normal"
          />

          <div className="login-buttons">
            <ButtonBase
              disabled={this.state.registerInProgress}
              className="overview-button login-button"
              color="secondary"
              onClick={this.register}
            >
              {this.state.registerInProgress
                ? 'Registrieren ...'
                : 'Registrieren'}
            </ButtonBase>

            <Button color="secondary" onClick={this.setMode(this.status.login)}>
              Zum Login
            </Button>
          </div>
        </form>
      );
    }

    // Rendering
    return (
      <div className="login-wrap">
        <div className="bg" />
        <div className="ov">
          <IconButton
            className="backbutton"
            color="inherit"
            onClick={this.setMode(this.status.overview)}
          >
            <BackIcon />
          </IconButton>

          <div className="box">
            <div className="social-buttons">
              <ButtonBase
                className="social-button gplus"
                onClick={this.loginByGoogle.bind(this)}
              >
                <img src={google} />
              </ButtonBase>

              <ButtonBase
                className="social-button fb"
                onClick={this.loginByFacebook.bind(this)}
              >
                <img src={facebook} />
              </ButtonBase>
            </div>

            <div className="divider">
              <hr className="left" />
              oder
              <hr className="right" />
            </div>

            {cardContent}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Login);
