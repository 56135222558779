import React, { Component } from 'react';
import firebase from '../../../services/firebase';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ChangeMailDialog from '../../Dialogs/ChangeMailDialog';
import ChangePasswordDialog from '../../Dialogs/ChangePasswordDialog';

const styles = theme => ({
  card: {
    position: 'relative'
  },
  textfield: {
    marginBottom: '30px'
  },
  emailButton: {
    marginBottom: '5px'
  }
});

class EmailPasswordCard extends Component {
  state = {
    loading: false,
    changeMailLoading: false,
    changeMailOpen: false,
    changePasswordOpen: false
  };

  set = (key, val) => () => {
    this.setState({ [key]: val });
  };

  changePassword = newPassword => {
    this.setState({ loading: true, changePasswordOpen: false });
    this.props.user
      .updatePassword(newPassword)
      .then(() => {
        this.setState({ loading: false });
        this.props.onChange();
      })
      .catch(err => {
        alert(err);
        this.setState({ loading: false });
      });
  };

  changeEmail = newMail => {
    this.setState({ loading: true, changeMailOpen: false });
    this.props.user
      .updateEmail(newMail)
      .then(() => {
        this.props.user
          .sendEmailVerification()
          .then(() => {
            this.setState({ loading: false });
            this.props.onChange();
          })
          .catch(err => {
            alert(err);
            this.setState({ loading: false });
          });
      })
      .catch(err => {
        alert(err);
        this.setState({ loading: false });
      });
  };

  reAuth = pass => {
    var credential = firebase.auth.EmailAuthProvider.credential(
      this.props.user.email,
      pass
    );
    return this.props.user.reauthenticateWithCredential(credential);
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={'simple-card ' + classes.card}>
        <CardHeader title={this.props.title} />
        <CardContent>
          <TextField
            type="email"
            label="Email"
            className={'fullwidth ' + this.props.classes.textfield}
            value={this.props.user.email}
            disabled
          />
          <Button
            disabled={this.state.loading}
            onClick={this.set('changeMailOpen', true)}
            color="primary"
            variant="contained"
            className={'fullwidth ' + this.props.classes.emailButton}
          >
            Email ändern
          </Button>
          <Button
            disabled={this.state.loading}
            onClick={this.set('changePasswordOpen', true)}
            color="secondary"
            className="fullwidth"
          >
            Passwort ändern
          </Button>

          <ChangeMailDialog
            open={this.state.changeMailOpen}
            onClose={this.set('changeMailOpen', false)}
            onSubmit={this.changeEmail}
            onAuth={this.reAuth}
          />
          <ChangePasswordDialog
            open={this.state.changePasswordOpen}
            onClose={this.set('changePasswordOpen', false)}
            onSubmit={this.changePassword}
            onAuth={this.reAuth}
          />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(EmailPasswordCard);
