import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import logo from '../../img/logo.svg';

export default function resetPasswordDone(classes) {
  return (
    <div className={classes.box}>
      <img className={classes.logo} src={logo} />
      <h1 className={classes.headline}>MyVoiz</h1>

      <h2>Erfolgreich!</h2>
      <p>
        Wir haben dir eine Email gesandt, in welcher erklärt ist wie du dein
        Passwort zurücksetzen kannst.
      </p>

      <br />
      <br />
      <div className={classes.registeracccontainer}>
        <Button
          onClick={this.setMode(this.status.login)}
          color="secondary"
          className={classes.forgotPasswd}
        >
          Zurück zum Login
        </Button>
      </div>
    </div>
  );
}
