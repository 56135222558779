import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import _ListItem from '../../ListItem';
import AddressAutocomplete from '../../AddressAutocomplete';
import { AddressModel } from '../../../helper/models';

import './styles.css';

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing.unit * 4
  }
});

class AddressListItem extends Component {
  constructor(props) {
    super(props);
    this.addressNames = ['Privat', 'Geschäftlich', 'Sonstige'];

    this.state = {
      open: false,
      address: AddressModel(props.address)
    };
  }

  toggleMenu() {
    this.setState({
      open: !this.state.open
    });
  }

  parsePlace(place) {
    let address = this.state.address ? { __id: this.state.address.__id } : {};
    place.address_components.forEach(ac => {
      if (ac.types.indexOf('street_number') > -1) {
        address.streetnr = ac.long_name;
      }
      if (ac.types.indexOf('route') > -1) {
        address.street = ac.long_name;
      }
      if (ac.types.indexOf('postal_code') > -1) {
        address.zip = ac.long_name;
      }
      if (ac.types.indexOf('locality') > -1) {
        address.city = ac.long_name;
      }
      if (ac.types.indexOf('country') > -1) {
        address.country = ac.long_name;
      }
    });

    address = AddressModel(address);
    return address;
  }

  handleAutocompleteSelected = place => {
    let address = this.parsePlace(place);
    this.setState({ address });
    if (!this.state.open) {
      this.toggleMenu();
    }
  };

  handleAutocompleteChange = text => {
    this.changeAddress('raw', text);
    return text;
  };

  handleChange = name => event => {
    this.changeAddress(name, event.target.value);
  };

  changeAddress(key, value) {
    let address = Object.assign(this.state.address, { [key]: value });
    if (key !== 'raw') {
      address.raw = address.street + ' ' + address.streetnr;
    }

    this.setState({
      address
    });

    if (this.props.onChange) {
      this.props.onChange(address);
    }
  }

  render() {
    const { classes } = this.props;
    let content;
    if (this.props.editMode) {
      content = (
        <div>
          <_ListItem
            key="address"
            primary={
              <AddressAutocomplete
                placeholder="Addresse"
                value={this.state.address.raw}
                onChange={this.handleAutocompleteChange}
                onSelected={this.handleAutocompleteSelected}
              />
            }
            icon={<LocationOnIcon />}
            after={[
              <IconButton key="menutoggle" onClick={this.toggleMenu.bind(this)}>
                {this.state.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>,
              this.props.disableDeleting ? null : (
                <IconButton key="close" onClick={this.props.onClose}>
                  <CloseIcon />
                </IconButton>
              )
            ]}
            noBtn={this.props.noBtn}
          />

          <Collapse key="addressinfo" in={this.state.open}>
            <_ListItem
              className={classes.nested}
              key="street"
              primary={[
                <Input
                  key="street"
                  placeholder="Straße"
                  className="list-textfield street"
                  value={this.state.address.street}
                  onChange={this.handleChange('street')}
                />,
                <Input
                  key="streetnr"
                  placeholder="Nr"
                  className="list-textfield streetnr"
                  value={this.state.address.streetnr}
                  onChange={this.handleChange('streetnr')}
                />
              ]}
              noBtn={this.props.noBtn}
            />

            <_ListItem
              className={classes.nested}
              key="city"
              primary={[
                <Input
                  key="zip"
                  placeholder="PLZ"
                  className="list-textfield plz"
                  value={this.state.address.zip}
                  onChange={this.handleChange('zip')}
                />,
                <Input
                  key="city"
                  placeholder="Stadt"
                  className="list-textfield city"
                  value={this.state.address.city}
                  onChange={this.handleChange('city')}
                />
              ]}
              noBtn={this.props.noBtn}
            />

            <_ListItem
              className={classes.nested}
              key="country"
              primary={
                <Input
                  placeholder="Land"
                  className="list-textfield"
                  value={this.state.address.country}
                  onChange={this.handleChange('country')}
                />
              }
              noBtn={this.props.noBtn}
            />
          </Collapse>

          <_ListItem
            key="description"
            primary={
              <TextField
                id="select-currency"
                select
                value={this.state.address.description}
                onChange={this.handleChange('description')}
                margin="none"
              >
                {this.addressNames.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            }
            noBtn={this.props.noBtn}
          />
        </div>
      );
    } else {
      content = (
        <_ListItem
          primary={
            this.state.address.street + ' ' + this.state.address.streetnr
          }
          secondary={this.state.address.description}
          icon={<LocationOnIcon />}
          noBtn={this.props.noBtn}
        />
      );
    }

    return content;
  }
}

export default withStyles(styles)(AddressListItem);
