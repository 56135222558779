import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Provider } from 'react-redux';
import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import './index.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import moment from 'moment';
import App from './App';
import registerHandlebarHelpers from './config/handlebars-helper';
// import NotAvailable from './components/NotAvailable';
import 'moment/locale/de';

// Handlebars
registerHandlebarHelpers();

// Analytics
ReactGA.initialize('UA-96168111-3');

// Locale
moment.locale('de');

// Theme
const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },

  palette: {
    primary: {
      light: blue['A100'],
      main: blue['500'],
      dark: blue['800'],
      contrastText: '#fff',

      /* to support material-ui-pickers */
      '200': blue['200'],
      '500': blue['500'],
      '700': blue['700']
    },

    /* to support material-ui-pickers */
    common: {
      lightWhite: 'rgba(255, 255, 255, 0.54)'
    },

    secondary: blueGrey
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
        <div>
          <App />
        </div>
      </MuiPickersUtilsProvider>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
registerServiceWorker();

// Some sexy prototype stuff
Date.prototype.addDays = function(days) {
  var dat = new Date(this.valueOf());
  dat.setDate(dat.getDate() + days);
  return dat;
};

Date.prototype.diffDays = function(secondDate) {
  return Math.round((secondDate - this.valueOf()) / (1000 * 60 * 60 * 24));
};

String.prototype.regexEscape = function() {
  return this.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
};
