import React, { Component } from 'react';
import Preloader from '../../components/Preloader';
import Overlay from '../../components/Overlay';
import CustomerInvoiceList from '../../components/CustomerInvoiceList';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {
  KundennummerListItem,
  EmailListItem,
  PhoneListItem,
  MobileListItem
} from '../../components/ListItem';
import AddressList from '../../components/AddressList';
import DeleteModal from '../../components/Modal/DeleteModal';
import { Redirect } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import { deleteCustomer } from '../../actions/customer';
import { connect } from 'react-redux';
import { gotoCustomers, gotoCustomerEdit } from '../../helper/transition';
import { generateCustomerVCard } from '../../helper/vcard';
import CustomerDetailsBase, {
  mapStateToProps,
  mapDispatchToProps
} from '../../sites/Customer/details';

class CustomerDetails extends CustomerDetailsBase {
  render() {
    // If loading
    if (!this.props.initialLoadDone) {
      return <Preloader />;
    }

    // Get Customer
    let customer = this.state.customer;

    // If not found
    if (!customer && (this.props.initialLoadDone || this.props.error)) {
      return (
        <Redirect
          to={{ pathname: '/404', state: { from: this.props.location } }}
        />
      );
    } else if (!customer || this.state.deleting) {
      return <Preloader />;
    }
    let data = customer.data();

    // Buttons
    let rightButtons = [
      <IconButton
        className="white-btn"
        key="edit"
        onClick={() => gotoCustomerEdit(this.props.history, customer.id)}
      >
        <EditIcon />
      </IconButton>,
      <IconButton onClick={this.openMenu} className="white-btn" key="more">
        <MoreVertIcon />
      </IconButton>,
      <Menu
        key="more-menu"
        id="customer-menu"
        anchorEl={this.state.menuAnchor}
        open={this.state.menuOpen}
        onClose={this.closeMenu}
      >
        <MenuItem onClick={this.exportCustomer}>Exportieren</MenuItem>
        <MenuItem
          disabled={this.state.deleting}
          onClick={() => {
            this.setState({ deleteModalOpen: true, menuOpen: false });
          }}
        >
          {this.state.deleting ? 'Löschen...' : 'Löschen'}
        </MenuItem>
      </Menu>
    ];

    return (
      <Overlay
        onBack={this.goBack.bind(this)}
        rightButtons={rightButtons}
        title={data.title + ' ' + data.firstname + ' ' + data.lastname}
        subtitle={data.company}
      >
        <List subheader={<ListSubheader disableSticky>Kunde</ListSubheader>}>
          <KundennummerListItem data={data.cid} />
          <EmailListItem data={data.email} />
          <PhoneListItem data={data.phone} />
          <MobileListItem data={data.mobile} />
        </List>
        <Divider />
        <AddressList addresses={data.addresses} />
        <Divider />
        <CustomerInvoiceList
          history={this.props.history}
          customer={customer}
          title="Rechnungen"
        />
        <DeleteModal
          open={this.state.deleteModalOpen}
          ok={this.deleteCustomer}
          onClose={() => {
            this.setState({ deleteModalOpen: false });
          }}
        />
      </Overlay>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetails);
