import Settings from './Settings';
import firebase from './firebase';

export default {

  storageRef: null,

  init(){
    this.storageRef = firebase.storage().ref().child(firebase.auth().currentUser.uid);
  },

  uploadFile(destination, file){
    return this.storageRef.child(destination).put(file);
  },

  deleteFile(path){
    return this.storageRef.child(path).delete();
  }

};
