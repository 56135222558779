import React, { Component } from 'react';
import Modal from '../';

export default class DeleteModal extends Component {
  ok() {
    this.props.ok();
  }

  cancel() {
    this.props.onClose();
  }

  render() {
    return (
      <Modal
        title="Wirklich löschen?"
        text="Dieser Schritt kann nicht rückgängig gemacht werden."
        okText="Ja,  löschen!"
        cancelText="Abbrechen"
        open={this.props.open}
        handleRequestOk={this.ok.bind(this)}
        handleRequestClose={this.cancel.bind(this)}
      />
    );
  }
}
