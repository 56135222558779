import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  card: {
    background: red[400]
  },

  icon: {
    fontSize: 28,
    marginRight: 7
  },

  headline: {
    display: 'flex',
    color: theme.palette.common.white,
    marginBottom: 4
  },

  text: {
    color: theme.palette.common.white
  }
});

class ErrorCard extends Component {
  render() {
    const { classes } = this.props;

    let headline = this.props.title || 'Ärgerlich ...';
    let text =
      this.props.text ||
      'Da ist etwas schief gelaufen. Wende dich bitte an den Support.';
    let buttonText = this.props.okText || 'Okay';
    let onOk = this.props.onOk ? this.props.onOk : () => {};

    return (
      <Card className={'simple-card ' + classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2" className={classes.headline}>
            <ErrorOutlineIcon className={classes.icon} /> {headline}
          </Typography>
          <Typography className={classes.text} variant="body1">
            {text}
          </Typography>
        </CardContent>
        <CardActions>
          <Button disabled={this.props.loading} onClick={onOk} color="default">
            {buttonText}
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(ErrorCard);
