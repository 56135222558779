import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

export default class PublicRoute extends Component {
  render() {
    let { component: Component, hasAuth, ...args } = this.props;
    return (
      <Route
        {...args}
        render={props =>
          hasAuth === false ? (
            <Component {...Object.assign({}, this.props, props)} />
          ) : (
            <Redirect
              to={{
                pathname: '/dashboard',
                state: { from: this.props.location }
              }}
            />
          )
        }
      />
    );
  }
}
