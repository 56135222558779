import React, { Component } from 'react';
import Firestore from '../../services/Firestore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import SettingsIcon from '@material-ui/icons/Settings';
import LinkIcon from '@material-ui/icons/Link';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import EmailIcon from '@material-ui/icons/Mail';
import SecurityIcon from '@material-ui/icons/Security';
import InfoOutlineIcon from '@material-ui/icons/InfoOutlined';
import SubjectIcon from '@material-ui/icons/Subject';
import DescriptionIcon from '@material-ui/icons/Description';

export default class Settings extends Component {
  transition = path => {
    this.props.history.push(path);
  };

  render() {
    return (
      <div>
        <List>
          <ListItem button onClick={() => this.transition('/settings/general')}>
            <ListItemIcon>
              <SettingsApplicationsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Allgemeine Einstellungen"
              secondary="Grundlegende MyVoiz Einstellungen"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => this.transition('/settings/accounts')}
          >
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText
              primary="Verlinkte Accounts"
              secondary="Deine verlinkten Service-Accounts"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => this.transition('/settings/invoice-template')}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
              primary="Rechnungsvorlage"
              secondary="Wähle ein Design für Rechnungen"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => this.transition('/settings/invoice-information')}
          >
            <ListItemIcon>
              <ArtTrackIcon />
            </ListItemIcon>
            <ListItemText
              primary="Rechnungsangaben"
              secondary="Deine Angaben für die Rechnungen"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => this.transition('/settings/mail-template')}
          >
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText
              primary="Emailvorlage"
              secondary="Wähle ein Design für Emails"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => this.transition('/settings/mail-information')}
          >
            <ListItemIcon>
              <SubjectIcon />
            </ListItemIcon>
            <ListItemText
              primary="Emailangaben"
              secondary="Deine Angaben für die Emails"
            />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <InfoOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItem>
        </List>
      </div>
    );
  }
}
