import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styles from '../../pageStyles';
import img1 from './buh.jpg';

class CEO extends Component {
  onChange = e => {
    this.props.onStatusChange(e.target.value.length > 0);
    this.props.onChange({
      'invoiceData.managingDirector': e.target.value
    });
  };

  render() {
    let { classes } = this.props;
    return (
      <React.Fragment>
        <img src={img1} className={classes.image} />
        <h1 className={classes.h1}>Geschäftsführer?</h1>
        <h2 className={classes.h2}>Jetzt der Name des Geschäftsführer.</h2>
        <TextField
          autoFocus
          label={'Name des Geschäftsführer'}
          placeholder={'Max Mustermann'}
          type="text"
          onChange={this.onChange}
          fullWidth
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CEO);
