import React, { Component } from 'react';
import firebase from '../../../../services/firebase';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  card: {
    position: 'relative'
  },

  modal: {
    boxShadow: 'none'
  }
});

class PayNoticeCard extends Component {
  handleChange = event => {
    this.props.onChange(event.target.value);
  };

  render() {
    const { classes } = this.props;

    return (
      <Card
        className={
          classes.card +
          (this.props.modal ? ' ' + classes.modal : ' simple-card')
        }
      >
        <CardContent className="no-top-padding">
          <TextField
            className="fullwidth"
            label="Notizen"
            value={this.props.notice}
            onChange={this.handleChange}
            margin="normal"
            multiline
            rowsMax="4"
          />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(PayNoticeCard);
