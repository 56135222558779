import React, { Component } from 'react';
import InvoiceDetailsBase, {
  mapStateToProps,
  mapDispatchToProps
} from '../../sites/Invoice/details';
import Preloader from '../../components/Preloader';
import Overlay from '../../components/Overlay';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {
  KundennummerListItem,
  EmailListItem,
  PhoneListItem,
  MobileListItem
} from '../../components/ListItem';
import AddressList from '../../components/AddressList';
import { Redirect } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import { deleteInvoice, updateInvoice } from '../../actions/invoice';
import { connect } from 'react-redux';
import {
  gotoInvoices,
  gotoInvoiceStart,
  gotoInvoiceMarkPayed
} from '../../helper/transition';
import { InvoiceModel } from '../../helper/models';
import InvoiceItemsCard from '../../components/Cards/InvoiceItemsCard';
import DateCard from '../../components/Cards/InvoiceDetailCards/DateCard';
import CustomerCard from '../../components/Cards/InvoiceDetailCards/CustomerCard';
import InvoiceImageOverlay from '../../components/InvoiceImageOverlay';
import InvoiceTopInfoBar from '../../components/InvoiceTopInfoBar';
import MailCard from '../../components/Cards/InvoiceDetailCards/MailCard';
import CircularProgress from '@material-ui/core/CircularProgress';
import TemplateRenderer from '../../components/TemplateRenderer';

// Observer not needed anymore :)
const OBSERVER_ACTIVE = false;
let OBSERVER = false;
class InvoiceDetails extends InvoiceDetailsBase {
  render() {
    // If loading
    if (!this.props.initialLoadDone) {
      return <Preloader />;
    }

    // Get Invoice
    let invoice = this.state.invoice;

    // If not found
    if (!invoice && this.props.initialLoadDone) {
      return (
        <Redirect
          to={{ pathname: '/404', state: { from: this.props.location } }}
        />
      );
    } else if (!invoice) {
      return <Preloader />;
    }
    let data = InvoiceModel(invoice.data());
    let mail = data.mails && data.mails.length > 0 ? data.mails[0] : null;
    let showThumbnail =
      !!data.thumbnail && (!data._reRender && data.renderStatus === 'done');

    // Buttons
    let rightButtons = [];
    if (data.type === 'draft') {
      rightButtons = this.state.editMode
        ? [
            <IconButton
              disabled={this.state.saving}
              onClick={this.cancelChanges}
              className="white-btn"
              key="save"
            >
              <CloseIcon />
            </IconButton>,
            <IconButton
              className="white-btn"
              key="send"
              onClick={this.state.saving ? () => {} : this.saveChanges}
            >
              {this.state.saving ? (
                <CircularProgress size={24} className="button-preloader" />
              ) : (
                <CheckIcon />
              )}
            </IconButton>
          ]
        : [
            <IconButton
              onClick={this.startChange}
              className="white-btn"
              key="edit"
            >
              <EditIcon />
            </IconButton>,
            <IconButton
              className="white-btn"
              key="send"
              onClick={() => {
                gotoInvoiceStart(this.props.history, invoice.id);
              }}
            >
              <SendIcon />
            </IconButton>
          ];
    }

    if (data.type === 'running') {
      rightButtons.push(
        <IconButton
          className="white-btn"
          key="send"
          onClick={() => {
            gotoInvoiceMarkPayed(this.props.history, invoice.id);
          }}
        >
          <DoneAllIcon />
        </IconButton>
      );
    }

    const image = (
      <TemplateRenderer
        data={data}
        thumbnail={showThumbnail ? data.thumbnail.url : false}
      />
    );

    return (
      <Overlay
        onImageClick={this.fullscreenInvoice(true)}
        onBack={this.goBack.bind(this)}
        rightButtons={rightButtons}
        title={''}
        subtitle={''}
        hasBackgroundImage
        backgroundFilter
        backgroundCover
        backgroundTop
        backgroundImage={image}
      >
        <InvoiceTopInfoBar invoice={invoice} dispatch={this.props.dispatch} />
        <CustomerCard
          customer={this.state.customer}
          readOnly={!this.state.editMode}
          selectedAddress={data.data.selectedAddress}
          loading={!this.props.customerInitialLoadDone}
        />
        {data.type !== 'draft' ? <MailCard mail={mail} /> : null}
        <InvoiceItemsCard
          onChange={this.onItemsChange}
          noTitle
          readOnly={!this.state.editMode}
          items={
            this.state.editMode ? this.state.changedItems : data.data.items
          }
        />
        <InvoiceImageOverlay
          image={image}
          actionsDisabled={data.renderStatus !== 'done'}
          backupRunning={this.state.backupRunning}
          backupSuccessful={!!data.additionalData.driveBackup}
          onBackup={this.backup}
          onDownload={this.download(
            data.pdf ? data.pdf.url : null,
            data.invoiceId ? data.invoiceId : 'draft' + '.pdf'
          )}
          onPrint={this.print(data.pdf ? data.pdf.url : null)}
          onBack={this.fullscreenInvoice(false)}
          visible={this.state.fullscreenImage}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.driveSetupDialogOpen}
          autoHideDuration={6000}
          onClose={this.driveSetupDialogClose}
          message="Google Drive Backup wurde noch nicht eingerichtet."
          action={
            <Button onClick={this.gotoDriveSetup} color="primary" size="small">
              Jetzt Einrichten
            </Button>
          }
        />
      </Overlay>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceDetails);
