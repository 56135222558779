import React, { Component } from 'react';
import _ from 'lodash';
import Settings from '../../services/Settings';

export default class MailInformationBase extends Component {
  state = {
    initial: {},
    saving: false,
    animation: 'hidden',
    subject: '',
    text: '',
    senderName: '',
    senderMail: ''
  };

  componentWillMount() {
    this.updateData();
  }

  goBack = () => {
    this.props.history.goBack();
  };

  onChange = (name, val) => {
    this.setState({ [name]: val }, () => {
      this.updateSaveButtonVisibility();
    });
  };

  updateData = (cb = () => {}) => {
    this.setState(
      {
        subject: Settings.get('invoiceEmailData.subject', ''),
        text: Settings.get('invoiceEmailData.text', ''),
        senderName: Settings.get('invoiceEmailData.senderName', ''),
        senderMail: Settings.get('invoiceEmailData.senderMail', '')
      },
      () => {
        this.updateInitial(cb);
      }
    );
  };

  discard = () => {
    this.updateData(() => {
      this.updateSaveButtonVisibility();
    });
  };

  save = () => {
    this.setState({ saving: true });
    Settings.update({ invoiceEmailData: this.generateStateObject() }).then(
      () => {
        this.setState({ saving: false });
        this.updateInitial();
        this.updateSaveButtonVisibility();
      }
    );
  };

  generateStateObject = () => {
    let { subject, text, senderName, senderMail } = this.state;
    return { subject, text, senderName, senderMail };
  };

  updateInitial = (cb = () => {}) => {
    this.setState(
      {
        initial: this.generateStateObject()
      },
      cb
    );
  };

  updateSaveButtonVisibility = () => {
    let saveVisible = this.somethingChanged();
    this.setState({
      saveVisible,
      animation: saveVisible ? 'rollIn' : 'rollOut'
    });
  };

  somethingChanged = () => {
    return !_.isEqual(this.state.initial, this.generateStateObject());
  };
}
