import React, { Component } from 'react';
import Input from '@material-ui/core/TextField';
import _ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default class ListItem extends Component {
  render() {
    let content;
    if (this.props.editMode) {
      content = (
        <ListItemText
          inset
          primary={
            <Input
              placeholder={this.props.placeholder}
              className="list-textfield"
              defaultValue={this.props.primary}
              onChange={this.props.onChange}
            />
          }
        />
      );
    } else {
      if (!this.props.primary) {
        return null;
      }

      content = (
        <ListItemText
          inset
          primary={this.props.primary}
          secondary={this.props.secondary}
        />
      );
    }
    let icon = this.props.icon ? (
      <ListItemIcon>{this.props.icon}</ListItemIcon>
    ) : null;
    let after = this.props.after ? this.props.after : null;
    let className = this.props.className ? this.props.className : null;

    return (
      <_ListItem className={className} button={!this.props.noBtn}>
        {icon}
        {content}
        {after}
      </_ListItem>
    );
  }
}
