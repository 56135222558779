import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  card: {
    position: 'relative',
    boxShadow: 'none',
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: theme.palette.secondary.main,
    borderRadius: 4
  },
  media: {
    height: 260,
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  }
});

class InvoiceTemplateCard extends Component {
  onChange = key => event => {
    this.props.onChange(key, event.target.value);
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={'simple-card ' + classes.card}>
        {this.props.img ? (
          <CardMedia className={classes.media} image={this.props.img} />
        ) : null}
        <CardContent>
          <Typography variant="h5" component="h2">
            {this.props.title}
          </Typography>
          <Typography component="p">{this.props.description}</Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={this.props.onActivate}
            disabled={this.props.used || this.props.loading}
            size="small"
            color="primary"
          >
            {this.props.used
              ? 'Wird verwendet'
              : this.props.loading
              ? '...'
              : 'Verwenden'}
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(InvoiceTemplateCard);
