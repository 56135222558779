import Handlebars from 'handlebars';
import moment from 'moment';

export default function registerHelper() {
  Handlebars.registerHelper('url', function(url) {
    url = url ? url.replace('"', '').replace("'", '') : '';
    return new Handlebars.SafeString(url);
  });

  Handlebars.registerHelper('dateAdd', function(context) {
    var days = context.hash.days || 0;
    var date = context.hash.date ? context.hash.date : new Date();
    if (date && date.toDate) {
      date = date.toDate();
    }
    date.setDate(date.getDate() + days);
    return date;
  });

  Handlebars.registerHelper('dateFormat', function(context) {
    var f = context.hash.format || 'L';
    var date = context.hash.date ? context.hash.date : new Date();
    if (date && date.toDate) {
      date = date.toDate();
    }
    return moment(date).format(f);
  });

  Handlebars.registerHelper('cashFormat', function(val) {
    return (parseFloat(val).toFixed(2) + '').replace('.', ',');
  });

  Handlebars.registerHelper('atLeastOneThere', function(...args) {
    let res = false;
    args.forEach(val => {
      if (val) res = true;
    });
    return res;
  });

  Handlebars.registerHelper('math', function(
    lvalue,
    operator,
    rvalue,
    options
  ) {
    lvalue = parseFloat(lvalue);
    rvalue = parseFloat(rvalue);

    return {
      '+': (l, r) => {
        return l + r;
      },
      '-': (l, r) => {
        return l - r;
      },
      '*': (l, r) => {
        return l * r;
      },
      '/': (l, r) => {
        return l / r;
      },
      '%': (l, r) => {
        return l % r;
      }
    }[operator](lvalue, rvalue);
  });
}
