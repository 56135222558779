import React, { Component } from 'react';
import { addInvoice } from '../../actions/invoice';
import { gotoInvoices } from '../../helper/transition';
import { InvoiceModel } from '../../helper/models';

export default class InvoiceAddBase extends Component {
  constructor(props) {
    super(props);

    let invoiceDate = null;
    let invoiceDueDays = null;

    this.state = {
      newInvoice: InvoiceModel({
        invoiceDate,
        invoiceDueDays,
        type: 'draft'
      }),
      saving: false
    };
  }

  addAndClose() {
    this.setState({ saving: true });
    this.props.dispatch(
      addInvoice(InvoiceModel(this.state.newInvoice), (err, invoice) => {
        if (err) {
          this.setState({ saving: false });
        } else {
          gotoInvoices(this.props.history);
        }
      })
    );
  }

  goBack() {
    this.props.history.goBack();
  }

  customerChanged(customer, address) {
    this.setState({
      newInvoice: Object.assign(this.state.newInvoice, {
        customer: customer ? customer.ref : null,
        data: Object.assign(this.state.newInvoice.data, {
          customer: customer ? customer.data() : null,
          selectedAddress:
            address >= 0 && address !== ''
              ? customer.data().addresses[address]
              : null
        })
      })
    });
  }

  itemsChanged(items) {
    this.setState({
      newInvoice: Object.assign(this.state.newInvoice, {
        data: Object.assign(this.state.newInvoice.data, {
          items
        })
      })
    });
  }
}

export function mapStateToProps(state, ownProps) {
  return {
    initialLoadDone: state.customer.initialLoadDone,
    customers: state.customer.customers
  };
}

export function mapDispatchToProps(dispatch) {
  return { dispatch };
}
