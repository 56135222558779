import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../components/Modal';
import UserBase from '../../sites/Settings/user';
import EmailVerifyCard from '../../components/Cards/EmailVerifyCard';
import EmailPasswordCard from '../../components/Cards/EmailPasswordCard';
import LinkedAccountCard from '../../components/Cards/LinkedAccountCard';
import APITokenDialog from '../../components/Dialogs/APITokenDialog';
import facebook from '../../img/facebook.svg';
import google from '../../img/google.svg';
import api from '../../img/api.svg';

const styles = theme => ({
  wrapper: {
    width: 400,
    margin: '60px auto 10px auto'
  }
});

class User extends UserBase {
  render() {
    let { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        <EmailVerifyCard visible={!this.props.user.emailVerified} />
        <EmailPasswordCard
          user={this.props.user}
          title="Dein Konto"
          onChange={() => {
            this.forceUpdate();
          }}
        />
        <LinkedAccountCard
          image={google}
          title="Google"
          subtitle="Verlinke dein Konto."
          onChange={this.toggleGoogle}
          checked={this.state.google}
        />
        <LinkedAccountCard
          image={facebook}
          title="Facebook"
          subtitle="Verlinke dein Konto."
          onChange={this.toggleFacebook}
          checked={this.state.facebook}
        />
        <LinkedAccountCard
          image={api}
          title="API"
          subtitle="für 3rd Party Services."
          onChange={this.toggleAPI}
          checked={this.state.api}
          onSettings={this.state.api ? this.openAPISettings : false}
          disabled={this.state.apiLoading}
        />
        <APITokenDialog
          open={this.state.apiModalVisible}
          onClose={this.closeAPISettings}
          onAdd={this.addAPIToken}
          token={this.state.apiToken}
          onDelete={this.deleteAPIToken}
          loading={this.state.apiLoading}
        />
        <Modal
          title="API deaktivieren?"
          text="Alle erstellten API-Keys werden unwiederuflich gelöscht."
          okText="Ja,  deaktivieren!"
          cancelText="Abbrechen"
          open={this.state.apiDisableModalOpen}
          handleRequestOk={this.confirmDisableAPIModal}
          handleRequestClose={this.closeDisableAPIModal}
        />
      </div>
    );
  }
}

export default withStyles(styles)(User);
