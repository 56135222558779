import firebase from '../services/firebase';
import Firestore from '../services/Firestore';

export function fetchInvoicesAuto(initialCb) {
  return function(dispatch) {
    Firestore.db()
      .collection('invoices')
      .where('_userid', '==', firebase.auth().currentUser.uid)
      .onSnapshot(snapshot => {
        dispatch({
          type: 'FETCH_INVOICES_AUTO_UPDATE',
          payload: snapshot.docs
        });
        if (initialCb) {
          initialCb(null, snapshot.docs);
          initialCb = null;
        }
      });
  };
}

export function updateInvoice(id, payload, cb = null) {
  payload = Object.assign(payload, {
    _userid: firebase.auth().currentUser.uid
  });
  return function(dispatch) {
    dispatch({ type: 'UPDATE_INVOICE_PENDING' });
    payload.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    Firestore.db()
      .collection('invoices')
      .doc(id)
      .update(payload)
      .then(docRef => {
        if (cb) {
          cb(null, docRef);
        }
      })
      .catch(error => {
        if (cb) {
          cb(error);
        }
      });
  };
}

export function addInvoice(payload, cb = null) {
  return function(dispatch) {
    dispatch({ type: 'ADD_INVOICE_PENDING' });
    let db = Firestore.db();
    let { uid } = firebase.auth().currentUser;
    let stats = db.doc(`stats/${uid}`);
    payload.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    var newInvoiceRef = db.collection('invoices').doc();
    db.runTransaction(transaction => {
      return transaction.get(stats).then(statsSnapshot => {
        if (!statsSnapshot.exists) {
          throw 'Stats not found!';
        }
        transaction.update(stats, {
          invoicesCreated: statsSnapshot.data().invoicesCreated + 1,
          invoicesTotal: statsSnapshot.data().invoicesTotal + 1
        });
        transaction.set(newInvoiceRef, payload);
        return Promise.resolve(newInvoiceRef);
      });
    })
      .then(docRef => {
        if (cb) {
          cb(null, docRef);
        }
      })
      .catch(error => {
        if (cb) {
          cb(error);
        }
      });
  };
}

export function deleteInvoice(id, cb = null) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_INVOICE_PENDING', payload: id });
    Firestore.db()
      .collection('invoices')
      .doc(id)
      .delete()
      .then(function() {
        dispatch({ type: 'DELETE_INVOICE_FULFILLED', payload: id });
        if (cb) {
          cb(null, id);
        }
      })
      .catch(function(error) {
        dispatch({ type: 'DELETE_INVOICE_REJECTED', payload: { id, error } });
        if (cb) {
          cb(error);
        }
      });
  };
}
