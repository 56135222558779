import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../pageStyles';
import img1 from './relax.jpg';

class Intro extends Component {
  componentDidMount() {
    this.props.onStatusChange(true);
  }

  render() {
    let { classes } = this.props;
    return (
      <React.Fragment>
        <img src={img1} className={classes.image} />
        <h1 className={classes.h1}>Fast geschafft!</h1>
        <h2 className={classes.h2}>
          Wir führen dich durch die Einrichtung damit sie für dich Kinderleicht
          ist. Keine Sorge, das geht ganz schnell.
        </h2>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Intro);
