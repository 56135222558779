import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  updateCustomer,
  addCustomer,
  deleteCustomer
} from '../../actions/customer';
import { gotoCustomer, gotoCustomers } from '../../helper/transition';
import { CustomerModel, CleanupPayload } from '../../helper/models';
import {
  updateCustomerNumber,
  nextCustomerNumber
} from '../../helper/number-gen';

export default class CustomerEditBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      mode: props.match.params.id ? 'edit' : 'add',
      newData: props.match.params.id
        ? {}
        : CustomerModel({ cid: nextCustomerNumber() }),
      saving: false
    };
  }

  deleteCustomer = event => {
    this.setState({ deleting: true });
    this.props.dispatch(
      deleteCustomer(this.state.customer.id, err => {
        if (err) {
          this.setState({ deleting: false });
        } else {
          gotoCustomers(this.props.history);
        }
      })
    );
  };

  openMenu = event => {
    this.setState({
      menuAnchor: event.currentTarget,
      menuOpen: true
    });
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  componentWillMount() {
    if (this.props.initialLoadDone) {
      this.updateStateCustomer(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialLoadDone) {
      this.updateStateCustomer(nextProps);
    }
  }

  cleanNewData() {
    let newData = CleanupPayload(this.state.newData);
    if (newData.addresses) {
      newData.addresses.map(address => {
        return CleanupPayload(address);
      });
    }
    return newData;
  }

  saveAndClose() {
    this.setState({ saving: true });
    let newData = this.cleanNewData();
    this.props.dispatch(
      updateCustomer(this.state.customer.id, newData, err => {
        if (err) {
          this.setState({ saving: false });
        } else {
          gotoCustomer(this.props.history, this.state.customer.id);
        }
      })
    );
  }

  addAndClose() {
    this.setState({ saving: true });
    this.props.dispatch(
      addCustomer(CustomerModel(this.state.newData), (err, customer) => {
        if (err) {
          this.setState({ saving: false });
        } else {
          updateCustomerNumber(this.state.newData.cid)
            .then(() => {
              gotoCustomer(this.props.history, customer.id);
            })
            .catch(err => {
              // todo: maybe better error handling later
              gotoCustomer(this.props.history, customer.id);
            });
        }
      })
    );
  }

  updateStateCustomer(props) {
    // Search Customer and set to state.customer
    if (this.state.mode === 'edit') {
      let customer = props.customers.find(customer => {
        return customer.id === props.match.params.id;
      });
      if (customer) {
        this.setState({ customer });
      } else {
        // customer does not exists. todo: some kinda fallback
      }
    }
  }

  handleChange = name => eventOrVal => {
    let val = 'target' in eventOrVal ? eventOrVal.target.value : eventOrVal;
    let newData = Object.assign(this.state.newData, {
      [name]: val
    });

    this.setState({
      newData
    });
  };

  goBack() {
    this.props.history.goBack();
  }
}

export function mapStateToProps(state, ownProps) {
  return {
    initialLoadDone: state.customer.initialLoadDone,
    customers: state.customer.customers
  };
}

export function mapDispatchToProps(dispatch) {
  return { dispatch };
}
