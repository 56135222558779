import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import async from 'async';
import GeneralBase from '../../sites/Settings/general';
import Overlay from '../../components/Overlay';
import DynamicNumberCard from '../../components/Cards/DynamicNumberCard';
import GetAppIcon from '@material-ui/icons/GetApp';
import Settings from '../../services/Settings';
import Firestore from '../../services/Firestore';
import Preloader from '../../components/Preloader';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  // Global overwritten styles from index.js
  desktopWrapper: {},
  wrap: {},
  actionBar: {},
  noChange: {},
  content: {},

  // Custom Styles
  contWrap: {
    padding: '20px 40px'
  },
  numCard: {
    margin: '40px 0 60px 0'
  },
  h3: {
    fontSize: 14,
    fontWeight: 'lighter',
    color: '#c3c3c3',
    textAlign: 'left'
  }
});

class General extends GeneralBase {
  render() {
    let { saveVisible } = this.state;
    let { classes } = this.props;
    return (
      <div className={classes.wrap}>
        <div className={classes.actionBar}>
          {saveVisible ? (
            <React.Fragment>
              <Button onClick={this.save} variant="contained" color="primary">
                Speichern
              </Button>
              &nbsp;
              <Button onClick={this.discard} color="secondary">
                Verwerfen
              </Button>
              {this.state.saving ? <Preloader /> : null}
            </React.Fragment>
          ) : (
            <span className={classes.noChange}>Einstellungen aktuell</span>
          )}
        </div>
        <div className={classes.contWrap}>
          <DynamicNumberCard
            className={classes.numCard}
            h3className={classes.h3}
            noCard
            title="Kundennummer"
            onChange={this.onChange('customer')}
            prefix={this.state.customer.prefix}
            number={this.state.customer.number}
            suffix={this.state.customer.suffix}
          />
          <DynamicNumberCard
            className={classes.numCard}
            h3className={classes.h3}
            noCard
            title="Rechnungsnummer"
            onChange={this.onChange('invoice')}
            prefix={this.state.invoice.prefix}
            number={this.state.invoice.number}
            suffix={this.state.invoice.suffix}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(General);
