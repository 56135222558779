import React, { Component } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';

// Mobile App Pages
import AnimatedOverlay from '../components/AnimatedOverlay';
import Dashboard from '../sites-mobile/Dashboard';
import Invoice from '../sites-mobile/Invoice';
import InvoiceAdd from '../sites-mobile/Invoice/add';
import InvoiceExport from '../sites-mobile/Invoice/export';
import InvoiceDetails from '../sites-mobile/Invoice/details';
import Customer from '../sites-mobile/Customer';
import CustomerDetails from '../sites-mobile/Customer/details';
import CustomerEdit from '../sites-mobile/Customer/edit';
import Login from '../sites-mobile/Login';
import Settings from '../sites-mobile/Settings';
import Accounts from '../sites-mobile/Settings/accounts';
import User from '../sites-mobile/Settings/user';
import General from '../sites-mobile/Settings/general';
import InvoiceInformation from '../sites-mobile/Settings/invoice-information';
import InvoiceTemplate from '../sites-mobile/Settings/invoice-template';
import MailTemplate from '../sites-mobile/Settings/mail-template';
import MailInformation from '../sites-mobile/Settings/mail-information';
import E404 from '../sites-mobile/E404';
import Intro from '../sites-mobile/Intro';
import Index from '../sites-mobile/Index';
import Subscription from '../sites-mobile/Settings/subscription';

// Desktop App Pages
import DesktopDashboard from '../sites-desktop/Dashboard';
import DesktopLogin from '../sites-desktop/Login';
import DesktopIndex from '../sites-desktop/Index';
import DesktopCustomer from '../sites-desktop/Customer';
import DesktopCustomerDetails from '../sites-desktop/Customer/details';
import DesktopCustomerEdit from '../sites-desktop/Customer/edit';
import DesktopInvoiceAdd from '../sites-desktop/Invoice/add';
import DesktopInvoiceIndex from '../sites-desktop/Invoice/index';
import DesktopInvoiceDetails from '../sites-desktop/Invoice/details';
import DesktopUser from '../sites-desktop/Settings/user';
import DesktopSettings from '../sites-desktop/Settings';
import DesktopSubscription from '../sites-desktop/Settings/subscription';

class Routes extends Component {
  render() {
    let {
      user,
      hasAuth,
      hasUnverifiedAuth,
      hasRealUserAccount,
      updateHeaderContent
    } = this.props;

    let routeAttr = {
      user,
      hasAuth,
      hasRealUserAccount,
      hasUnverifiedAuth,
      updateHeaderContent
    };

    let routes;
    if (isMobile) {
      routes = [
        // Public Routes
        <PublicRoute
          exact
          path="/login"
          component={Login}
          {...routeAttr}
          key="login"
        />,
        <PublicRoute
          exact
          path="/intro"
          component={Intro}
          {...routeAttr}
          key="intro"
        />,
        <PublicRoute
          exact
          path="/"
          component={Index}
          {...routeAttr}
          key="index"
        />,
        // Private Routes
        <PrivateRoute
          exact
          path="/dashboard"
          component={Dashboard}
          {...routeAttr}
          key="dashboard"
        />,
        <PrivateRoute
          exact
          path="/invoices"
          component={Invoice}
          {...routeAttr}
          key="Invoice"
        />,
        <PrivateRoute
          exact
          path="/invoices/add"
          animateWrapper={AnimatedOverlay}
          component={InvoiceAdd}
          {...routeAttr}
          key="invoiceadd"
        />,
        <PrivateRoute
          exact
          path="/invoices/export"
          animateWrapper={AnimatedOverlay}
          component={InvoiceExport}
          {...routeAttr}
          key="invoiceexport"
        />,
        <PrivateRoute
          exact
          path="/invoice/:id"
          animateWrapper={AnimatedOverlay}
          component={InvoiceDetails}
          {...routeAttr}
          key="invoicedetails"
        />,
        <PrivateRoute
          exact
          path="/invoice/:id/start"
          component={Invoice}
          invoiceAction="start"
          {...routeAttr}
          key="invoicestart"
        />,
        <PrivateRoute
          exact
          path="/invoice/:id/mark-payed"
          component={Invoice}
          invoiceAction="markPayed"
          {...routeAttr}
          key="invoicemarkpayed"
        />,
        <PrivateRoute
          exact
          path="/customers"
          component={Customer}
          {...routeAttr}
          key="customer"
        />,
        <PrivateRoute
          exact
          path="/customers/add"
          animateWrapper={AnimatedOverlay}
          component={CustomerEdit}
          {...routeAttr}
          key="customeradd"
        />,
        <PrivateRoute
          exact
          path="/customer/:id"
          animateWrapper={AnimatedOverlay}
          component={CustomerDetails}
          {...routeAttr}
          key="customerdetails"
        />,
        <PrivateRoute
          exact
          path="/customer/:id/edit"
          component={CustomerEdit}
          {...routeAttr}
          key="customeredit"
        />,
        <PrivateRoute
          exact
          path="/settings"
          component={Settings}
          {...routeAttr}
          key="settings"
        />,
        <PrivateRoute
          exact
          path="/settings/general"
          component={General}
          {...routeAttr}
          key="settings-general"
        />,
        <PrivateRoute
          exact
          path="/settings/accounts"
          component={Accounts}
          {...routeAttr}
          key="settings-accounts"
        />,
        <PrivateRoute
          exact
          path="/settings/invoice-information"
          component={InvoiceInformation}
          {...routeAttr}
          key="settings-invoice-information"
        />,
        <PrivateRoute
          exact
          path="/settings/invoice-template"
          component={InvoiceTemplate}
          {...routeAttr}
          key="settings-invoice-template"
        />,
        <PrivateRoute
          exact
          path="/settings/mail-template"
          component={MailTemplate}
          {...routeAttr}
          key="settings-mail-template"
        />,
        <PrivateRoute
          exact
          path="/settings/mail-information"
          component={MailInformation}
          {...routeAttr}
          key="settings-mail-information"
        />,
        <PrivateRoute
          exact
          path="/settings/user"
          component={User}
          {...routeAttr}
          key="settings-user"
        />,
        <PrivateRoute
          exact
          path="/settings/subscription"
          component={Subscription}
          {...routeAttr}
          key="settings-subscription"
        />,
        <PrivateRoute
          exact
          path="/404"
          component={E404}
          {...routeAttr}
          key="e404"
        />
      ];
    } else {
      routes = [
        // Public Routes
        <PublicRoute
          exact
          path="/login"
          component={DesktopLogin}
          {...routeAttr}
          key="login"
        />,
        <PublicRoute
          exact
          path="/"
          component={DesktopIndex}
          {...routeAttr}
          key="index"
        />,
        // Private Routes
        <PrivateRoute
          exact
          path="/dashboard"
          component={DesktopDashboard}
          {...routeAttr}
          key="dashboard"
        />,
        <PrivateRoute
          exact
          path="/customers"
          component={DesktopCustomer}
          {...routeAttr}
          key="customer"
        />,
        <PrivateRoute
          exact
          path="/customers/add"
          component={DesktopCustomerEdit}
          {...routeAttr}
          key="customeradd"
        />,
        <PrivateRoute
          exact
          path="/customer/:id"
          component={DesktopCustomerDetails}
          {...routeAttr}
          key="customerdetails"
        />,
        <PrivateRoute
          exact
          path="/customer/:id/edit"
          component={DesktopCustomerEdit}
          {...routeAttr}
          key="customeredit"
        />,
        <PrivateRoute
          exact
          path="/invoices/add"
          component={DesktopInvoiceAdd}
          {...routeAttr}
          key="invoiceadd"
        />,
        <PrivateRoute
          exact
          path="/invoices"
          component={DesktopInvoiceIndex}
          {...routeAttr}
          key="Invoice"
        />,
        <PrivateRoute
          exact
          path="/invoice/:id"
          component={DesktopInvoiceDetails}
          {...routeAttr}
          key="invoicedetails"
        />,
        <PrivateRoute
          exact
          path="/settings"
          component={DesktopSettings}
          {...routeAttr}
          key="settings"
        />,
        <PrivateRoute
          exact
          path="/settings/general"
          component={DesktopSettings}
          {...routeAttr}
          key="settings-general"
        />,
        <PrivateRoute
          exact
          path="/settings/accounts"
          component={DesktopSettings}
          {...routeAttr}
          key="settings-accounts"
        />,
        <PrivateRoute
          exact
          path="/settings/invoice-information"
          component={DesktopSettings}
          {...routeAttr}
          key="settings-invoice-information"
        />,
        <PrivateRoute
          exact
          path="/settings/invoice-template"
          component={DesktopSettings}
          {...routeAttr}
          key="settings-invoice-template"
        />,
        <PrivateRoute
          exact
          path="/settings/mail-template"
          component={DesktopSettings}
          {...routeAttr}
          key="settings-mail-template"
        />,
        <PrivateRoute
          exact
          path="/settings/mail-information"
          component={DesktopSettings}
          {...routeAttr}
          key="settings-mail-information"
        />,
        <PrivateRoute
          exact
          path="/settings/user"
          component={DesktopUser}
          {...routeAttr}
          key="settings-user"
        />,
        <PrivateRoute
          exact
          path="/settings/subscription"
          component={DesktopSubscription}
          {...routeAttr}
          key="settings-subscription"
        />
      ];
    }

    return <div>{routes}</div>;
  }
}

export default Routes;
