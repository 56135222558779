import React, { Component } from 'react';
import InvoiceIndexBase, {
  mapStateToProps,
  mapDispatchToProps
} from '../../sites/Invoice';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import CircularProgress from '@material-ui/core/CircularProgress';
import { deleteInvoice, updateInvoice } from '../../actions/invoice';
import DeleteModal from '../../components/Modal/DeleteModal';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DraftsIcon from '@material-ui/icons/Drafts';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import InvoiceCard from '../../components/Cards/InvoiceCard';
import InvoiceStartOverlay from '../../components/InvoiceStart/overlay';
import InvoiceMarkPayedOverlay from '../../components/InvoiceMarkPayed/overlay';
import BankSyncDialog from '../../components/Dialogs/BankSyncDialog';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {
  gotoInvoiceAdd,
  gotoInvoices,
  gotoInvoiceStart,
  gotoInvoiceMarkPayed,
  gotoInvoice
} from '../../helper/transition';
import Runtime from '../../services/Runtime';
import { updateInvoiceNumber } from '../../helper/number-gen';

function TabContainer({ children, dir }) {
  return (
    <div dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const styles = theme => ({
  root: {},

  appBar: {
    boxShadow: 'none'
  },

  swipeableView: {
    paddingTop: 72
  }
});

class Invoice extends InvoiceIndexBase {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, this.state, {
      // Selected Tab and Buttons
      selectedTab: Runtime.selectedInvoiceTab || 0,
      fabButtonClass: '',
      fabButton2Class: 'hidden',

      // Delete Modal
      deleteModalOpen: false,
      invoiceToDelete: false,
      deletingIds: [],

      // Start Invoice Dialog
      invoiceToStart: false,
      startDialogOpen: false,
      startingInvoice: false,
      startingInvoiceErr: null,

      // Mark Invoice Payed Dialog
      invoiceToMarkPayed: false,
      markPayedDialogOpen: false,
      markingInvoicePayed: false,
      markingInvoicePayedErr: null
    });
  }

  componentDidMount() {
    this.updateVisibleButton(this.state.selectedTab);
    Runtime.selectedInvoiceTab = this.state.selectedTab;
    if (this.props.invoiceAction === 'start') {
      this.setState({
        invoiceToStart: this.props.match.params.id,
        startDialogOpen: true
      });
    } else if (this.props.invoiceAction === 'markPayed') {
      this.setState({
        invoiceToMarkPayed: this.props.match.params.id,
        markPayedDialogOpen: true
      });
    }
  }

  componentWillMount() {
    if (this.props.location.state && this.props.location.state.tab) {
      this.setState({
        selectedTab: this.props.location.state.tab
      });
    }
  }

  deleteInvoice = invoice => () => {
    let deletingIds = this.state.deletingIds;
    deletingIds.push(invoice.id);
    this.setState({ deleteModalOpen: false, deletingIds });
    this.props.dispatch(
      deleteInvoice(invoice.id, err => {
        let deletingIds = this.state.deletingIds;
        deletingIds.filter(id => {
          return id !== invoice.id;
        });
        this.setState({ deletingIds });
        if (err) {
          // todo: some error handling
        }
      })
    );
  };

  handleChangeIndexOnButton = (event, value) => {
    this.changeTabsIndex(value);
  };

  handleChangeIndexOnSwipe = index => {
    this.changeTabsIndex(index);
  };

  changeTabsIndex(index) {
    this.setState({ selectedTab: index });
    this.updateVisibleButton(index);
    Runtime.selectedInvoiceTab = index;
  }

  updateVisibleButton(index) {
    let inAnimation = 'rotateIn';
    let outAnimation = 'rotateOut';
    this.setState({
      fabButtonClass: index === 0 ? inAnimation : outAnimation,
      fabButton2Class: index === 1 ? inAnimation : outAnimation
    });
    setTimeout(() => {
      this.setState({
        fabButtonClass: index === 0 ? inAnimation : outAnimation + ' hidden',
        fabButton2Class: index === 1 ? inAnimation : outAnimation + ' hidden'
      });
    }, 500);
  }

  deleteInvoiceDialog = invoiceToDelete => () => {
    this.setState({ deleteModalOpen: true, invoiceToDelete });
  };

  sendInvoiceDialog = invoiceToStart => () => {
    gotoInvoiceStart(this.props.history, invoiceToStart);
  };

  markInvoiceAsPayedDialog = invoiceToMarkedPayed => () => {
    gotoInvoiceMarkPayed(this.props.history, invoiceToMarkedPayed);
  };

  startDialogGoBack = () => {
    if (this.props.history) {
      this.props.history.goBack();
    } else {
      gotoInvoices(this.props.history);
    }
  };

  markPayedDialogGoBack = () => {
    if (this.props.history) {
      this.props.history.goBack();
    } else {
      gotoInvoices(this.props.history);
    }
  };

  startInvoice = data => {
    let { startData, invoiceData } = data;
    let updatePayload = Object.assign(invoiceData, {
      startData,
      type: 'running'
    });
    this.setState({ startingInvoice: true });
    this.props.dispatch(
      updateInvoice(this.state.invoiceToStart, updatePayload, err => {
        if (err) {
          this.setState({ startingInvoice: false, startingInvoiceErr: err });
        } else {
          updateInvoiceNumber(invoiceData.invoiceId)
            .then(() => {
              gotoInvoice(this.props.history, this.state.invoiceToStart);
            })
            .catch(err => {
              this.setState({
                startingInvoice: false,
                startingInvoiceErr: err
              });
            });
        }
      })
    );
  };

  markInvoicePayed = data => {
    let updatePayload = { payments: [data], type: 'finished' };
    this.setState({ markingInvoicePayed: true });
    this.props.dispatch(
      updateInvoice(this.state.invoiceToMarkPayed, updatePayload, err => {
        if (err) {
          this.setState({
            markingInvoicePayed: false,
            markingInvoicePayedErr: err
          });
        } else {
          gotoInvoice(this.props.history, this.state.invoiceToMarkPayed);
        }
      })
    );
  };

  render() {
    const { classes, theme, initialLoadDone, account } = this.props;
    const { deletingIds } = this.state;

    let draftContent, runningContent, finishedContent;
    if (!initialLoadDone) {
      draftContent = (
        <CircularProgress color="secondary" className="preloader" size={60} />
      );
      runningContent = (
        <CircularProgress color="secondary" className="preloader" size={60} />
      );
      finishedContent = (
        <CircularProgress color="secondary" className="preloader" size={60} />
      );
    } else {
      draftContent = this.getProcessedInvoices('draft').map(invoice => (
        <InvoiceCard
          key={invoice.id}
          progress={deletingIds.indexOf(invoice.id) > -1}
          onClose={this.deleteInvoiceDialog(invoice)}
          onCreate={this.sendInvoiceDialog(invoice.id)}
          invoice={invoice}
          history={this.props.history}
        />
      ));
      runningContent = this.getProcessedInvoices('running').map(invoice => (
        <InvoiceCard
          key={invoice.id}
          invoice={invoice}
          history={this.props.history}
          onMarkPayed={this.markInvoiceAsPayedDialog(invoice.id)}
        />
      ));
      finishedContent = this.getProcessedInvoices('finished').map(invoice => (
        <InvoiceCard
          key={invoice.id}
          invoice={invoice}
          history={this.props.history}
        />
      ));
    }

    return (
      <div className={classes.root}>
        <AppBar
          className={'below-app-bar ' + classes.appBar}
          position="fixed"
          color="primary"
        >
          <Tabs
            value={this.state.selectedTab}
            onChange={this.handleChangeIndexOnButton}
            indicatorColor="primary"
            fullWidth
          >
            <Tab icon={<DraftsIcon />} label="Entwurf" />
            <Tab icon={<DoneIcon />} label="Laufend" />
            <Tab icon={<DoneAllIcon />} label="Fertig" />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.selectedTab}
          onChangeIndex={this.handleChangeIndexOnSwipe}
          className={classes.swipeableView + ' invoice-swipe'}
        >
          <TabContainer dir={theme.direction}>{draftContent}</TabContainer>
          <TabContainer dir={theme.direction}>{runningContent}</TabContainer>
          <TabContainer dir={theme.direction}>{finishedContent}</TabContainer>
        </SwipeableViews>
        <Fab
          onClick={() => gotoInvoiceAdd(this.props.history)}
          className={'default-fab animated ' + this.state.fabButtonClass}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
        <Fab
          disabled={this.state.syncBankLoading}
          onClick={() => {
            this.syncBankAccount();
          }}
          className={'default-fab animated ' + this.state.fabButton2Class}
          color="primary"
          aria-label="add"
        >
          {this.state.syncBankLoading ? (
            <CircularProgress
              color="secondary"
              className={classes.btnpreloader}
              size={24}
            />
          ) : (
            <AccountBalanceIcon />
          )}
        </Fab>
        <DeleteModal
          open={this.state.deleteModalOpen}
          ok={this.deleteInvoice(this.state.invoiceToDelete)}
          onClose={() => {
            this.setState({ deleteModalOpen: false });
          }}
        />
        <InvoiceStartOverlay
          subscription={account.data().subscriptionData}
          onStart={this.startInvoice}
          error={this.state.startingInvoiceErr}
          starting={this.state.startingInvoice}
          visible={this.state.startDialogOpen}
          invoice={this.findInvoice(this.state.invoiceToStart)}
          onBack={this.startDialogGoBack}
        />
        <InvoiceMarkPayedOverlay
          onMarkPayed={this.markInvoicePayed}
          error={this.state.startingInvoiceErr}
          visible={this.state.markPayedDialogOpen}
          invoice={this.findInvoice(this.state.invoiceToMarkPayed)}
          markingAsPayed={this.state.markingInvoicePayed}
          onBack={this.markPayedDialogGoBack}
        />
        <BankSyncDialog
          mobileLayout
          onSubmit={this.bankSyncDialogSubmit}
          loading={this.state.syncBankDialogLoading}
          onClose={this.bankSyncDialogClose}
          open={this.state.syncBankDialogOpen}
          transactions={this.state.relevantBankTransactions}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Invoice));
