import Settings from './Settings';
import firebase from './firebase';

export default {
  isInit: false,
  _db: null,
  currentlyPersistent: false,
  error: null,

  db() {
    return this._db;
  },

  init() {
    // Settings never isInit=true => it will everytimes do the offline check, thats okay cuz offline
    // Data is updated after every start. If the device would get untrusted, it would be not persistent
    // anymore after the next start.
    let persistent = Settings.isInit
      ? Settings.getDevice('trusted')
      : Settings.isTrustedDeviceOfflineCheck();
    console.log('Init persistent:', persistent);
    return this.updateDB(persistent);
  },

  updateDB(persistent) {
    return new Promise((resolve, reject) => {
      if (persistent) {
        firebase
          .firestore()
          .enablePersistence()
          .then(() => {
            this._db = firebase.firestore();
            this.currentlyPersistent = true;
            this.isInit = true;
            resolve();
          })
          .catch(err => {
            this.error = err;
            reject(err);
          });
      } else {
        this._db = firebase.firestore();
        this.currentlyPersistent = false;
        this.isInit = true;
        resolve();
      }
    });
  }
};
