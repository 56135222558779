import React, { Component } from 'react';
import moment from 'moment';
import firebase from '../../../services/firebase';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Moment from 'react-moment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DraftsIcon from '@material-ui/icons/Drafts';
import ShareIcon from '@material-ui/icons/Share';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { gotoInvoice } from '../../../helper/transition';
import { CustomerModel } from '../../../helper/models';
import { getDueWarnLevel } from '../../../helper/calc';
import request from '../../../helper/request';
import Preloader from '../../Preloader';
import Modal from '../../Modal';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  card: {
    position: 'relative',
    marginBottom: 30
  },

  avatar: {},

  content: {
    position: 'relative',
    color: theme.palette.common.white
  },

  closeBtn: {
    color: theme.palette.grey['500'],
    position: 'absolute',
    right: 0,
    top: 0
  },

  dueInfo: {
    width: '100%',
    padding: '20px 0',
    textAlign: 'center',
    color: 'white'
  },

  dueInfoSuccess: {
    backgroundColor: '#a0d2b0'
  },

  dueInfoWarning: {
    backgroundColor: '#d2c7a0'
  },

  dueInfoDanger: {
    backgroundColor: '#d2a0a0'
  }
});

class InvoiceCard extends Component {
  state = {
    shareLoading: false,
    shareModalOpen: false,
    shareDaysTillExpires: null,
    shareUrl: null
  };

  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  share() {
    this.setState({ shareLoading: true });
    request.getClient().then(client => {
      client.__cloudfunctions.invoice
        .getShareLink(this.props.invoice.id)
        .then(res => {
          let today = moment(),
            expires = moment(res.data.expires, 'MM-DD-YYYY');
          let daysTillExpires = expires.diff(today, 'days') + 1;
          this.setState({
            shareLoading: false,
            shareUrl: res.data.url,
            shareDaysTillExpires: daysTillExpires,
            shareModalOpen: true
          });
        })
        .catch(err => {
          this.setState({ shareLoading: false });
          alert('Die Rechnung kann aktuell nicht geteilt werden.');
        });
    });
  }

  handleRequestOk() {
    this.setState({ shareModalOpen: false });

    // WEB SHARE API
    if (navigator.share) {
      navigator
        .share({
          title: 'Neue Rechnung',
          text: `Ich habe das PDF zur neuen Rechnung hier für dich verlinkt. Der Link ist noch ${
            this.state.shareDaysTillExpires
          } Tage gültig`,
          url: this.state.shareUrl
        })
        .then(() => console.log('Successfully shared'))
        .catch(err => {
          console.log('Error sharing:' + err);
          prompt('Link', this.state.shareUrl);
        });
    } else if (navigator.clipboard) {
      navigator.clipboard
        .writeText(this.state.shareUrl)
        .then(() => {
          alert('In die Zwischenablage kopiert!');
        })
        .catch(err => {
          console.log('Error sharing:' + err);
          prompt('Link', this.state.shareUrl);
        });
    } else {
      prompt('Link', this.state.shareUrl);
    }
  }

  handleRequestClose() {
    this.setState({ shareModalOpen: false });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!nextProps.invoice) return true;
    if (nextProps.invoice.id !== this.props.invoice.id) return true;
    if (nextState.shareLoading !== this.state.shareLoading) return true;
    if (nextState.shareModalOpen !== this.state.shareModalOpen) return true;
    return false;
  }

  render() {
    const { classes } = this.props;
    let id = this.props.invoice.id;
    let data = this.props.invoice.data();
    let dueDate = data.invoiceDate
      ? data.invoiceDate.toDate().addDays(data.invoiceDueDays)
      : null;
    let remainingDueDays = new Date().diffDays(dueDate);

    let type =
      data.type === 'draft'
        ? 'Entwurf'
        : data.type === 'running'
        ? `#${data.invoiceId}`
        : `#${data.invoiceId}`;
    let icon =
      data.type === 'draft' ? (
        <DraftsIcon />
      ) : data.type === 'running' ? (
        <DoneIcon />
      ) : (
        <DoneAllIcon />
      );

    let cardActions;
    if (data.type === 'draft') {
      cardActions = (
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              gotoInvoice(this.props.history, id);
            }}
          >
            Anzeigen
          </Button>
          <Button onClick={this.props.onCreate} size="small" color="primary">
            Erstellen
          </Button>
        </CardActions>
      );
    } else if (data.type === 'running') {
      cardActions = (
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              gotoInvoice(this.props.history, id);
            }}
          >
            Anzeigen
          </Button>
          <Button size="small" color="primary" onClick={this.props.onMarkPayed}>
            Bezahlt Markieren
          </Button>
        </CardActions>
      );
    }

    let actionButton;
    if (data.type === 'draft') {
      actionButton = (
        <IconButton
          onClick={this.close.bind(this)}
          className={classes.closeBtn}
          color="secondary"
          aria-label="Menu"
        >
          <CloseIcon />
        </IconButton>
      );
    } else if (data.type === 'running') {
      actionButton = (
        <IconButton
          disabled={this.state.shareLoading}
          onClick={this.share.bind(this)}
          className={classes.closeBtn}
          color="secondary"
          aria-label="Menu"
        >
          {this.state.shareLoading ? (
            <CircularProgress size={24} className="button-preloader gray" />
          ) : (
            <ShareIcon />
          )}
        </IconButton>
      );
    } else if (data.type === 'finished') {
      actionButton = (
        <IconButton
          onClick={() => {
            gotoInvoice(this.props.history, id);
          }}
          className={classes.closeBtn}
          color="secondary"
          aria-label="Menu"
        >
          <RemoveRedEyeIcon />
        </IconButton>
      );
    }

    let dueInfo;
    if (data.type === 'running') {
      // DueColor
      let dueColor;
      let warnLevel = getDueWarnLevel(remainingDueDays);
      switch (warnLevel) {
        case 2:
          dueColor = classes.dueInfoDanger;
          break;

        case 1:
          dueColor = classes.dueInfoWarning;
          break;

        case 0:
        default:
          dueColor = classes.dueInfoSuccess;
      }

      // DueInfo Element
      dueInfo = (
        <div className={classes.dueInfo + ' ' + dueColor}>
          Fällig{' '}
          <Moment interval={60000} fromNow locale="de">
            {dueDate}
          </Moment>
        </div>
      );
    }

    return (
      <div>
        <Card className={'simple-card rel ' + classes.card}>
          {actionButton}
          <CardHeader
            avatar={
              <Avatar aria-label="Recipe" className={classes.avatar}>
                {icon}
              </Avatar>
            }
            title={
              data.data.customer
                ? data.data.customer.firstname +
                  ' ' +
                  data.data.customer.lastname
                : 'Kein Kunde ausgewählt'
            }
            subheader={type}
          />
          {dueInfo}
          {cardActions}
          {this.props.progress ? <Preloader absolute /> : ''}
        </Card>

        <Modal
          onlyOk
          open={this.state.shareModalOpen}
          handleRequestClose={this.handleRequestClose.bind(this)}
          okText="Teilen"
          title="Rechnung Teilen"
          text={`Einen ${
            this.state.shareDaysTillExpires
          } Tage gültigen Link zur Rechnung teilen.`}
          handleRequestOk={this.handleRequestOk.bind(this)}
        />
      </div>
    );
  }
}

export default withStyles(styles)(InvoiceCard);
