import React, { Component } from 'react';
import Settings from '../../../services/Settings';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import MailOutline from '@material-ui/icons/MailOutline';

const styles = theme => ({
  card: {
    background: theme.palette.primary.dark,
    position: 'relative'
  },

  headline: {
    color: theme.palette.common.white
  },

  text: {
    color: theme.palette.grey['50']
  },

  icon: {
    width: 42,
    height: 42,
    padding: '0 20px 0 10px',
    float: 'left'
  }
});

class DynamicNumberCard extends Component {
  onChange = key => event => {
    this.props.onChange(key, event.target.value);
  };

  render() {
    const { classes } = this.props;

    if (!this.props.visible) {
      return null;
    }

    return (
      <Card className={'simple-card ' + classes.card}>
        <CardContent className={classes.text}>
          <MailOutline className={classes.icon} />
          Bitte bestätige deine neue E-Mail Addresse. Wir haben die bereits eine
          E-Mail gesendet.
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(DynamicNumberCard);
