import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AddressAutocomplete from '../../../../AddressAutocomplete';
import styles from '../../pageStyles';
import img1 from './where.jpg';

class Address extends Component {
  state = {
    value: ''
  };

  onChange = val => {
    this.setState({ value: val });
    this.props.onStatusChange(false);
  };

  onSelected = place => {
    let address = {};
    place.address_components.forEach(ac => {
      if (ac.types.indexOf('street_number') > -1) {
        address.streetnr = ac.long_name;
      }
      if (ac.types.indexOf('route') > -1) {
        address.street = ac.long_name;
      }
      if (ac.types.indexOf('postal_code') > -1) {
        address.zip = ac.long_name;
      }
      if (ac.types.indexOf('locality') > -1) {
        address.city = ac.long_name;
      }
      if (ac.types.indexOf('country') > -1) {
        address.country = ac.long_name;
      }
    });

    this.setState({
      value: `${address.street || ''} ${address.streetnr ||
        ''}, ${address.zip || ''} ${address.city || ''}`
    });

    if (!['streetnr', 'street', 'zip', 'city'].every(k => k in address)) {
      console.log('Missing Stuff');
    } else {
      this.props.onChange({
        'invoiceData.street': `${address.street} ${address.streetnr}`,
        'invoiceData.city': `${address.zip} ${address.city}`
      });
      this.props.onStatusChange(true);
    }
  };

  render() {
    let { classes } = this.props;
    return (
      <React.Fragment>
        <img src={img1} className={classes.image} />
        <h1 className={classes.h1}>Wo?</h1>
        <h2 className={classes.h2}>
          Jetzt brauchen wir die Addresse und Hausnummer nicht vergessen 😉
        </h2>
        <AddressAutocomplete
          onSelected={this.onSelected}
          onChange={this.onChange}
          value={this.state.value}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Address);
