import React, { Component } from 'react';
import firebase from '../../../services/firebase';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import InvoiceIdInput from '../../InvoiceIdInput';
import { DatePicker } from 'material-ui-pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';

export default class InvoiceInfoCard extends Component {
  state = {
    customDueDialogOpen: false,
    tempCustomDueDay: '',
    customDueDay: false
  };

  customDueDialogClose = () => {
    this.setState({
      customDueDialogOpen: false,
      tempCustomDueDay: ''
    });
  };

  customDueDialogSuccess = () => {
    this.setState(
      {
        customDueDay: this.state.tempCustomDueDay,
        customDueDialogOpen: false,
        tempCustomDueDay: ''
      },
      () => {
        this.triggerPaymenDueDayOnChange(this.state.customDueDay);
      }
    );
  };

  changeInvoiceDate = date => {
    if (this.props.onChange) {
      this.props.onChange('invoiceDate', date.toDate());
    }
  };

  changeInvoicePaymentDueDay = event => {
    if (event.target.value === 'custom') {
      return this.setState({ customDueDialogOpen: true });
    }

    this.triggerPaymenDueDayOnChange(event.target.value);
  };

  triggerPaymenDueDayOnChange = val => {
    if (this.props.onChange) {
      this.props.onChange('invoiceDueDays', val);
    }
  };

  changeTempDueDay = event => {
    this.setState({ tempCustomDueDay: parseInt(event.target.value) });
  };

  changeInvoiceId = event => {
    if (this.props.onChange) {
      this.props.onChange('invoiceId', event.target.value);
    }
  };

  render() {
    let content = (
      <React.Fragment>
        <InvoiceIdInput
          value={this.props.invoiceId}
          onChange={this.changeInvoiceId}
        />
        <br />
        <br />
        <div className="flex-2">
          <DatePicker
            leftArrowIcon={<KeyboardArrowLeft />}
            rightArrowIcon={<KeyboardArrowRight />}
            label="Rechnungsdatum"
            format="LL"
            value={this.props.invoiceDate}
            onChange={this.changeInvoiceDate}
          />
          <FormControl>
            <InputLabel htmlFor="dueDays">Fälligkeit</InputLabel>
            <Select
              value={this.props.invoiceDueDays}
              onChange={this.changeInvoicePaymentDueDay}
              input={<Input name="dueDays" id="dueDays" />}
            >
              <MenuItem value={10}>In 10 Tagen</MenuItem>
              <MenuItem value={12}>In 12 Tagen</MenuItem>
              <MenuItem value={14}>In 14 Tagen</MenuItem>
              <MenuItem value={30}>In 30 Tagen</MenuItem>
              {this.state.customDueDay ? (
                <MenuItem value={this.state.customDueDay}>
                  In {this.state.customDueDay} Tagen
                </MenuItem>
              ) : (
                ''
              )}
              <MenuItem value="custom">Anderes ...</MenuItem>
            </Select>
          </FormControl>
        </div>

        <Dialog
          open={this.state.customDueDialogOpen}
          onClose={this.customDueDialogClose}
        >
          <DialogTitle>Anderes Zahlungsziel</DialogTitle>
          <DialogContent>
            <TextField
              value={this.state.tempCustomDueDay}
              onChange={this.changeTempDueDay}
              autoFocus
              margin="dense"
              id="days"
              label="Tage"
              type="number"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.customDueDialogClose} color="primary">
              Abbrechen
            </Button>
            <Button onClick={this.customDueDialogSuccess} color="primary">
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );

    if (this.props.modal) {
      return <React.Fragment>{content}</React.Fragment>;
    }

    return (
      <Card className="simple-card">
        <CardContent>
          <Typography variant="h5" component="h2">
            Rechnung
          </Typography>
          {content}
        </CardContent>
      </Card>
    );
  }
}
