import React, { Component } from 'react';
import firebase from '../../services/firebase';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

export default class InvoiceIdInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceid: ''
    };
  }

  handleChange(event) {
    this.setState({ invoiceid: event.target.value });
  }

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  render() {
    return (
      <FormControl className="fullwidth">
        <InputLabel htmlFor="invoiceid">Rechnungsnummer</InputLabel>
        <Input
          id="invoiceid"
          type="text"
          value={this.props.value}
          onChange={this.props.onChange}
        />
      </FormControl>
    );
  }
}
