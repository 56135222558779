import React, { Component } from 'react';
import _ from 'lodash';
import firebase, { firebaseAuth } from '../../services/firebase';
import Settings from '../../services/Settings';

let providerGoogle = new firebase.auth.GoogleAuthProvider();
let providerFacebook = new firebase.auth.FacebookAuthProvider();
const emailAuthProviderId = 'password';

export default class UserBase extends Component {
  unsubApiTokenListener = null;
  autoUserUpdate = null;

  state = {
    google: false,
    facebook: false,
    api: false,
    apiLoading: true,
    apiDisableModalOpen: false,
    apiModalVisible: false
  };

  componentWillMount() {
    let { providerData } = this.props.user;
    this.setState({
      google: _.find(providerData, { providerId: providerGoogle.providerId })
        ? true
        : false,
      facebook: _.find(providerData, {
        providerId: providerFacebook.providerId
      })
        ? true
        : false
    });
    this.updateUser();
    this.listenForApiTokenChanges();
  }

  componentWillUnmount() {
    this.stopAutoUserRefresh();
    if (this.unsubApiTokenListener) {
      this.unsubApiTokenListener();
    }
  }

  componentDidUpdate() {
    if (!this.props.user.emailVerified && !this.autoUserUpdate) {
      this.startAutoUserRefresh();
    } else if (this.props.user.emailVerified && this.autoUserUpdate) {
      this.stopAutoUserRefresh();
    }
  }

  updateUser() {
    this.props.user.reload().then(() => {
      this.forceUpdate();
    });
  }

  startAutoUserRefresh() {
    this.autoUserUpdate = setInterval(() => {
      this.updateUser();
    }, 5000);
  }

  stopAutoUserRefresh() {
    clearTimeout(this.autoUserUpdate);
    this.autoUserUpdate = null;
  }

  goBack = () => {
    this.props.history.goBack();
  };

  listenForApiTokenChanges() {
    this.unsubApiTokenListener = Settings.getAPIToken(docSnapshot => {
      let { docs: apiToken } = docSnapshot;
      let api = apiToken.length > 0;
      console.log({ apiToken });
      this.setState({ apiToken, api, apiLoading: false });
    });
  }

  toggleAPI = () => {
    if (this.state.api) {
      this.openDisableAPIModal();
    } else {
      this.addAPIToken();
    }
  };

  addAPIToken = () => {
    this.setState({ apiLoading: true });
    Settings.addAPIToken();
  };

  deleteAPIToken = token => {
    if (token.length < 1) {
      return;
    }
    this.setState({ apiLoading: true });
    token.forEach(doc => {
      doc.ref.delete();
    });
  };

  confirmDisableAPIModal = () => {
    this.deleteAPIToken(this.state.apiToken);
    this.closeDisableAPIModal();
  };

  openDisableAPIModal = () => {
    this.setState({ apiDisableModalOpen: true });
  };

  closeDisableAPIModal = () => {
    this.setState({ apiDisableModalOpen: false });
  };

  openAPISettings = () => {
    this.setState({ apiModalVisible: true });
  };

  closeAPISettings = () => {
    this.setState({ apiModalVisible: false });
  };

  toggleGoogle = () => {
    let provider = providerGoogle;
    if (this.state.google) {
      // Unlink Google
      this.props.user.unlink(provider.providerId).then(() => {
        this.setState({ google: false });
      });
    } else {
      // Link Google
      this.props.user
        .linkWithPopup(provider)
        .then(res => {
          // Accounts successfully linked.
          this.setState({ google: true });
        })
        .catch(this.alertError);
    }
  };

  toggleFacebook = () => {
    let provider = providerFacebook;
    if (this.state.facebook) {
      // Unlink Facebook
      this.props.user.unlink(provider.providerId).then(() => {
        this.setState({ facebook: false });
      });
    } else {
      // Link Facebook
      this.props.user
        .linkWithPopup(provider)
        .then(res => {
          // Accounts successfully linked.
          this.setState({ facebook: true });
        })
        .catch(this.alertError);
    }
  };

  alertError(err) {
    if (err.code === 'auth/credential-already-in-use') {
      alert('Der Account ist bereits mit einem anderen Konto verknüpft.');
    }
  }
}
