import store from '../store';
import _ from 'lodash';
import async from 'async';
import { fetchInvoicesAuto } from '../actions/invoice';
import { fetchCustomersAuto } from '../actions/customer';
import { fetchStatsAuto } from '../actions/stats';
import { fetchAccountAuto } from '../actions/account';

export function getAllData() {
  return Promise.all([
    getAllInvoices(),
    getAllCustomers(),
    getStats(),
    getAccount()
  ]);
}

export function getStats() {
  return new Promise((resolve, reject) => {
    let stats = store.getState().stats.stats;
    if (store.getState().stats.initialLoadDone) {
      return resolve(stats);
    } else {
      store.dispatch(
        fetchStatsAuto((err, stats) => {
          if (err) return reject(err);
          return resolve(stats);
        })
      );
    }
  });
}

export function getAccount() {
  return new Promise((resolve, reject) => {
    let account = store.getState().account.account;
    if (store.getState().account.initialLoadDone) {
      return resolve(account);
    } else {
      store.dispatch(
        fetchAccountAuto((err, account) => {
          if (err) return reject(err);
          return resolve(account);
        })
      );
    }
  });
}

export function getAllInvoices() {
  return new Promise((resolve, reject) => {
    let invoices = store.getState().invoice.invoices;
    if (store.getState().invoice.initialLoadDone) {
      return resolve(invoices);
    } else {
      store.dispatch(
        fetchInvoicesAuto((err, snapshots) => {
          if (err) return reject(err);
          return resolve(snapshots);
        })
      );
    }
  });
}

export function getAllCustomers() {
  return new Promise((resolve, reject) => {
    let customers = store.getState().customer.customers;
    if (store.getState().customer.initialLoadDone) {
      return resolve(customers);
    } else {
      store.dispatch(
        fetchCustomersAuto((err, snapshots) => {
          if (err) return reject(err);
          return resolve(snapshots);
        })
      );
    }
  });
}
