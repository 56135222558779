import React, { Component } from 'react';
import Firestore from '../../services/Firestore';
import { gotoInvoices } from '../../helper/transition';
import download from '../../helper/download';
import request from '../../helper/request';

const maxRetriesOnTimeout = 3;

export default class InvoiceAddBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timespan: { from: null, to: null },
      error: null,
      success: false
    };

    this.__ERRORS__ = {
      no_matching_records:
        'Keine Rechnungen entsprechen den eingestellten Filtern.'
    };
  }

  async exportAndClose(tryCount = 1) {
    this.setState({ inProgress: true, error: null, success: false });
    let client = await request.getClient();
    const { from, to } = this.state.timespan;
    client.__cloudfunctions
      .exportInvoices(from, to)
      .then(res => {
        console.log(res);
        this.waitForValidContainer(res.data.export);
      })
      .catch(err => {
        // On Error
        if (err && err.response.status === 400) {
          return this.setError(err.response.data.error);
        }

        // On Timeout
        if (tryCount < maxRetriesOnTimeout) {
          this.exportAndClose(tryCount + 1);
        } else {
          this.setState({ inProgress: false, error: 'Timeout!' });
        }
      });
  }

  setError = (errorId = '', exportId = null) => {
    let error = this.__ERRORS__[errorId]
      ? this.__ERRORS__[errorId]
      : `Da ist etwas schief gelaufen. Wende dich bitte an den Support.`;

    if (exportId) {
      error += ` [id=${exportId}]`;
    }

    this.setState({
      inProgress: false,
      error
    });
  };

  resetError = () => {
    this.setState({
      error: null
    });
  };

  waitForValidContainer(exportId) {
    let doc = Firestore.db()
      .collection('exports')
      .doc(exportId);
    this.unsubscribe = doc.onSnapshot(this.onExportSnap);
    Firestore.db()
      .collection('exports')
      .doc(exportId)
      .get()
      .then(this.onExportSnap);
  }

  onExportSnap = snap => {
    let data = snap.data();
    if (data.status === 'done') {
      this.unsubscribe();
      this.setState({ inProgress: false, success: true });
      download(data.file.url, 'invoice-export.zip');
      setTimeout(() => gotoInvoices(this.props.history), 2000);
    }
    if (data.status === 'error') {
      this.unsubscribe();
      this.setError('', snap.ref.id);
    }
  };

  goBack() {
    this.props.history.goBack();
  }

  timespanChanged(timespan) {
    this.setState({ timespan });
  }
}

// export function mapStateToProps(state, ownProps) {
//   return {
//     initialLoadDone: state.customer.initialLoadDone,
//     customers: state.customer.customers
//   };
// }

// export function mapDispatchToProps(dispatch) {
//   return { dispatch };
// }
