import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { desktopWrapper } from '../../helper/styles';
import ContentCard from '../../components/Desktop/ContentCard';
import General from './general';
import Accounts from './accounts';
import Invoice from './invoice';
import Mail from './mail';

const styles = theme => ({
  desktopWrapper,
  content: {
    padding: 0
  },
  wrap: {
    position: 'relative',
    paddingTop: 50,
    height: '100%',
    boxSizing: 'border-box'
  },
  actionBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: 10,
    background: theme.palette.secondary.dark
  },
  noChange: {
    display: 'block',
    color: theme.palette.secondary.main,
    height: 36,
    padding: '9px 0 0 10px',
    boxSizing: 'border-box'
  }
});

class Settings extends Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    let { value } = this.state;
    let { classes } = this.props;

    return (
      <div className={classes.desktopWrapper}>
        <Tabs
          value={value}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Allgemein" />
          <Tab label="Verlinkte Accounts" />
          <Tab label="Rechnungen" />
          <Tab label="Emails" />
        </Tabs>

        <ContentCard
          visible={value === 0}
          className={classes.content}
          content={<General {...this.props} />}
        />

        <ContentCard
          visible={value === 1}
          className={classes.content}
          content={<Accounts {...this.props} />}
        />

        <ContentCard
          visible={value === 2}
          className={classes.content}
          content={<Invoice {...this.props} />}
        />

        <ContentCard
          visible={value === 3}
          className={classes.content}
          content={<Mail {...this.props} />}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Settings);
