import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Preloader from '../../components/Preloader';
import CustomerInvoiceList from '../../components/CustomerInvoiceList';
import Overlay from '../../components/Overlay';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as LI from '../../components/ListItem';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomerDetailsBase, {
  mapStateToProps,
  mapDispatchToProps
} from '../../sites/Customer/edit';
import AddressList from '../../components/AddressList';
import { gotoCustomers, gotoCustomerEdit } from '../../helper/transition';
import ContentCard from '../../components/Desktop/ContentCard';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = theme => ({
  btn: {
    marginTop: '-12px',
    marginRight: '10px'
  },

  container: {
    display: 'flex'
  },

  pane: {
    width: '50%'
  },

  flex: {
    flex: 1
  }
});

class CustomerDetails extends CustomerDetailsBase {
  render() {
    let { classes } = this.props;

    // If loading
    if (!this.props.initialLoadDone) {
      return <Preloader />;
    }

    // Get Customer
    let customer = this.state.customer;

    // If not found
    if (!customer && (this.props.initialLoadDone || this.props.error)) {
      return (
        <Redirect
          to={{ pathname: '/404', state: { from: this.props.location } }}
        />
      );
    } else if (!customer || this.state.deleting) {
      return <Preloader />;
    }
    let data = customer.data();

    return (
      <ContentCard
        topBarContent={
          <React.Fragment>
            <IconButton
              className={classes.btn}
              color="primary"
              onClick={() => gotoCustomers(this.props.history)}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="secondary">
              {data.title + ' ' + data.firstname + ' ' + data.lastname}
            </Typography>
            <span className={classes.flex} />
            <Button
              color="primary"
              onClick={() => gotoCustomerEdit(this.props.history, customer.id)}
            >
              Editieren
            </Button>
          </React.Fragment>
        }
        content={
          <div className={classes.container}>
            <div className={classes.pane}>
              <List
                subheader={
                  <ListSubheader disableSticky>
                    Kundeninformationen
                  </ListSubheader>
                }
              >
                <LI.KundennummerListItem data={data.cid} noBtn />
                <LI.CompanyListItem data={data.company} noBtn />
                <LI.EmailListItem data={data.email} noBtn />
                <LI.PhoneListItem data={data.phone} noBtn />
                <LI.MobileListItem data={data.mobile} noBtn />
              </List>
              <CustomerInvoiceList
                history={this.props.history}
                customer={customer}
                title="Rechnungen"
              />
            </div>
            <div className={classes.pane}>
              <AddressList addresses={data.addresses} noBtn />
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomerDetails)
);
