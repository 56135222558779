import React, { Component } from 'react';
import Moment from 'react-moment';
import firebase from '../../services/firebase';
import { checkPasswordSafe } from '../../helper/calc';
import ReactGA from 'react-ga';
const VERIFICATIONMAIL_COOLDOWN = 300000;

export default class Login extends Component {
  status = {
    loggedInAsNotRegisteredUser: 'loggedInAsNotRegisteredUser',
    unverified: 'unverified',
    loading: 'loading',
    resetPassword: 'reset-password',
    resetPasswordDone: 'reset-password-done',
    overview: 'overview',
    login: 'login',
    register: 'register'
  };

  state = {
    mode: null,
    loginError: null,
    emailDate: false,
    realAccountPassword: '',
    realAccountPasswordRepeat: '',
    reg_email: '',
    reg_password: '',
    reg_passwordRepeat: '',
    realAccountLoading: false,
    realAccountError: null,
    loggingOutAndDeleteing: false,
    loginInProgress: false,
    registerInProgress: false,
    resetEmail: '',
    resetError: '',
    resetPasswordInProgress: false,
    loading: false,
    registerError: {
      type: null,
      text: null
    }
  };

  getCurrentStatus(d = null) {
    if (this.state.loading) {
      return this.status.loading;
    }
    if (this.props.user && !this.props.hasRealUserAccount) {
      return this.status.loggedInAsNotRegisteredUser;
    }
    if (this.props.hasUnverifiedAuth) {
      return this.status.unverified;
    }
    return this.state.mode ? this.state.mode : d ? d : this.status.login;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({ loading: false });
    }
  }

  componentWillMount() {
    this.setState({
      emailDate: this.getVerificationMailCooldownTS()
    });
  }

  componentDidMount() {
    if (this.props.hasUnverifiedAuth) {
      this.sendMailIfNotDoneAlready();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.hasUnverifiedAuth && this.props.hasUnverifiedAuth) {
      this.sendMailIfNotDoneAlready();
    }
  }

  changeEmail(event) {
    this.setState({
      email: event.target.value
    });
  }

  changePassword(event) {
    this.setState({
      password: event.target.value
    });
  }

  changeReg = item => event => {
    this.setState({
      ['reg_' + item]: event.target.value
    });
  };

  setMode = mode => () => {
    this.setState({ mode });
  };

  // todo: login by mail
  loginByMail() {
    this.setState({ loginInProgress: true });
    let { email, password } = this.state;
    if (!email || !password) {
      ReactGA.event({
        category: 'User',
        action: 'Failed to sign in by Mail'
      });
      return this.setState({
        loginInProgress: false,
        loginError: 'Falscher Benutzername oder Passwort'
      });
    }
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        ReactGA.event({
          category: 'User',
          action: 'Signed in by Mail'
        });
      })
      .catch(error => {
        ReactGA.event({
          category: 'User',
          action: 'Failed to sign in by Mail'
        });
        this.setState({
          loginInProgress: false,
          loginError: 'Falscher Benutzername oder Passwort'
        });
      });
  }

  // todo: registration
  register = () => {
    this.setState({ registerInProgress: true });
    let { reg_email, reg_password, reg_passwordRepeat } = this.state;

    // First reset error
    this.registerError(null, null);

    // Check if Password repeated right
    if (reg_password !== reg_passwordRepeat) {
      this.setState({ registerInProgress: false });
      ReactGA.event({
        category: 'User',
        action: 'Failed to register',
        error: 'Password is not repeated right'
      });
      return this.registerError(
        'passwordRepeat',
        'Passwort wiederholung ist nicht korrekt'
      );
    }

    // Check if password is safe
    if (!checkPasswordSafe(reg_password)) {
      this.setState({ registerInProgress: false });
      ReactGA.event({
        category: 'User',
        action: 'Failed to register',
        error: 'Password does not match critiria'
      });
      return this.registerError(
        'password',
        'Passwort muss min. 6 Zeichen lang sein'
      );
    }

    firebase
      .auth()
      .createUserWithEmailAndPassword(reg_email, reg_password)
      .then(() => {
        ReactGA.event({
          category: 'User',
          action: 'Registered with Email'
        });
      })
      .catch(error => {
        this.setState({ registerInProgress: false });
        // var errorCode = error.code;
        // var errorMessage = error.message;
        ReactGA.event({
          category: 'User',
          action: 'Failed to register',
          error: error.message
        });
        this.registerError('email', 'Fehler bei der Registrierung');
      });
  };

  sendMailIfNotDoneAlready = () => {
    var user = this.props.user;
    if (!localStorage.getItem(user.uid + '_vMail')) {
      this.sendVerificationMail();
    }
  };

  getVerificationMailCooldownTS = () => {
    var user = this.props.user;
    if (!user) {
      return false;
    }

    let sendMailTs = localStorage.getItem(user.uid + '_vMail');
    if (!sendMailTs) {
      return false;
    }

    sendMailTs = parseInt(sendMailTs) + VERIFICATIONMAIL_COOLDOWN;
    if (sendMailTs < Date.now()) {
      return false;
    }
    return new Date(sendMailTs);
  };

  emailCooldDownUpdated = () => {
    if (this.state.emailDate && this.state.emailDate < Date.now()) {
      this.setState({
        emailDate: false
      });
    }
  };

  sendVerificationMail = () => {
    var user = this.props.user;

    localStorage.setItem(user.uid + '_vMail', Date.now());
    this.setState({
      emailDate: this.getVerificationMailCooldownTS()
    });

    ReactGA.event({
      category: 'User',
      action: 'Send email verification'
    });

    return user.sendEmailVerification();
  };

  logout = () => {
    this.setState({
      loginInProgress: false,
      registerInProgress: false,
      resetEmail: '',
      resetError: '',
      reg_email: '',
      reg_password: '',
      reg_passwordRepeat: '',
      resetPasswordInProgress: false,
      loading: false
    });
    firebase.auth().signOut();
    ReactGA.event({
      category: 'User',
      action: 'Signed out'
    });
  };

  logoutAndDelete = () => {
    this.setState({ loggingOutAndDeleteing: true });
    try {
      this.props.user.delete().then(() => {
        this.setState({ loggingOutAndDeleteing: false });
        ReactGA.event({
          category: 'User',
          action: 'Deleted Account'
        });
      });
    } catch (e) {
      this.logout();
      this.setState({ loggingOutAndDeleteing: false });
    }
  };

  registerError(type, text) {
    return this.setState({
      registerError: {
        type,
        text
      }
    });
  }

  resetPassword = () => {
    this.setState({ resetPasswordInProgress: true, resetError: '' });
    firebase
      .auth()
      .sendPasswordResetEmail(this.state.resetEmail)
      .then(() => {
        this.setState({
          resetPasswordInProgress: false,
          mode: 'reset-password-done'
        });
        ReactGA.event({
          category: 'User',
          action: 'Resetted his Password'
        });
      })
      .catch(error => {
        this.setState({ resetPasswordInProgress: false, resetError: error });
      });
  };

  loginByGoogle() {
    window.Raven.captureBreadcrumb({
      message: 'Initiated login by Google',
      category: 'action'
    });

    // Start a sign in process for an unauthenticated user.
    this.setState({ loading: true });
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        ReactGA.event({
          category: 'User',
          action: 'Signed in by Google'
        });
        // this.setState({loading: false});
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  loginByFacebook() {
    window.Raven.captureBreadcrumb({
      message: 'Initiated login by Facebook',
      category: 'action'
    });

    // Start a sign in process for an unauthenticated user.
    this.setState({ loading: true });
    var provider = new firebase.auth.FacebookAuthProvider();
    // provider.addScope('user_birthday');
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        ReactGA.event({
          category: 'User',
          action: 'Signed in by Facebook'
        });
        // this.setState({loading: false});
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  changeResetMail(event) {
    this.setState({
      resetEmail: event.target.value
    });
  }

  changeRealAccountPassword(event) {
    this.setState({
      realAccountPassword: event.target.value
    });
  }

  changeRealAccountPasswordRepeat(event) {
    this.setState({
      realAccountPasswordRepeat: event.target.value
    });
  }

  createRealAccount() {
    if (
      this.state.realAccountPassword !== this.state.realAccountPasswordRepeat
    ) {
      ReactGA.event({
        category: 'User',
        action: 'Failed to register with social account',
        error: 'Password is not repeated right'
      });
      return this.setState({
        realAccountError: 'Passwort wiederholung ist nicht korrekt'
      });
    }

    if (!checkPasswordSafe(this.state.realAccountPassword)) {
      ReactGA.event({
        category: 'User',
        action: 'Failed to register with social account',
        error: 'Password does not match critiria'
      });
      return this.setState({
        realAccountError: 'Passwort muss min. 6 Zeichen lang sein'
      });
    }

    this.setState({ realAccountLoading: true, realAccountError: null });
    var credential = firebase.auth.EmailAuthProvider.credential(
      this.props.user.email,
      this.state.realAccountPassword
    );
    this.props.user.linkWithCredential(credential).then(
      user => {
        ReactGA.event({
          category: 'User',
          action: 'Registered with social account'
        });
        // this.setState({realAccountLoading:false});
        // Todo: login without reload maybe ???
        window.location.reload();
      },
      err => {
        ReactGA.event({
          category: 'User',
          action: 'Failed to register with social account',
          error: err.message
        });
        this.setState({
          realAccountLoading: false,
          realAccountError: 'Fehler bei der Registrierung'
        });
      }
    );
  }

  reload() {
    window.location.reload();
  }
}
