import React, { Component } from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  wrapper: {
    textAlign: 'center',
    padding: 20
  },

  currStep: {
    color: theme.palette.secondary.main
  }
});

class SteppedDialogContent extends Component {
  render() {
    let { classes, contentClassName } = this.props;
    return (
      <div className={classes.wrapper}>
        <span className={classes.currStep}>
          {this.props.index + 1} von {this.props.slides.length}
        </span>
        {this.props.head}
        <DialogContent className={contentClassName}>
          {this.props.slides[this.props.index]}
        </DialogContent>
        <DialogActions>{this.props.actions[this.props.index]}</DialogActions>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SteppedDialogContent);
