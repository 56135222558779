import React, { Component } from 'react';
import firebase from '../../../../services/firebase';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TodayIcon from '@material-ui/icons/Today';
import PaymentIcon from '@material-ui/icons/Payment';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { gotoInvoice } from '../../../../helper/transition';
import Moment from 'react-moment';

const styles = theme => ({
  card: {
    position: 'relative'
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  dateText: {
    marginTop: 12,
    fontSize: 18,
    color: theme.palette.text.secondary
  }
});

class CustomerCard extends Component {
  state = {
    customer: null,
    loading: false
  };

  render() {
    const { classes, customer, loading } = this.props;
    let dueDate = new Date(this.props.date).addDays(this.props.dueDays);

    return (
      <Card className={'simple-card ' + classes.card}>
        <CardContent className="flex-2">
          <div className="just-flex">
            <Avatar className="right-margin">
              <TodayIcon />
            </Avatar>
            <div className={classes.dateText}>
              <Moment date={this.props.date} locale="de" format="DD.MM.YYYY" />
            </div>
          </div>
          <div className="just-flex">
            <Avatar className="right-margin">
              <PaymentIcon />
            </Avatar>
            <div className={classes.dateText}>
              <Moment interval={60000} fromNow locale="de">
                {dueDate}
              </Moment>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(CustomerCard);
