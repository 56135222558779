import React, { Component } from 'react';
import InvoiceInformationBase from '../../sites/Settings/invoice-information';
import Overlay from '../../components/Overlay';
import DeleteModal from '../../components/Modal/DeleteModal';
import _ from 'lodash';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import InvoiceInformationCards from '../../components/Cards/InvoiceInformationCards';
import Preloader from '../../components/Preloader';

export default class InvoiceInformation extends InvoiceInformationBase {
  render() {
    let {
      name,
      street,
      city,
      notice,
      institute,
      iban,
      bic,
      tel,
      fax,
      email,
      managingDirector,
      taxNumber,
      vatIndentNumber,
      taxOffice,
      saving,
      uploadingLogo,
      removingLogo,
      _logoUrl
    } = this.state;
    return (
      <Overlay title="Rechnungsangaben" onBack={this.goBack} realAppBar>
        <InvoiceInformationCards
          name={name}
          street={street}
          city={city}
          notice={notice}
          institute={institute}
          iban={iban}
          bic={bic}
          tel={tel}
          fax={fax}
          email={email}
          managingDirector={managingDirector}
          taxNumber={taxNumber}
          vatIndentNumber={vatIndentNumber}
          taxOffice={taxOffice}
          uploadingLogo={uploadingLogo}
          removingLogo={removingLogo}
          logoUrl={_logoUrl}
          onRemoveLogo={() => {
            this.setState({ removeLogoDialogOpen: true });
          }}
          onChange={this.onChange}
        />

        <Fab
          onClick={this.save}
          className={'default-fab animated ' + this.state.animation}
          color="primary"
          aria-label="save"
        >
          <SaveIcon />
        </Fab>
        {saving ? <Preloader /> : null}

        <DeleteModal
          open={this.state.removeLogoDialogOpen}
          ok={this.removeLogo}
          onClose={() => {
            this.setState({ removeLogoDialogOpen: false });
          }}
        />
      </Overlay>
    );
  }
}
