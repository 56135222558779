import React, { Component } from 'react';
import firebase from '../../../../services/firebase';
import { convertToCash } from '../../../../helper/calc';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  card: {
    backgroundColor: '#a0d2b0',
    position: 'relative'
  },

  modal: {
    boxShadow: 'none'
  },

  amount: {
    color: 'white',
    fontSize: 28,
    padding: '30px 0',
    textAlign: 'center'
  }
});

class PayAmountCard extends Component {
  handleChange = event => {
    this.props.onChange(event.target.value);
  };

  render() {
    const { classes } = this.props;

    return (
      <Card
        className={
          classes.card +
          (this.props.modal ? ' ' + classes.modal : ' simple-card')
        }
      >
        <CardContent className={classes.amount}>
          {convertToCash(this.props.amount)} €
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(PayAmountCard);
