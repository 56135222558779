import vcard from 'vcard-generator';

export function generateCustomerVCard(payload) {
  let addresses = payload.addresses.map(address => {
    return {
      type: address.description,
      street: address.street + ' ' + address.streetnr,
      locality: address.city,
      code: address.zip,
      country: address.country
    };
  });

  return vcard.generate({
    name: {
      familyName: payload.lastname,
      givenName: payload.firstname,
      middleName: '',
      prefix: payload.title,
      suffix: ''
    },

    works: [
      {
        organization: payload.company,
        title: '',
        role: ''
      }
    ],
    emails: [
      {
        type: '',
        text: payload.email
      }
    ],
    phones: [
      {
        type: '',
        text: payload.phone
      },
      {
        type: 'mobile',
        text: payload.mobile
      }
    ],
    addresses
  });
}
