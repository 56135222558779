import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ShortTextIcon from '@material-ui/icons/ShortText';
import Typography from '@material-ui/core/Typography';

class TextSelectDialog extends Component {
  handleClose = () => {
    this.props.onClose(null);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  render() {
    const { classes, onClose, selectedValue, ...other } = this.props;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        {...other}
      >
        <DialogTitle id="simple-dialog-title">Textbausteine</DialogTitle>
        <div>
          <List>
            {this.props.items.map(item => (
              <ListItem
                button
                onClick={() => this.handleListItemClick(item)}
                key={item}
              >
                <ListItemAvatar>
                  <Avatar>
                    <ShortTextIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item} />
              </ListItem>
            ))}
            <ListItem button onClick={() => this.handleListItemClick(null)}>
              <ListItemAvatar>
                <Avatar>
                  <CloseIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Keinen verwenden" />
            </ListItem>
          </List>
        </div>
      </Dialog>
    );
  }
}

export default TextSelectDialog;
