import React, { Component } from 'react';
import Modal from '../';

export default class KontistDisconnectModal extends Component {
  ok() {
    this.props.ok();
  }

  cancel() {
    this.props.cancel();
  }

  render() {
    return (
      <Modal
        title="Kontist entfernen"
        text="Möchtest du wirklich die Verknüfung zwischen MyVoiz und deinem Kontist-Konto aufheben?"
        okText="Verknüpfung aufheben"
        cancelText="Abbrechen"
        open={this.props.open}
        handleRequestOk={this.ok.bind(this)}
        handleRequestClose={this.cancel.bind(this)}
      />
    );
  }
}
