import React, { Component } from 'react';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';

export default class MailInformationCards extends Component {
  handleChange = name => event => {
    this.props.onChange(name, event.target.value);
  };

  render() {
    let { noCard } = this.props;

    return (
      <div>
        <Card className={'simple-card' + (noCard ? ' shadowless' : '')}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Absender
            </Typography>

            <TextField
              className="fullwidth"
              label="Name"
              value={this.props.senderName}
              onChange={this.handleChange('senderName')}
              margin="normal"
            />

            <TextField
              className="fullwidth"
              label="EMail"
              value={this.props.senderMail}
              onChange={this.handleChange('senderMail')}
              margin="normal"
            />
          </CardContent>
        </Card>

        <Card className={'simple-card' + (noCard ? ' shadowless' : '')}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Nachricht
            </Typography>

            <TextField
              className="fullwidth"
              label="Betreff"
              value={this.props.subject}
              onChange={this.handleChange('subject')}
              margin="normal"
            />

            <TextField
              multiline
              rows="2"
              rowsMax="4"
              className="fullwidth"
              label="Nachricht"
              value={this.props.text}
              onChange={this.handleChange('text')}
              margin="normal"
            />
          </CardContent>
        </Card>
      </div>
    );
  }
}
