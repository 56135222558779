import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

class DynamicNumber extends Component {
  onChange = key => event => {
    this.props.onChange(key, event.target.value);
  };

  render() {
    return (
      <div className="flex-3">
        <TextField
          placeholder="Prefix"
          value={this.props.prefix}
          onChange={this.onChange('prefix')}
        />
        <TextField
          placeholder="Nummer"
          type="number"
          value={this.props.number}
          onChange={this.onChange('number')}
        />
        <TextField
          placeholder="Suffix"
          value={this.props.suffix}
          onChange={this.onChange('suffix')}
        />
      </div>
    );
  }
}

export default DynamicNumber;
