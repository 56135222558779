import React, { Component } from 'react';
import firebase from '../../../services/firebase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import SettingsIcon from '@material-ui/icons/Settings';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import './styles.css';

export default class UserMenuList extends Component {
  transition(path) {
    this.props.history.push(path);
  }

  logout() {
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
        window.location.reload(); // otherwise firestore error cuz of persistance
      })
      .catch(function(error) {
        // An error happened.
      });
  }

  render() {
    const gotoSub = () => this.transition('/settings/subscription');
    const gotoUser = () => this.transition('/settings/user');
    const logout = () => this.logout();

    return (
      <div className="MenuList">
        <List>
          <ListItem button onClick={gotoSub}>
            <ListItemIcon>
              <AllInclusiveIcon />
            </ListItemIcon>
            <ListItemText primary="Abonnement" />
          </ListItem>
          <ListItem button onClick={gotoUser}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Konto-Einstellungen" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={logout}>
            <ListItemIcon>
              <KeyboardArrowLeftIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </div>
    );
  }
}
