import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import './styles.css';

export default class InvoiceImageOverlay extends Component {
  render() {
    if (!this.props.visible) return null;

    let backIcon = this.props.backIcon || <CloseIcon />;
    let backgroundStyle = {},
      headBarStyle = {};

    backgroundStyle.backgroundColor = 'white';
    headBarStyle.backgroundColor = 'transparent';

    let imageComponent = (
      <div
        style={{
          textAlign: 'center',
          display: 'table',
          width: '100%',
          height: '100%'
        }}
      >
        <div
          style={{
            verticalAlign: 'middle',
            display: 'table-cell',
            padding: '10px'
          }}
        >
          {this.props.image}
        </div>
      </div>
    );

    return (
      <div className="invoice-overlay">
        <div style={backgroundStyle} className="image">
          {imageComponent}
        </div>
        <div style={headBarStyle} className="app-bar-iov">
          <div className="left">
            <IconButton
              onClick={this.props.onBack || (() => {})}
              color="secondary"
            >
              {backIcon}
            </IconButton>
          </div>
          <div className="right">
            <IconButton
              disabled={
                this.props.actionsDisabled ||
                this.props.backupRunning ||
                this.props.backupSuccessful
              }
              onClick={this.props.onBackup || (() => {})}
              color="secondary"
            >
              {this.props.backupRunning ? (
                <CircularProgress color="secondary" size={24} />
              ) : this.props.backupSuccessful ? (
                <CheckCircleIcon />
              ) : (
                <CloudUploadIcon />
              )}
            </IconButton>
            <IconButton
              disabled={this.props.actionsDisabled}
              onClick={this.props.onDownload || (() => {})}
              color="secondary"
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              disabled={this.props.actionsDisabled}
              onClick={this.props.onPrint || (() => {})}
              color="secondary"
            >
              <PrintIcon />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}
