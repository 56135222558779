import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import './styles.css';

export default class SubscriptionCard extends Component {
  render() {
    let { props } = this;
    let { type } = props;

    return (
      <Card className="simple-card subscription-card">
        <CardContent>
          <Typography variant="h5" component="h2">
            Dein Abonnement
          </Typography>

          <div className="type">{type}</div>
        </CardContent>
      </Card>
    );
  }
}
