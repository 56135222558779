import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default class Modal extends Component {
  render() {
    let onlyOk = this.props.onlyOk ? true : false;

    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick={this.props.disableBackdropClick}
        disableEscapeKeyDown={onlyOk}
        onClose={this.props.handleRequestClose}
      >
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.props.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {onlyOk ? null : (
            <Button onClick={this.props.handleRequestClose} color="primary">
              {this.props.cancelText}
            </Button>
          )}
          <Button onClick={this.props.handleRequestOk} color="primary">
            {this.props.okText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
