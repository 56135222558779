import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import logo from '../../img/logo.svg';

export default function resetPassword(classes) {
  return (
    <div className={classes.box}>
      <img className={classes.logo} src={logo} />
      <h1 className={classes.headline}>MyVoiz</h1>

      <TextField
        id="resetemail"
        value={this.state.resetEmail || ''}
        className={classes.textfield}
        label="Email"
        onChange={this.changeResetMail.bind(this)}
        margin="normal"
        error={this.state.resetError ? true : false}
      />

      <br />
      <br />
      <Button
        disabled={this.state.resetPasswordInProgress}
        className={classes.loginButton}
        onClick={this.resetPassword}
      >
        {this.state.resetPasswordInProgress ? '...' : 'Password zurücksetzen'}
      </Button>
      <div className={classes.registeracccontainer}>
        <Button
          onClick={this.setMode(this.status.login)}
          disabled={this.state.resetPasswordInProgress}
          color="secondary"
          className={classes.forgotPasswd}
        >
          Doch wieder eingefallen? Hier zum Login.
        </Button>
      </div>
    </div>
  );
}
