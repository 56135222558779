import React, { Component } from 'react';
import Moment from 'react-moment';
import Button from '@material-ui/core/Button';
import logo from '../../img/logo.svg';

export default function unverified(classes) {
  return (
    <div className={classes.box}>
      <img className={classes.logo} src={logo} />
      <h1 className={classes.headline}>MyVoiz</h1>

      <div className="box">
        <h3>Verifiziere deine Email</h3>
        <p>
          Wir haben dir eine Email an {this.props.user.email} gesandt. Bitte
          klicke auf den Link in der Email um die Registrierung abzuschließen.
        </p>
        <br />
        <Button
          disabled={this.state.emailDate ? true : false}
          variant="contained"
          color="primary"
          onClick={this.sendVerificationMail}
        >
          Email nocheinmal senden&nbsp;
          {this.state.emailDate ? (
            <Moment
              interval={30000}
              onChange={this.emailCooldDownUpdated}
              fromNow
              locale="de"
            >
              {this.state.emailDate}
            </Moment>
          ) : null}
        </Button>
        <br />
        <br />
        <br />
        <Button variant="contained" color="primary" onClick={this.reload}>
          Aktualisieren
        </Button>
      </div>

      <div className={classes.registeracccontainer}>
        <Button
          onClick={this.logout}
          color="secondary"
          className={classes.forgotPasswd}
        >
          Logout
        </Button>
      </div>
    </div>
  );
}
