import React, { Component } from 'react';
import firebase from '../../../services/firebase';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Typography from '@material-ui/core/Typography';
import CustomerAutocomplete from '../../CustomerAutocomplete';
import CustomerAddressSelect from '../../CustomerAddressSelect';
import { createCustomerSearchString } from '../../../helper/search';

export default class SelectCustomerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCustomer: null,
      selectedAddress: ''
    };
  }

  onCustomerAddressSelected(event) {
    let addressKey = event.target.value;
    if (addressKey) {
      this.setState(
        {
          selectedAddress: addressKey
        },
        () => {
          this.triggerOnChange();
        }
      );
    } else {
      this.setState(
        {
          selectedAddress: ''
        },
        () => {
          this.triggerOnChange();
        }
      );
    }
  }

  onCustomerOnChange(text) {
    if (
      this.state.selectedCustomer &&
      text !== createCustomerSearchString(this.state.selectedCustomer)
    ) {
      this.onCustomerSelected(false);
    }
  }

  onCustomerSelected(customer) {
    if (customer) {
      this.setState(
        {
          selectedCustomer: customer,
          selectedAddress: customer.data().addresses.length > 0 ? '0' : '-1'
        },
        () => {
          this.triggerOnChange();
        }
      );
    } else {
      this.setState(
        {
          selectedCustomer: null,
          selectedAddress: ''
        },
        () => {
          this.triggerOnChange();
        }
      );
    }
  }

  triggerOnChange() {
    if (this.props.onChange) {
      this.props.onChange(
        this.state.selectedCustomer,
        this.state.selectedAddress
      );
    }
  }

  render() {
    let addresses = this.state.selectedCustomer
      ? this.state.selectedCustomer.data().addresses
      : [];

    return (
      <Card
        className={'simple-card' + (this.props.shadowless ? ' shadowless' : '')}
      >
        <CardContent>
          <Typography variant="h5" component="h2">
            Kunde
          </Typography>
          <br />
          <CustomerAutocomplete
            onChange={this.onCustomerOnChange.bind(this)}
            onSelected={this.onCustomerSelected.bind(this)}
            customers={this.props.customers || []}
          />
          <br />
          <CustomerAddressSelect
            disabled={this.state.selectedCustomer === null}
            addresses={addresses}
            value={this.state.selectedAddress}
            onChange={this.onCustomerAddressSelected.bind(this)}
          />
        </CardContent>
      </Card>
    );
  }
}
