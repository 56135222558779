import React, { Component } from 'react';
import _ from 'lodash';
import Moment from 'react-moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentIcon from '@material-ui/icons/Payment';
import CheckIcon from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import SteppedDialogContent from '../../SteppedDialogContent';
import { getItemsTotal, convertToCash } from '../../../helper/calc';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  success: {
    color: theme.palette.primary.main
  },

  iconWrap: {
    width: 188,
    margin: '0 auto',
    position: 'relative'
  },

  mobileLayoutContent: {
    padding: 0
  },

  paymentInfoWrap: {
    position: 'relative',
    boxSizing: 'border-box',
    width: '100%',
    textAlign: 'left',
    margin: '40px 0 0 0',
    color: 'white',
    background: theme.palette.primary.main,
    padding: '35px 20px 5px 20px',
    borderRadius: 5,
    '&.mobile p b': {
      marginLeft: 5
    },
    '& h3': {
      position: 'absolute',
      top: -20,
      left: 'calc(50% - 55px)',
      display: 'inline-block',
      margin: 0,
      background: 'white',
      color: theme.palette.primary.main,
      borderRadius: 30,
      padding: 10,
      width: 100,
      textAlign: 'center'
    },
    '& p': {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: 'dashed 2px #e9f5ff',
      marginBottom: 30
    },
    '& p b': {
      marginLeft: 35,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 210,
      display: 'block'
    }
  },

  headIcon: {
    width: 128,
    height: 128,
    color: 'white',
    borderRadius: '50%',
    background: theme.palette.secondary.main,
    padding: 30,
    margin: '20px 0',
    '&.mobile': {
      width: 64,
      height: 64
    }
  },

  checkIcon: {
    position: 'absolute',
    right: -20,
    padding: 10,
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: '50%',
    width: 64,
    height: 64,
    boxShadow: '#0000005e -2px 4px 7px',
    '&.mobile': {
      right: 20,
      top: 10,
      width: 32,
      height: 32
    }
  }
});

class BankSyncDialog extends Component {
  state = {
    currIndex: 0
  };

  onDiscard = () => {
    if (this.state.currIndex + 1 === this.payedInvoices().length) {
      this.onClose();
    } else {
      this.nextSlide();
    }
  };

  onClose = () => {
    this.props.onClose();
    setTimeout(() => {
      this.setState({
        currIndex: 0
      });
    }, 500);
  };

  onSubmit = transaction => () => {
    if (this.state.currIndex + 1 === this.payedInvoices().length) {
      this.props.onSubmit(transaction).then(() => {
        this.onClose();
      });
    } else {
      this.props.onSubmit(transaction).then(() => {
        this.nextSlide();
      });
    }
  };

  nextSlide() {
    this.setState({
      currIndex: ++this.state.currIndex
    });
  }

  payedInvoices() {
    return this.props.transactions
      ? this.props.transactions.filter(t => {
          return (
            t.transactions.length &&
            getItemsTotal(t.invoice.data().data.items) ===
              _.sumBy(t.transactions, 'amount') / 100
          );
        })
      : [];
  }

  render() {
    let { classes, mobileLayout } = this.props;
    let payedInvoices = this.payedInvoices();

    if (payedInvoices.length === 0) {
      return (
        <Dialog
          open={this.props.open}
          onClose={this.onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Keine Transaktionen gefunden
          </DialogTitle>
          <DialogContent>
            Es wurden keine passenden Transaktionen gefunden ...
          </DialogContent>
          <DialogActions>
            <Button
              disabled={this.props.loading}
              onClick={this.onClose}
              color="primary"
            >
              Schließen
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.onClose}
        aria-labelledby="form-dialog-title"
      >
        <SteppedDialogContent
          index={this.state.currIndex}
          contentClassName={mobileLayout ? classes.mobileLayoutContent : ''}
          head={
            <div className={classes.iconWrap}>
              <PaymentIcon
                className={classes.headIcon + (mobileLayout ? ' mobile' : '')}
              />
              <CheckIcon
                className={classes.checkIcon + (mobileLayout ? ' mobile' : '')}
              />
            </div>
          }
          slides={payedInvoices.map(t => (
            <div key={'s_' + t.invoice.id}>
              <b>Rechnung {t.invoice.data().invoiceId}</b>
              <br />
              <i className={classes.success}>
                Passende Transaktionen gefunden!
              </i>
              <div
                className={
                  classes.paymentInfoWrap + (mobileLayout ? ' mobile' : '')
                }
              >
                <h3>Zahlung</h3>
                <p>
                  Absender: <b>{t.transactions[0].name}</b>
                </p>
                <p>
                  Zweck: <b>{t.transactions[0].purpose}</b>
                </p>
                <p>
                  Datum:{' '}
                  <b>
                    <Moment format="DD.MM.YYYY" locale="de">
                      {t.transactions[0].booking_date}
                    </Moment>
                  </b>
                </p>
                <p>
                  Betrag:{' '}
                  <b>
                    {convertToCash(_.sumBy(t.transactions, 'amount') / 100)} €
                  </b>
                </p>
                <p>
                  Zahlungen: <b>{t.transactions.length}</b>
                </p>
              </div>
            </div>
          ))}
          actions={payedInvoices.map(t => (
            <div key={'a_' + t.invoice.id}>
              <Button
                disabled={this.props.loading}
                onClick={this.onDiscard}
                color="secondary"
              >
                Abbrechen
              </Button>
              <Button
                disabled={this.props.loading}
                onClick={this.onSubmit(t)}
                color="primary"
              >
                {this.props.loading ? (
                  <CircularProgress color="secondary" size={20} />
                ) : (
                  'Jetzt Abschließen'
                )}
              </Button>
            </div>
          ))}
        />
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(BankSyncDialog);
