export function filterIncomingTransactions(transactions) {
  return transactions.filter(t => t.to);
}

export function filterForInvoiceId(transactions, invoiceId) {
  return transactions.filter(t => invoiceIdCheck(t.purpose, invoiceId));
}

export function invoiceIdCheck(string, invoiceId) {
  return !!(string.indexOf(invoiceId) > -1);
}
