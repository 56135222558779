import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Preloader from '../Preloader';
import Overlay from '../Overlay';
import EmailCard from '../Cards/InvoiceStartCards/EmailCard';
import PostCard from '../Cards/InvoiceStartCards/PostCard';
import InvoiceInfoCard from '../Cards/InvoiceInfoCard';
import { nextInvoiceNumber } from '../../helper/number-gen';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InvoiceStartBase from './index';

const styles = theme => ({
  dialog: {},

  amountTypeButton: {
    textTransform: 'none'
  },

  amountTypeAdornment: {
    height: 32
  },

  deleteButton: {
    position: 'absolute',
    right: 10,
    top: 10
  }
});

class InvoiceStartModal extends InvoiceStartBase {
  render() {
    let { classes } = this.props;
    if (!this.props.invoice) return;

    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle>Rechnung starten</DialogTitle>

        <DialogContent className={classes.dialog}>
          <InvoiceInfoCard
            modal
            invoiceId={this.state.invoiceData.invoiceId}
            invoiceDueDays={this.state.invoiceData.invoiceDueDays}
            invoiceDate={this.state.invoiceData.invoiceDate}
            onChange={this.dataChanged.bind(this)}
          />
          <br />
          <EmailCard
            modal
            disabled={!this.mailsEnabled()}
            active={this.state.startData.sendMail}
            onChange={sendMail => {
              this.writeObject('sendMail', sendMail, 'startData');
            }}
          />
          <PostCard
            modal
            disabled={!this.postMailsEnabled()}
            active={this.state.startData.sendPostMail}
            onChange={sendPostMail => {
              this.writeObject('sendPostMail', sendPostMail, 'startData');
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button
            disabled={this.props.starting}
            onClick={() => this.props.onClose()}
            color="secondary"
          >
            Abbrechen
          </Button>
          <Button
            disabled={this.props.starting}
            onClick={() => this.props.onStart(this.state)}
            color="primary"
          >
            Starten
            {this.props.markingAsPayed ? (
              <CircularProgress size={24} className="button-preloader gray" />
            ) : (
              ''
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(InvoiceStartModal);
