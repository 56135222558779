import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DynamicNumber from '../../DynamicNumber';

const styles = theme => ({
  card: {
    position: 'relative'
  }
});

class DynamicNumberCard extends Component {
  onChange = key => event => {
    this.props.onChange(key, event.target.value);
  };

  render() {
    const { classes } = this.props;

    return this.props.noCard ? (
      <div className={this.props.className || ''}>
        <h3 className={this.props.h3className || ''}>{this.props.title}</h3>
        <DynamicNumber
          prefix={this.props.prefix}
          number={this.props.number}
          suffix={this.props.suffix}
          onChange={this.props.onChange}
        />
      </div>
    ) : (
      <Card
        className={
          'simple-card ' +
          classes.card +
          (this.props.className ? ' ' + this.props.className : '')
        }
      >
        <CardHeader title={this.props.title} />
        <CardContent>
          <DynamicNumber
            prefix={this.props.prefix}
            number={this.props.number}
            suffix={this.props.suffix}
            onChange={this.props.onChange}
          />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(DynamicNumberCard);
