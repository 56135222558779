import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.css';

export default class Preloader extends Component {
  render() {
    let size = 60;
    let classes = ['blur'];
    if (this.props.absolute) {
      classes.push('abs');
    }
    if (this.props.dark) {
      classes.push('dark');
    }
    if (this.props.transparentBackground) {
      classes.push('trans');
    }
    if (this.props.size) {
      size = this.props.size;
    }

    return (
      <div className={classes.join(' ')}>
        <CircularProgress color="secondary" className="spinner" size={size} />
      </div>
    );
  }
}
