import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styles from '../../pageStyles';
import img1 from './hey.jpg';

class CompanyName extends Component {
  onChange = e => {
    this.props.onStatusChange(e.target.value.length > 0);
    this.props.onChange({
      'invoiceData.name': e.target.value
    });
  };

  render() {
    let { classes } = this.props;
    return (
      <React.Fragment>
        <img src={img1} className={classes.image} />
        <h1 className={classes.h1}>Wie heisst dein Unternehmen?</h1>
        <h2 className={classes.h2}>
          Der Name kommt später auf die Rechnungen.
        </h2>
        <TextField
          autoFocus
          label={'Name des Unternehmen'}
          placeholder={'Einhornfabrik Wonka'}
          type="text"
          fullWidth
          onChange={this.onChange}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CompanyName);
