import React, { Component } from 'react';
import _ from 'lodash';
import async from 'async';
import Settings from '../../services/Settings';
import Firestore from '../../services/Firestore';

export default class GeneralBase extends Component {
  state = {
    initialOnline: null,
    initialOffline: null,

    saving: false,
    saveVisible: false,
    animation: 'hidden',

    newUnitDialogOpen: false,
    newUnitText: '',

    persistent: Firestore.currentlyPersistent,

    units: [],

    customer: {
      prefix: '',
      number: 1,
      suffix: ''
    },

    invoice: {
      prefix: '',
      number: 1,
      suffix: ''
    }
  };

  componentWillMount() {
    this.updateData();
  }

  newUnitOpen = () => this.setState({ newUnitDialogOpen: true });
  newUnitOnSubmit = () => {
    if (!this.state.newUnitText) {
      return;
    }
    this._onChange('units', [...this.state.units, this.state.newUnitText]);
    this.setState({ newUnitDialogOpen: false, newUnitText: '' });
  };
  newUnitOnCancel = () =>
    this.setState({ newUnitDialogOpen: false, newUnitText: '' });
  newUnitOnChange = newUnitText => this.setState({ newUnitText });

  save = () => {
    this.setState({ saving: true });

    let tasks = [];
    // Online Settings
    if (this.somethingOnlineChanged()) {
      tasks.push(callback => {
        Settings.update({ generalSettings: this.generateOnlineStateObject() })
          .then(() => {
            callback(null);
          })
          .catch(err => {
            callback(err);
          });
      });
    }

    // Offline Settings
    if (this.somethingOfflineChanged()) {
      tasks.push(callback => {
        // Change Persistent Mode
        if (this.state.persistent !== this.state.initialOffline.persistent) {
          Settings.setDeviceTrust(this.state.persistent)
            .then(() => {
              callback(null, { reload: true });
            })
            .catch(err => {
              callback(err);
            });
        }
      });
    }

    // Run them parallel
    async.parallel(tasks, (err, res) => {
      if (_.find(res, { reload: true })) {
        return window.location.reload(); // Pages need reload
      }
      this.setState({ saving: false });
      this.updateInitial();
      this.updateSaveButtonVisibility();
    });
  };

  updateData = (cb = () => {}) => {
    this.setState(
      {
        persistent: Firestore.currentlyPersistent,
        customer: Settings.get('generalSettings.customer', {
          prefix: '',
          number: 1,
          suffix: ''
        }),
        invoice: Settings.get('generalSettings.invoice', {
          prefix: '',
          number: 1,
          suffix: ''
        }),
        units: Settings.get('generalSettings.units', [
          'Stück',
          'Stunden',
          'Minuten'
        ])
      },
      () => {
        this.updateInitial(cb);
      }
    );
  };

  updateInitial = (cb = () => {}) => {
    this.setState(
      {
        initialOnline: this.generateOnlineStateObject(),
        initialOffline: this.generateOfflineStateObject()
      },
      cb
    );
  };

  discard = () => {
    this.updateData(() => {
      this.updateSaveButtonVisibility();
    });
  };

  updateSaveButtonVisibility = () => {
    let onlineChanged = this.somethingOnlineChanged();
    this.setState({
      saveVisible: onlineChanged,
      animation:
        onlineChanged || this.somethingOfflineChanged() ? 'rollIn' : 'rollOut'
    });
  };

  generateOnlineStateObject = () => {
    let { customer, invoice, units } = this.state;
    return {
      customer: Object.assign({}, customer),
      invoice: Object.assign({}, invoice),
      units
    };
  };

  deleteChip = index => () => {
    let units = this.state.units;
    units.splice(index, 1);
    this._onChange('units', units);
  };

  generateOfflineStateObject = () => {
    let { persistent } = this.state;
    return { persistent };
  };

  somethingOnlineChanged = () => {
    return (
      JSON.stringify(this.state.initialOnline) !==
      JSON.stringify(this.generateOnlineStateObject())
    );
  };

  somethingOfflineChanged = () => {
    console.log(this.state.initialOffline, this.generateOfflineStateObject());
    return !_.isEqual(
      this.state.initialOffline,
      this.generateOfflineStateObject()
    );
  };

  goBack = () => {
    this.props.history.goBack();
  };

  onChange = type => (key, val) => {
    this.setState(
      {
        [type]: Object.assign(this.state[type], { [key]: val })
      },
      () => {
        this.updateSaveButtonVisibility();
      }
    );
  };

  _onChange = (key, val) => {
    this.setState(
      {
        [key]: val
      },
      () => {
        this.updateSaveButtonVisibility();
      }
    );
  };

  changePersistent = event => {
    this._onChange('persistent', event.target.checked);
  };
}
