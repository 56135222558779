import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';
import Preloader from '../Preloader';
import Overlay from '../Overlay';
import EmailCard from '../Cards/InvoiceStartCards/EmailCard';
import PostCard from '../Cards/InvoiceStartCards/PostCard';
import InvoiceInfoCard from '../Cards/InvoiceInfoCard';
import { nextInvoiceNumber } from '../../helper/number-gen';
import InvoiceStartBase from './index';

export default class InvoiceStartOverlay extends InvoiceStartBase {
  render() {
    if (!this.props.visible) return null;
    if (!this.props.invoice) return <Preloader />;

    return (
      <Overlay
        realAppBar
        className="animated zoomIn"
        title="Rechnung Erstellen"
        backIcon={<CloseIcon />}
        rightButtonIcon={
          this.props.starting ? (
            <CircularProgress size={24} className="button-preloader" />
          ) : (
            <SendIcon />
          )
        }
        rightButtonDisabled={this.props.starting ? true : false}
        onBack={this.props.onBack || (() => {})}
        rightButtonEvent={
          (() => {
            this.props.onStart(this.state);
          }) || (() => {})
        }
      >
        <InvoiceInfoCard
          invoiceId={this.state.invoiceData.invoiceId}
          invoiceDueDays={this.state.invoiceData.invoiceDueDays}
          invoiceDate={this.state.invoiceData.invoiceDate}
          onChange={this.dataChanged.bind(this)}
        />
        <EmailCard
          disabled={!this.mailsEnabled()}
          active={this.state.startData.sendMail}
          onChange={sendMail => {
            this.writeObject('sendMail', sendMail, 'startData');
          }}
        />
        <PostCard
          disabled={!this.postMailsEnabled()}
          active={this.state.startData.sendPostMail}
          onChange={sendPostMail => {
            this.writeObject('sendPostMail', sendPostMail, 'startData');
          }}
        />
      </Overlay>
    );
  }
}
