import React, { Component } from 'react';
import firebase from '../../../../services/firebase';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { gotoInvoice } from '../../../../helper/transition';
import { CustomerModel, AddressModel } from '../../../../helper/models';
import Preloader from '../../../Preloader';
import AddressList from '../../../AddressList';
import CustomerAddressSelect from '../../../CustomerAddressSelect';

const styles = theme => ({
  card: {
    position: 'relative'
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  cardContent: {
    paddingTop: 0,
    paddingBottom: 0
  }
});

class CustomerCard extends Component {
  state = {
    toggled: false,
    customer: null,
    loading: false
  };

  render() {
    const { classes, customer, loading } = this.props;
    let customerData = customer
      ? CustomerModel(customer.data())
      : CustomerModel();

    let addresses = [];

    if (!this.props.selectedAddress) {
      addresses.push(
        AddressModel({ street: '', description: 'Keine Addresse' })
      );
    } else {
      addresses.push(this.props.selectedAddress);
    }

    let content = (
      <div>
        <CardHeader
          avatar={
            <Avatar
              aria-label="Kunde"
              style={{ backgroundColor: customerData.color }}
            >
              {customerData.char}
            </Avatar>
          }
          action={
            <IconButton
              onClick={() => {
                this.setState({ toggled: !this.state.toggled });
              }}
            >
              {this.state.toggled ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          }
          title={customerData.firstname + ' ' + customerData.lastname}
          subheader={customerData.company}
        />
        {this.state.toggled ? (
          <CardContent className={classes.cardContent}>
            <AddressList hideLabel addresses={addresses} />
          </CardContent>
        ) : null}
      </div>
    );

    return (
      <Card className={'simple-card ' + classes.card}>
        {loading ? (
          <div style={{ height: 70, padding: 20 }}>
            <Preloader absolute />
          </div>
        ) : (
          content
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(CustomerCard);
