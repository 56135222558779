import React, { Component } from 'react';
import Storage from '../../services/Storage';
import Settings from '../../services/Settings';
import _ from 'lodash';

export default class InvoiceInformationBase extends Component {
  state = {
    saving: false,
    saveVisible: false,
    uploadingLogo: false,
    removingLogo: false,
    removeLogoDialogOpen: false,
    _logoUrl: '',
    initial: {},
    saving: false,
    animation: 'hidden',
    name: '',
    street: '',
    city: '',
    notice: '',
    institute: '',
    iban: '',
    bic: '',
    tel: '',
    fax: '',
    email: '',
    managingDirector: '',
    taxNumber: '',
    vatIndentNumber: '',
    taxOffice: ''
  };

  componentWillMount() {
    this.updateData();
  }

  goBack = () => {
    this.props.history.goBack();
  };

  onChange = (name, val, target) => {
    if (name === 'logo') {
      return this.uploadNewLogo(target.files[0]);
    }

    this.setState({ [name]: val }, () => {
      this.updateSaveButtonVisibility();
    });
  };

  removeLogo = () => {
    this.setState({ removeLogoDialogOpen: false, removingLogo: true });
    Storage.deleteFile('images/invoiceLogo.png').then(() => {
      let data = Settings.get('invoiceData');
      data.logoUrl = null;
      Settings.updateKey('invoiceData', data).then(() => {
        this.setState({ removingLogo: false, _logoUrl: null });
      });
    });
  };

  uploadNewLogo(file) {
    this.setState({ uploadingLogo: true });
    let uploadTask = Storage.uploadFile('images/invoiceLogo.png', file);
    uploadTask.then(snapshot => {
      let data = Settings.get('invoiceData');
      let url = snapshot.downloadURL;
      data.logoUrl = url;
      Settings.updateKey('invoiceData', data).then(() => {
        this.setState({ uploadingLogo: false, _logoUrl: url });
      });
    });
  }

  updateData = (cb = () => {}) => {
    this.setState(
      {
        _logoUrl: Settings.get('invoiceData.logoUrl', ''),
        name: Settings.get('invoiceData.name', ''),
        street: Settings.get('invoiceData.street', ''),
        city: Settings.get('invoiceData.city', ''),
        notice: Settings.get('invoiceData.notice', ''),
        institute: Settings.get('invoiceData.institute', ''),
        iban: Settings.get('invoiceData.iban', ''),
        bic: Settings.get('invoiceData.bic', ''),
        tel: Settings.get('invoiceData.tel', ''),
        fax: Settings.get('invoiceData.fax', ''),
        email: Settings.get('invoiceData.email', ''),
        managingDirector: Settings.get('invoiceData.managingDirector', ''),
        taxNumber: Settings.get('invoiceData.taxNumber', ''),
        vatIndentNumber: Settings.get('invoiceData.vatIndentNumber', ''),
        taxOffice: Settings.get('invoiceData.taxOffice', '')
      },
      () => {
        this.updateInitial(cb);
      }
    );
  };

  discard = () => {
    this.updateData(() => {
      this.updateSaveButtonVisibility();
    });
  };

  save = () => {
    this.setState({ saving: true });
    let saveObject = this.generateStateObject();
    saveObject.logoUrl = this.state._logoUrl;
    Settings.update({ invoiceData: saveObject }).then(() => {
      this.setState({ saving: false });
      this.updateInitial();
      this.updateSaveButtonVisibility();
    });
  };

  generateStateObject = () => {
    let {
      name,
      street,
      city,
      notice,
      institute,
      iban,
      bic,
      tel,
      fax,
      email,
      managingDirector,
      taxNumber,
      vatIndentNumber,
      taxOffice
    } = this.state;
    return {
      name,
      street,
      city,
      notice,
      institute,
      iban,
      bic,
      tel,
      fax,
      email,
      managingDirector,
      taxNumber,
      vatIndentNumber,
      taxOffice
    };
  };

  updateInitial = (cb = () => {}) => {
    this.setState(
      {
        initial: this.generateStateObject()
      },
      cb
    );
  };

  updateSaveButtonVisibility = () => {
    let onlineChanged = this.somethingChanged();
    this.setState({
      saveVisible: onlineChanged,
      animation: onlineChanged ? 'rollIn' : 'rollOut'
    });
  };

  somethingChanged = () => {
    return !_.isEqual(this.state.initial, this.generateStateObject());
  };
}
