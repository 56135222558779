import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReactPullToRefresh from 'react-pull-to-refresh';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RefreshIcon from '@material-ui/icons/Refresh';
import './style.css';

const styles = theme => ({
  refreshBubble: {
    width: 32,
    height: 32,
    padding: 10,
    borderRadius: '50%',
    background: 'white',
    margin: '-80px calc(50% - 16px) 20px calc(50% - 16px)'
  },
  pullContainer: {
    background: '#ef7373'
  },
  container: {
    background: '#ef7373',
    padding: '30px 30px 10px 30px',
    '& b': {
      fontSize: 64
    }
  },
  refreshContainer: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
});

class StartupErrorBox extends Component {
  handleRefresh = () => {
    return this.props.refreshHandler
      ? this.props.refreshHandler()
      : new Promise(r => r());
  };

  render() {
    const { classes } = this.props;

    return (
      <ReactPullToRefresh
        className={classes.pullContainer}
        distanceToRefresh={80}
        icon={<RefreshIcon className={classes.refreshBubble} />}
        onRefresh={this.handleRefresh}
      >
        <div className={classes.container}>
          <b>:(</b>
          <p>Da ist etwas schief gelaufen.</p>
          <div className={classes.refreshContainer}>
            <ArrowDownwardIcon />
            <p>Hier runter ziehen zum Neuladen</p>
            <ArrowDownwardIcon />
          </div>
        </div>
      </ReactPullToRefresh>
    );
  }
}

export default withStyles(styles)(StartupErrorBox);
