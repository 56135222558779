import React, { Component } from 'react';
import Modal from '../';

export default class PersistenceReloadModal extends Component {
  ok() {
    window.location.reload();
    this.props.onClose();
  }

  cancel() {
    this.props.onClose();
  }

  render() {
    return (
      <Modal
        onlyOk
        title="Jetzt Neuladen"
        text="Damit MyVoiz auch offline funktionieren kann, muss die App einmal Neuladen."
        okText="Jetzt Neuladen"
        open={this.props.open}
        handleRequestOk={this.ok.bind(this)}
      />
    );
  }
}
