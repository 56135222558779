import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default class CustomerAddressSelect extends Component {
  render() {
    let items = this.props.addresses
      ? this.props.addresses.map((address, index) => {
          return (
            <MenuItem key={index} value={'' + index}>
              {address.raw}
            </MenuItem>
          );
        })
      : '';

    return (
      <FormControl
        className="fullwidth"
        disabled={this.props.disabled || false}
      >
        <InputLabel htmlFor="address-select">Adresse</InputLabel>
        <Select
          value={this.props.value}
          onChange={this.props.onChange}
          input={<Input id="address-select" />}
        >
          <MenuItem value="-1">
            <em>Keine</em>
          </MenuItem>
          {items}
        </Select>
      </FormControl>
    );
  }
}
