import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SettingsIcon from '@material-ui/icons/Settings';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
  card: {
    position: 'relative',
    display: 'flex'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  cover: {
    width: 71,
    height: 71,
    backgroundSize: 'contain',
    margin: '40px'
  },
  coverNoCard: {
    width: 100,
    height: 100,
    backgroundSize: 'contain',
    margin: 10
  },
  toggle: {
    position: 'absolute',
    right: 5,
    top: 5
  },
  noCard: {
    boxShadow: 'none'
  },
  settings: {
    position: 'absolute',
    right: 18,
    bottom: 20,
    width: 35,
    height: 35
  }
});

class LinkedAccountCard extends Component {
  render() {
    const {
      classes,
      theme,
      className = '',
      noCard = false,
      onSettings = false
    } = this.props;

    return (
      <div className={className}>
        <Card
          className={
            'simple-card ' + classes.card + (noCard ? ' ' + classes.noCard : '')
          }
        >
          <CardMedia
            className={noCard ? classes.coverNoCard : classes.cover}
            image={this.props.image}
          />
          {onSettings ? (
            <IconButton className={classes.settings} onClick={onSettings}>
              <SettingsIcon />
            </IconButton>
          ) : null}
          <Switch
            className={classes.toggle}
            checked={this.props.checked}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            aria-label={this.props.title}
          />
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography variant="h5">{this.props.title}</Typography>
              <Typography variant="subtitle1" color="secondary">
                {this.props.subtitle || 'Füge dein Konto hinzu.'}
              </Typography>
            </CardContent>
          </div>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LinkedAccountCard);
