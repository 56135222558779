import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
class IbanInput extends Component {
  render() {
    // Ugly ass fix but working ...
    let props = Object.assign({}, this.props);
    if (props && props.inputRef) {
      delete props.inputRef;
    }

    return (
      <MaskedInput
        {...props}
        mask={[
          /[A-Z]/,
          /[A-Z]/,
          /\d/,
          /\d/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/
        ]}
        placeholderChar={'\u2000'}
      />
    );
  }
}

export default IbanInput;
