import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

export default class AnimatedOverlay extends Component {
  constructor(props) {
    super(props);

    let { componentArgs } = props;
    let orignalHistory = componentArgs.history;
    componentArgs.history = {
      orignalHistory,
      push: (path, state) => {
        if (state && state.animate) {
          this.setState(
            {
              closing: true
            },
            () => {
              setTimeout(() => {
                orignalHistory.push(path, state);
              }, 100);
            }
          );
          return;
        }
        orignalHistory.push(path, state);
      },
      goBack: () => {
        this.setState(
          {
            closing: true
          },
          () => {
            setTimeout(() => {
              orignalHistory.goBack();
            }, 100);
          }
        );
      }
    };

    this.state = {
      closing: false,
      componentArgs
    };
  }

  render() {
    let {
      component: Component,
      animationIn = 'zoomIn',
      animationOut = 'zoomOut'
    } = this.props;
    let className = '';
    if (this.state.closing) {
      className = ' ' + animationOut;
    } else if (
      this.state.componentArgs.history.orignalHistory.action === 'PUSH'
    ) {
      className = ' ' + animationIn;
    }

    return (
      <div className={'animate-overlay-wrapper animated' + className}>
        <Component {...this.state.componentArgs} />
      </div>
    );
  }
}
