import React, { Component } from 'react';
import firebase from '../../../services/firebase';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  main: {
    boxSizing: 'borderBox',
    margin: '30px auto',
    maxWidth: 1200,
    width: '90%'
  },

  card: {
    boxSizing: 'border-box',
    borderRadius: 4,
    height: '70vh',
    padding: '20px',
    overflowY: 'auto'
  },

  flex: {
    flex: 1
  },

  topBar: {
    display: 'flex',
    margin: '0 5px 10px 5px'
  },

  hidden: {
    display: 'none'
  }
});

class ContentCard extends Component {
  render() {
    let { classes, topBarContent, content, visible = null } = this.props;

    return (
      <div
        className={
          classes.main + (visible === false ? ' ' + classes.hidden : '')
        }
      >
        <div className={classes.topBar}>{topBarContent}</div>
        <Paper
          className={
            classes.card +
            (this.props.className ? ' ' + this.props.className : '')
          }
        >
          {content}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(ContentCard);
