import WatchJS from 'melanke-watchjs';
let watch = WatchJS.watch;
let unwatch = WatchJS.unwatch;

export function bindToState(obj, key, receiver = false, receiverKey = false) {
  if (!receiver) {
    receiver = this;
  }
  if (!receiverKey) {
    receiverKey = key;
  }
  if (!receiver.hasOwnProperty('__bindedHandler')) {
    bindSetup(receiver);
  }

  let handler = () => {
    receiver.setState({
      [receiverKey]: obj[key]
    });
  };

  // TO Watch for changes
  watch(obj, key, handler);

  // Initial
  handler();

  // For Unbinding
  receiver.__bindedHandler.push({
    obj,
    key,
    handler
  });
}

function bindSetup(receiver) {
  receiver.__bindedHandler = [];
  let realHook = receiver.componentWillUnmount
    ? receiver.componentWillUnmount
    : () => {};
  receiver.componentWillUnmount = () => {
    receiver.__bindedHandler.forEach(handler => {
      unwatch(handler.obj, handler.key, handler.handler);
    });
    receiver.__bindedHandler = [];
    realHook();
  };
}
