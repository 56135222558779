import React, { Component } from 'react';
import MailInformationBase from '../../sites/Settings/mail-information';
import Overlay from '../../components/Overlay';
import _ from 'lodash';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import MailInformationCards from '../../components/Cards/MailInformationCards';
import Preloader from '../../components/Preloader';

export default class MailInformation extends MailInformationBase {
  render() {
    let { subject, text, senderName, senderMail, saving } = this.state;
    return (
      <Overlay title="Emailangaben" onBack={this.goBack} realAppBar>
        <MailInformationCards
          subject={subject}
          text={text}
          senderName={senderName}
          senderMail={senderMail}
          onChange={this.onChange}
        />

        <Fab
          onClick={this.save}
          className={'default-fab animated ' + this.state.animation}
          color="primary"
          aria-label="save"
        >
          <SaveIcon />
        </Fab>
        {saving ? <Preloader /> : null}
      </Overlay>
    );
  }
}
