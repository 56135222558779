import React, { Component } from 'react';
import firebase from '../../services/firebase';
import { convertToCash, getInvoiceMonthTotal } from '../../helper/calc';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';

const chartOptions = {
  responsive: true,
  hover: { mode: null },

  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 20,
      bottom: 0
    }
  },

  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ],
    yAxes: [
      {
        display: true,
        ticks: {
          beginAtZero: true,
          steps: 10,
          stepValue: 5
        }
      }
    ]
  },

  legend: {
    display: false,

    labels: {
      // This more specific font property overrides the global property
      fontColor: '#e2e2e2'
    }
  },

  tooltips: {
    enabled: true,
    mode: 'single',
    callbacks: {
      label: function(tooltipItem, data) {
        return convertToCash(tooltipItem.yLabel) + ' €';
      }
    }
  }
};

const styles = theme => ({});

class TotalSalesChart extends Component {
  getOptions(data) {
    let obj = Object.assign({}, chartOptions);
    let setMax = true;
    data.datasets[0].data.forEach(item => {
      if (item > 100) {
        setMax = false;
      }
    });
    if (setMax) {
      obj.scales.yAxes[0].ticks.max = 100;
    }
    return obj;
  }

  getData() {
    return {
      labels: this.getLastMonth(),
      datasets: [
        {
          label: 'Einnahmen',
          backgroundColor: '#82b1ff',
          borderColor: '#78909c',
          pointBackgroundColor: '#78909c',
          pointBorderColor: '#f7f7f7',
          lineTension: 0,
          borderWidth: 3,
          radius: 9,
          pointRadius: 9,
          hitRadius: 15,
          data: this.getValues()
        }
      ]
    };
  }

  getLastMonth(count = 5) {
    let month = [];
    let momentInstance = moment();
    while (month.length < count) {
      month.push(momentInstance.format('MMMM'));
      momentInstance = momentInstance.subtract(1, 'month').startOf('month');
    }
    return month.reverse();
  }

  getValues(count = 5) {
    let values = [];
    let momentInstance = moment();
    while (values.length < count) {
      values.push(
        getInvoiceMonthTotal(this.props.invoices, momentInstance.month())
      );
      momentInstance = momentInstance.subtract(1, 'month').startOf('month');
    }
    return values.reverse();
  }

  render() {
    const { classes } = this.props;
    let data = this.getData();

    return <Line data={data} options={this.getOptions(data)} />;
  }
}

export default withStyles(styles)(TotalSalesChart);
