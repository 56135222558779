import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';

export default class ComponentWithHeader extends Component {

  componentWillMount() {
    this._renderHeader();
  }

  renderHeader() {
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    let renderHeader = false;
    if(this.headerProps && this.headerProps.updateStateKeys){
      this.headerProps.updateStateKeys.forEach( key => {
        if(prevState[key] !== this.state[key]) {
          renderHeader = true;
        }
      });
    }

    if (renderHeader) {
      this._renderHeader();
    }
  }

  _renderHeader() {
    this.props.updateHeaderContent && this.props.updateHeaderContent(
      <Fragment>
        {this.renderHeader()}
        <span style={{flex: 1}}></span>
        { this.headerProps && this.headerProps.searchKey && <TextField placeholder="Suche ..." value={this.state[this.headerProps.searchKey]} onChange={ e => {
          this.setState({[this.headerProps.searchKey]: e.target.value}, () => this._renderHeader());
        }} />}
      </Fragment>
    );
  }

}
