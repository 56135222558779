import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DeleteIcon from '@material-ui/icons/Delete';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextSelectDialog from './TextSelectDialog';
import PaymentDataDialog from './PaymentDataDialog';
import IbanInput from './IbanInput';

const textItems = [
  {
    desc: 'Kleinunternehmer UmSt-Hinweis',
    text:
      'Keine Anrechnung von Umsatzsteuer gemäß Kleinunternehmerregelung (§ 19 Abs. 1 UStG).'
  },
  {
    desc: 'Dienstleitungs Danksagung',
    text:
      'Ich habe gerne für Sie gearbeitet und freue mich auf zukünftige Aufträge.'
  }
];

const styles = theme => ({
  logoContainer: {
    textAlign: 'center'
  },

  logoWrap: {
    height: 120,
    borderRadius: 4,
    border: '1px dotted rgb(164, 164, 164)',
    margin: '10px 0'
  },

  logo: {
    height: '100%',
    width: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    boxSizing: 'border-box',
    padding: 2,
    border: 'none',
    borderRadius: 4
  },

  hidden: {
    display: 'none'
  },

  rmvBtn: {
    position: 'absolute',
    top: 5,
    right: 5
  }
});

class InvoiceInformationCards extends Component {
  state = {
    textSelectOpen: false,
    importPaymentDataOpen: false
  };

  handleChange = name => event => {
    if (name === 'logo' && !event.target.value) return;
    this.props.onChange(name, event.target.value, event.target);
  };

  handleTextSelectClickOpen = () => {
    this.setState({
      textSelectOpen: true
    });
  };

  handleTextSelectClose = key => {
    this.setState({ textSelectOpen: false });
    if (key) {
      let value = _.find(textItems, { desc: key }).text;
      this.props.onChange('notice', value);
    }
  };

  handleImportPaymentDataClickOpen = () => {
    this.setState({
      importPaymentDataOpen: true
    });
  };

  handleImportPaymentDataClose = paymentDetails => {
    this.setState({ importPaymentDataOpen: false });
    if (paymentDetails) {
      let { iban, bic, institute } = paymentDetails;
      this.props.onChange('iban', iban);
      this.props.onChange('bic', bic);
      this.props.onChange('institute', institute);
    }
  };

  uploadLogo = () => {
    this.refs.fileInput.click();
  };

  render() {
    let { classes, noCard } = this.props;
    return (
      <div>
        <Card className={'simple-card rel' + (noCard ? ' shadowless' : '')}>
          <CardContent>
            {this.props.logoUrl ? (
              <IconButton
                className={classes.rmvBtn}
                disabled={this.props.uploadingLogo || this.props.removingLogo}
                color="secondary"
                onClick={this.props.onRemoveLogo}
              >
                {this.props.removingLogo ? (
                  <CircularProgress
                    size={24}
                    className="button-preloader gray"
                  />
                ) : (
                  <DeleteIcon />
                )}
              </IconButton>
            ) : (
              ''
            )}
            <Typography variant="h5" component="h2">
              Logo
            </Typography>
            <div className={classes.logoContainer}>
              <div className={classes.logoWrap}>
                <div
                  className={classes.logo}
                  style={{ backgroundImage: `url(${this.props.logoUrl})` }}
                />
              </div>
              <Button
                disabled={this.props.uploadingLogo || this.props.removingLogo}
                variant="contained"
                color="primary"
                onClick={this.uploadLogo}
              >
                {this.props.uploadingLogo
                  ? 'Wird hochgeladen ...'
                  : 'Logo hochladen'}
              </Button>
            </div>
            <input
              ref="fileInput"
              className="hidden"
              onChange={this.handleChange('logo')}
              type="file"
            />
          </CardContent>
        </Card>
        <Card className={'simple-card' + (noCard ? ' shadowless' : '')}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Absender
            </Typography>

            <TextField
              className="fullwidth"
              label="Name / Firma"
              value={this.props.name}
              onChange={this.handleChange('name')}
              margin="normal"
            />

            <TextField
              className="fullwidth"
              label="Straße und Hausnummer"
              value={this.props.street}
              onChange={this.handleChange('street')}
              margin="normal"
            />

            <TextField
              className="fullwidth"
              label="Postleitzahl und Stadt"
              value={this.props.city}
              onChange={this.handleChange('city')}
              margin="normal"
            />

            <TextField
              className="fullwidth"
              label="Telefon"
              value={this.props.tel}
              onChange={this.handleChange('tel')}
              margin="normal"
              type="tel"
            />

            <TextField
              className="fullwidth"
              label="Fax"
              value={this.props.fax}
              onChange={this.handleChange('fax')}
              margin="normal"
              type="tel"
            />

            <TextField
              className="fullwidth"
              label="Email"
              value={this.props.email}
              onChange={this.handleChange('email')}
              margin="normal"
              type="email"
            />
          </CardContent>
        </Card>
        <Card className={'simple-card' + (noCard ? ' shadowless' : '')}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Steuer
            </Typography>

            <TextField
              className="fullwidth"
              label="Geschäftsführer"
              value={this.props.managingDirector}
              onChange={this.handleChange('managingDirector')}
              margin="normal"
            />

            <TextField
              className="fullwidth"
              label="Steuernummer"
              value={this.props.taxNumber}
              onChange={this.handleChange('taxNumber')}
              margin="normal"
            />

            <TextField
              className="fullwidth"
              label="USt.-Indentnummer"
              value={this.props.vatIndentNumber}
              onChange={this.handleChange('vatIndentNumber')}
              margin="normal"
            />

            <TextField
              className="fullwidth"
              label="Finanzamt"
              value={this.props.taxOffice}
              onChange={this.handleChange('taxOffice')}
              margin="normal"
            />
          </CardContent>
        </Card>
        <Card className={'simple-card' + (noCard ? ' shadowless' : '')}>
          <CardHeader
            className="no-bottom-padding"
            action={
              <IconButton onClick={this.handleTextSelectClickOpen}>
                <ModeCommentIcon />
              </IconButton>
            }
            title="Notizen"
          />
          <CardContent className="no-top-padding">
            <TextField
              className="fullwidth"
              label="Notizen"
              value={this.props.notice}
              onChange={this.handleChange('notice')}
              margin="normal"
              multiline
              rowsMax="4"
            />
          </CardContent>
        </Card>
        <Card className={'simple-card' + (noCard ? ' shadowless' : '')}>
          <CardHeader
            className="no-bottom-padding"
            action={
              <IconButton onClick={this.handleImportPaymentDataClickOpen}>
                <ImportExportIcon />
              </IconButton>
            }
            title="Zahlungsdetails"
          />
          <CardContent className="no-top-padding">
            <TextField
              className="fullwidth"
              label="Bankname"
              value={this.props.institute}
              onChange={this.handleChange('institute')}
              margin="normal"
            />

            <FormControl fullWidth>
              <InputLabel htmlFor="iban">IBAN</InputLabel>
              <Input
                id="iban"
                className="fullwidth"
                label="IBAN"
                placeholder="DEXX XXXX XXXX XXXX XXXX XX"
                value={this.props.iban}
                onChange={this.handleChange('iban')}
                inputComponent={IbanInput}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel htmlFor="bic">BIC</InputLabel>
              <Input
                id="bic"
                className="fullwidth"
                label="BIC"
                placeholder=""
                value={this.props.bic}
                onChange={this.handleChange('bic')}
              />
            </FormControl>
          </CardContent>
        </Card>

        <TextSelectDialog
          open={this.state.textSelectOpen}
          onClose={this.handleTextSelectClose}
          items={_.map(textItems, 'desc')}
        />

        <PaymentDataDialog
          open={this.state.importPaymentDataOpen}
          onClose={this.handleImportPaymentDataClose}
        />
      </div>
    );
  }
}

export default withStyles(styles)(InvoiceInformationCards);
