import React, { Component } from 'react';
import { nextInvoiceNumber } from '../../helper/number-gen';

export default class InvoiceStartBase extends Component {
  state = {
    startData: {
      sendMail: true,
      sendPostMail: false
    },
    invoiceData: {
      invoiceId: nextInvoiceNumber(),
      invoiceDueDays: 10,
      invoiceDate: new Date()
    }
  };

  dataChanged(key, val) {
    if (key === 'invoiceDate') {
      val.setHours(12);
    }
    this.writeObject(key, val, 'invoiceData');
  }

  writeObject(key, val, index) {
    this.setState({
      [index]: Object.assign(this.state[index], {
        [key]: val
      })
    });
  }

  mailsEnabled() {
    return !!(
      this.props.subscription.emailsEnabled &&
      this.props.invoice.data().data.customer.email
    );
  }

  postMailsEnabled() {
    return !!(
      this.props.subscription.postMailsEnabled &&
      this.props.invoice.data().data.selectedAddress
    );
  }
}
