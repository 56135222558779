import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Runtime from '../../../services/Runtime';
import { checkPasswordSafe } from '../../../helper/calc';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TableHead from './TableHead';
import TableToolbar from './TableToolbar';
import Checkbox from '@material-ui/core/Checkbox';
import AssignmentIcon from '@material-ui/icons/Assignment';

const styles = theme => ({
  token: {
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

class APITokenDialog extends Component {
  state = {
    selected: []
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.props.token.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  delete = () => {
    const { selected } = this.state;
    this.props.onDelete(
      this.props.token.filter(token => selected.indexOf(token.id) > -1)
    );
    this.setState({ selected: [] });
  };

  render() {
    const { token = [], classes } = this.props;
    const { selected } = this.state;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <TableToolbar numSelected={selected.length} onDelete={this.delete} />
        <Table aria-labelledby="tableTitle" className={classes.table}>
          <TableHead
            numSelected={selected.length}
            onSelectAllClick={this.handleSelectAllClick}
            onRequestSort={this.handleRequestSort}
            rowCount={token.length}
          />
          <TableBody>
            {token &&
              token.map(n => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    key={n.id}
                    onClick={event => this.handleClick(event, n.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={isSelected} />
                    </TableCell>
                    <TableCell scope="row" className={classes.tokenCell}>
                      <span className={classes.token}>{n.data().token}</span>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        <DialogActions>
          <Button
            disabled={this.props.loading}
            onClick={this.props.onAdd}
            color="secondary"
          >
            Neuer Schlüssel
          </Button>
          <Button onClick={this.props.onClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(APITokenDialog);
