import _ from 'lodash';

export function searchCustomers(customers, query) {
  customers = _.map(customers, customer => {
    customer =
      'data' in customer
        ? Object.assign({ __id: customer.id }, customer.data())
        : customer;
    return Object.assign(
      { _search: createCustomerSearchString(customer) },
      customer
    );
  });

  return _.filter(customers, customer => {
    return customer._search.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  });
}

export function createCustomerSearchString(customer) {
  return `${customer.title} ${customer.firstname} ${customer.lastname}`.trim();
}
