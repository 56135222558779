import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import './styles.css';

export default class QuotaCards extends Component {
  render() {
    let { props } = this;
    let { emailsEnabled, postMailsEnabled } = props;

    // Customer Calculations
    let customersCreatedLeft =
      props.maxCustomersMonthly - props.customersCreated;
    let customersTotalLeft = props.maxCustomersTotal - props.customersTotal;
    let customersCreated =
      props.maxCustomersMonthly === -1
        ? 100
        : Math.ceil((100 / props.maxCustomersMonthly) * customersCreatedLeft);
    let customersTotal =
      props.maxCustomersTotal === -1
        ? 100
        : Math.ceil((100 / props.maxCustomersTotal) * customersTotalLeft);

    // Invoice Calculations
    let invoicesCreatedLeft = props.maxInvoicesMonthly - props.invoicesCreated;
    let invoicesTotalLeft = props.maxInvoicesTotal - props.invoicesTotal;
    let invoicesCreated =
      props.maxInvoicesMonthly === -1
        ? 100
        : Math.ceil((100 / props.maxInvoicesMonthly) * invoicesCreatedLeft);
    let invoicesTotal =
      props.maxInvoicesTotal === -1
        ? 100
        : Math.ceil((100 / props.maxInvoicesTotal) * invoicesTotalLeft);

    return (
      <div>
        <Card className="simple-card quota-card">
          <CardContent>
            <Typography variant="h5" component="h2">
              Kunden Kontingent
            </Typography>

            <div className="quota-box">
              {props.maxCustomersMonthly !== -1 &&
              props.maxCustomersTotal === -1 ? null : (
                <LinearProgress variant="determinate" value={customersTotal} />
              )}
              {props.maxCustomersMonthly !== -1 &&
              props.maxCustomersTotal ===
                -1 ? null : props.maxCustomersTotal === -1 ? (
                <h3>
                  <b>Unbegrenzt</b>
                </h3>
              ) : (
                <h3>
                  <b>{customersTotalLeft}</b> von{' '}
                  <b>{props.maxCustomersTotal}</b> verbleibend
                </h3>
              )}
            </div>

            <div className="quota-box">
              {props.maxCustomersMonthly === -1 &&
              props.maxCustomersTotal !== -1 ? null : (
                <LinearProgress
                  variant="determinate"
                  value={customersCreated}
                />
              )}
              {props.maxCustomersMonthly === -1 &&
              props.maxCustomersTotal !==
                -1 ? null : props.maxCustomersMonthly === -1 ? (
                <h3>
                  Monatlich <b>Unbegrenzt</b>
                </h3>
              ) : (
                <h3>
                  <b>{customersCreatedLeft}</b> von{' '}
                  <b>{props.maxCustomersMonthly}</b> verbleibend in diesem Monat
                </h3>
              )}
            </div>
          </CardContent>
        </Card>

        <Card className="simple-card quota-card">
          <CardContent>
            <Typography variant="h5" component="h2">
              Rechnungs Kontingent
            </Typography>

            <div className="quota-box">
              {props.maxInvoicesMonthly !== -1 &&
              props.maxInvoicesTotal === -1 ? null : (
                <LinearProgress variant="determinate" value={invoicesTotal} />
              )}
              {props.maxInvoicesMonthly !== -1 &&
              props.maxInvoicesTotal === -1 ? null : props.maxInvoicesTotal ===
                -1 ? (
                <h3>
                  <b>Unbegrenzt</b>
                </h3>
              ) : (
                <h3>
                  <b>{invoicesTotalLeft}</b> von <b>{props.maxInvoicesTotal}</b>
                </h3>
              )}
            </div>

            <div className="quota-box">
              {props.maxInvoicesMonthly === -1 &&
              props.maxInvoicesTotal !== -1 ? null : (
                <LinearProgress variant="determinate" value={invoicesCreated} />
              )}
              {props.maxInvoicesMonthly === -1 &&
              props.maxInvoicesTotal !==
                -1 ? null : props.maxInvoicesMonthly === -1 ? (
                <h3>
                  Monatlich <b>Unbegrenzt</b>
                </h3>
              ) : (
                <h3>
                  <b>{invoicesCreatedLeft}</b> von{' '}
                  <b>{props.maxInvoicesMonthly}</b> verbleibend in diesem Monat
                </h3>
              )}
            </div>

            <div className="vspace-small" />

            <div className="feature-container">
              <div
                className={'feature-item' + (emailsEnabled ? ' enabled' : '')}
              >
                <span>
                  <MailIcon />
                </span>
                <p>
                  Rechnungsversand per Email
                  <br />
                  Status: <b>{emailsEnabled ? 'Aktiv' : 'Inaktiv'}</b>
                </p>
              </div>

              <div
                className={
                  'feature-item' + (postMailsEnabled ? ' enabled' : '')
                }
              >
                <span>
                  <MarkunreadMailboxIcon />
                </span>
                <p>
                  Postaler Rechnungsversand
                  <br />
                  Status: <b>{postMailsEnabled ? 'Aktiv' : 'Inaktiv'}</b>
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}
