import React, { Component } from 'react';
import _ from 'lodash';
import Measure from 'react-measure';
import Settings from '../../services/Settings';
import { withStyles } from '@material-ui/core/styles';
const widthForHeight = 0.707;

const styles = () => ({
  wrap: {
    position: 'relative',
    width: '100%',
    background: 'white'
  },

  templateFrame: {
    display: 'block',
    transform: 'scale(0.5)',
    transformOrigin: '0 0'
  },

  thumbnail: {
    objectFit: 'contain',
    display: 'block',
    width: '100%',
    height: 'auto'
  },

  accessBlock: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent'
  }
});

// Should be the same format than in workernode => otherwise different invoice results ;)
const format = {
  getTaxInfo(invoiceData) {
    let res = {};
    res.totalNetto = _.sumBy(invoiceData.data.items, o => {
      return o.price * o.amount;
    });
    res.taxes = _.mapValues(
      _.groupBy(
        _.filter(invoiceData.data.items, 'taxes').map(item => {
          return {
            taxes: item.taxes,
            taxValue: _.round((item.price * item.amount * item.taxes) / 100, 2)
          };
        }),
        'taxes'
      ),
      value => {
        return _.round(_.sumBy(value, 'taxValue'), 2);
      }
    );
    res.totalTaxes = _.sum(_.toArray(res.taxes));
    res.totalBrutto = res.totalNetto + res.totalTaxes;
    res.hasTaxes = res.totalTaxes > 0;
    return res;
  },

  makeUserSettingsNice(rawUserSettings) {
    return rawUserSettings.invoiceData;
  }
};

class TemplateRenderer extends Component {
  constructor(props) {
    super(props);
    const scale = 0.5;
    this.template = Settings.getTemplate('invoice-template');
    this.state = {
      scale,
      height: 0,
      width: `${100 / scale}%`,
      template: null
    };
  }

  updateHeight(measure) {
    const scale = this.state.scale;
    const width = measure.entry.width;
    const height = width / widthForHeight;
    this.setState({ height, wrapHeight: height * scale });
  }

  genHTML(html) {
    return 'data:text/html;charset=utf-8,' + encodeURIComponent(html);
  }

  genTemplateData(invoiceData) {
    return Object.assign(invoiceData, {
      __computed__: format.getTaxInfo(invoiceData),
      __user__: format.makeUserSettingsNice(Settings.get())
    });
  }

  render() {
    const { height, width, wrapHeight } = this.state;
    const { className = '', classes, data, thumbnail } = this.props;
    const html = this.template(this.genTemplateData(data));
    const htmlUrl = this.genHTML(html);

    if (this.props.hidden) {
      return null;
    }

    const isThumb = !!thumbnail;

    const iframeStyle = {
      visibility: isThumb ? 'hidden' : 'visible',
      width,
      height: isThumb ? 0 : height
    };

    return (
      <div
        style={{ height: wrapHeight }}
        className={classes.wrap + (className ? ' ' + className : '')}
      >
        <Measure onResize={e => this.updateHeight(e)}>
          {({ measureRef }) => (
            <React.Fragment>
              <iframe
                style={iframeStyle}
                className={classes.templateFrame}
                ref={measureRef}
                src={htmlUrl}
                frameBorder="no"
              />
              {isThumb ? (
                <img className={classes.thumbnail} src={thumbnail} />
              ) : null}
            </React.Fragment>
          )}
        </Measure>
        <div className={classes.accessBlock} />
      </div>
    );
  }
}

export default withStyles(styles)(TemplateRenderer);
