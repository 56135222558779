import React, { Component } from 'react';

export default class PageWrapper extends Component {
  componentWillMount() {
    this.props.beforeMount && this.props.beforeMount();
  }

  componentDidMount() {
    this.props.afterMount && this.props.afterMount();
  }

  render() {
    let { component } = this.props;
    return component;
  }
}
