import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import AddressListItem from './AddressListItem';
import { AddressModel } from '../../helper/models';

export default class AddressList extends Component {
  constructor(props) {
    super(props);
    this.addressIdNumber = 0;
    this.state = {
      addresses: props.addresses.map(address => {
        address.__id = this.addressIdNumber++;
        return address;
      })
    };
  }

  showAddComponent() {
    if (this.props.editMode && !this.props.disableAdding) {
      if (this.state.addresses.length === 0) {
        return true;
      }
      let lastAddress = AddressModel(
        this.state.addresses[this.state.addresses.length - 1]
      );
      if (lastAddress.raw) {
        return true;
      }
    }
    return false;
  }

  triggerOnChange(addresses) {
    if (this.props.onChange) {
      this.props.onChange(
        addresses.filter(address => {
          return address.raw !== '';
        })
      );
    }
  }

  handleChange = key => address => {
    let addresses = this.state.addresses;
    addresses[key] = address;
    this.setState({ addresses });
    this.triggerOnChange(addresses);
  };

  handleRemove = key => () => {
    let addresses = this.state.addresses;
    addresses.splice(key, 1);
    this.setState({ addresses });
    this.triggerOnChange(addresses);
  };

  render() {
    // The Add Component
    if (this.showAddComponent()) {
      this.state.addresses.push(AddressModel({ __id: this.addressIdNumber++ }));
    }

    // Addresses
    let addresses = !this.state.addresses
      ? ''
      : this.state.addresses.map((address, key) => {
          return (
            <AddressListItem
              key={this.props.editMode ? address.__id : key}
              disableDeleting={this.props.disableDeleting}
              address={AddressModel(address)}
              editMode={this.props.editMode}
              onChange={this.handleChange(key)}
              onClose={this.handleRemove(key)}
              noBtn={!!this.props.noBtn}
            />
          );
        });

    return (
      <List
        subheader={
          this.props.hideLabel ? (
            false
          ) : (
            <ListSubheader disableSticky>Adressen</ListSubheader>
          )
        }
      >
        {addresses}
      </List>
    );
  }
}
