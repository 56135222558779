import React, { Component } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import firebase from '../../../services/firebase';
import { getItemsNetto, getItemTaxes } from '../../../helper/calc';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import ItemAddDialog from './ItemAddDialog';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  price: {},
  addColor: {
    color: theme.palette.primary.main
  },
  firstDoubleDivider: {
    marginBottom: 4
  }
});

class InvoiceItemsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      dialogEditOpen: false,
      items: []
    };
  }

  showAddItemDialog = () => {
    this.setState({
      editItem: false,
      editItemIndex: false,
      dialogOpen: true
    });
  };

  showEditItemDialog = (item, index) => {
    this.setState({
      editItem: item,
      editItemIndex: index,
      dialogOpen: true
    });
  };

  onDialogClose = type => item => {
    let items = this.props.items || this.state.items;
    let hasChanged = false;
    if (type === 'submit') {
      if (this.state.editItem) {
        items[this.state.editItemIndex] = item;
      } else {
        items.push(item);
      }
      hasChanged = true;
    } else if (type === 'delete') {
      items.splice(this.state.editItemIndex, 1);
      hasChanged = true;
    }

    this.setState({
      dialogOpen: false,
      items
    });

    if (hasChanged && this.props.onChange) {
      this.props.onChange(items);
    }
  };

  niceDecimals = number => {
    return (parseFloat(number).toFixed(2) + '').replace('.', ',');
  };

  render() {
    const { classes } = this.props;

    const totalNetto = getItemsNetto(this.props.items || this.state.items);
    const taxes = getItemTaxes(this.props.items || this.state.items);
    const totalTaxes = _.sum(_.values(taxes));
    const totalBrutto = totalNetto + totalTaxes;

    return (
      <Card
        className={'simple-card' + (this.props.shadowless ? ' shadowless' : '')}
      >
        <CardContent>
          {this.props.noTitle ? (
            ''
          ) : (
            <Typography variant="h5" component="h2">
              {this.props.title || 'Einträge'}
            </Typography>
          )}
          <List>
            {(this.props.items || this.state.items).map((item, index) => (
              <ListItem
                key={index}
                size="small"
                button
                onClick={
                  this.props.readOnly
                    ? () => {}
                    : () => this.showEditItemDialog(item, index)
                }
                className={classes.listItem}
              >
                <ListItemText primary={item.description} />
                <div className={classes.price}>
                  <Chip
                    label={
                      item.amount + ' x ' + this.niceDecimals(item.price) + ' €'
                    }
                  />
                </div>
              </ListItem>
            ))}

            {this.props.readOnly ? (
              ''
            ) : (
              <ListItem button onClick={this.showAddItemDialog}>
                <ListItemIcon>
                  <AddIcon className={classes.addColor} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span className={classes.addColor}>Neuer Eintrag</span>
                  }
                />
              </ListItem>
            )}

            <Divider />
            <ListItem size="small" className={classes.listItem}>
              <ListItemText primary="Gesamt (Netto)" />
              <div className={classes.price}>
                <Chip label={this.niceDecimals(totalNetto) + ' €'} />
              </div>
            </ListItem>

            {_.map(taxes, (value, percentage) => (
              <ListItem
                key={percentage}
                size="small"
                className={classes.listItem}
              >
                <ListItemText primary={'UmSt (' + percentage + '%)'} />
                <div className={classes.price}>
                  <Chip label={this.niceDecimals(value) + ' €'} />
                </div>
              </ListItem>
            ))}

            <Divider className={classes.firstDoubleDivider} />
            <Divider />
            <ListItem size="small" className={classes.listItem}>
              <ListItemText primary="Gesamt (Brutto)" />
              <div className={classes.price}>
                <Chip label={this.niceDecimals(totalBrutto) + ' €'} />
              </div>
            </ListItem>
          </List>
          <ItemAddDialog
            editItem={this.state.editItem}
            open={this.state.dialogOpen}
            onSubmit={this.onDialogClose('submit')}
            onDelete={this.onDialogClose('delete')}
            onCancel={this.onDialogClose('cancel')}
          />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(InvoiceItemsCard);
