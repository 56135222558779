import _ from 'lodash';

export function getInvoiceMonthTotal(invoices, startMonth) {
  invoices = invoices.filter(inv => {
    return (
      inv.data().type !== 'draft' &&
      inv
        .data()
        .invoiceDate.toDate()
        .getMonth() === startMonth
    );
  });
  return _.sum(_.map(invoices, inv => getItemsTotal(inv.data().data.items)));
}

export function getItemsTotal(items) {
  let taxes = _.sum(_.values(getItemTaxes(items)));
  return getItemsNetto(items) + taxes;
}

export function getItemsNetto(items) {
  return _.sumBy(items, function(o) {
    return o.price * o.amount;
  });
}

export function getItemTaxes(items) {
  let taxes = _.mapValues(
    _.groupBy(
      _.filter(items, 'taxes').map(item => {
        return {
          taxes: item.taxes,
          taxValue: _.round((item.price * item.amount * item.taxes) / 100, 2)
        };
      }),
      'taxes'
    ),
    value => {
      return _.round(_.sumBy(value, 'taxValue'), 2);
    }
  );
  return taxes;
}

export function convertToCash(val) {
  return (parseFloat(val).toFixed(2) + '').replace('.', ',');
}

export function getRemainingInvoiceDays(invoiceData) {
  let dueDate = getInvoiceDueDate(invoiceData);
  let remainingDueDays = new Date().diffDays(dueDate);
  return remainingDueDays;
}

export function getInvoiceDueDate(invoiceData) {
  return invoiceData.invoiceDate.toDate().addDays(invoiceData.invoiceDueDays);
}

export function getLatestPayment(invoiceData) {
  // maybe in futur there are more than one payment
  let { payments } = invoiceData;
  if (payments && payments.length > 0) {
    return payments[payments.length - 1];
  }
  return null;
}

export function getDueWarnLevel(dueDaysRemaining) {
  return dueDaysRemaining > 3 ? 0 : dueDaysRemaining < 0 ? 2 : 1;
}

export function checkPasswordSafe(pass) {
  if (pass.length < 6) {
    return false;
  }
  return true;
}
