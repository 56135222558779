import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const PDF_STATUS_RENDERING = 'rendering';
const PDF_STATUS_ERROR = 'error';
const PDF_STATUS_DONE = 'done';

const styles = () => ({
  state: {
    padding: '7px 20px',
    fontSize: 12,
    backgroundColor: '#4f5f5cba',
    color: 'white',
    borderRadius: 14
  }
});

class InvoicePDFStatus extends Component {
  state = {
    startReRenderInProgress: false
  };

  getRenderStatus(invoice) {
    let { renderStatus, _reRender } = invoice.data();
    let type = '';
    let message = '';
    if (
      _reRender ||
      renderStatus === null ||
      this.state.startReRenderInProgress
    ) {
      type = PDF_STATUS_RENDERING;
      message = 'PDF in arbeit ..';
    } else if (renderStatus === 'in_queue') {
      type = PDF_STATUS_RENDERING;
      message = 'PDF in arbeit ...';
    } else if (renderStatus === 'rendering') {
      type = PDF_STATUS_RENDERING;
      message = 'PDF wird erstellt ...';
    } else if (renderStatus === 'done') {
      type = PDF_STATUS_DONE;
      message = 'PDF aktuell';
    } else if (renderStatus === 'error') {
      type = PDF_STATUS_ERROR;
      message = 'PDF Fehler';
    } else {
      // Cant check it, just tell them okay cuz we dont know anyways ;)
      type = PDF_STATUS_DONE;
      message = 'PDF aktuell';
    }

    return {
      type,
      message
    };
  }

  render() {
    if (!this.props.invoice) return null;
    const { classes, invoice, className = '' } = this.props;
    let classNameString = className
      ? className + ' ' + classes.state
      : classes.state;
    let {
      type: renderStatusType,
      message: renderStatusMessage
    } = this.getRenderStatus(invoice);

    return (
      <div
        className={classNameString}
        onClick={() =>
          this.props.onClick ? this.props.onClick(renderStatusType) : null
        }
      >
        {renderStatusMessage}
      </div>
    );
  }
}

export default withStyles(styles)(InvoicePDFStatus);
