import constants from '../config/constants';

const boundary = '-------314159265358979323846';
const delimiter = '\r\n--' + boundary + '\r\n';
const close_delim = '\r\n--' + boundary + '--';

export default {
  boundary,
  delimiter,
  close_delim,
  init: false,
  initPromise: null,
  gapi: window.gapi,
  client: null,
  isSignedIn: false,
  gDrive: {
    account: null,
    quota: null
  },

  getClient() {
    return new Promise(resolve => {
      if (this.client) {
        return resolve(this.client);
      }

      if (this.initPromise) {
        resolve(this.initPromise);
      } else {
        resolve(this.init());
      }
    });
  },

  init() {
    let promise = new Promise((resolve, reject) => {
      if (!this.gapi) {
        reject('Could not load GAPI.');
      }
      this.gapi.load('client', () => {
        resolve(this.gapi.client.init(constants.gapi));
      });
    }).then(res => {
      this.init = true;
      this.initPromise = null;
      this.client = this.gapi.client;
      this._registerHandlers();
      return res;
    });

    this.initPromise = promise;
    return promise;
  },

  getFile(fileId) {
    return this.getClient().then(client =>
      client.request({
        path: '/drive/v3/files/' + fileId,
        method: 'GET',
        params: {
          fields: 'id,name,trashed,parents'
        }
      })
    );
  },

  async uploadFile(file, name) {
    let { boundary } = this;
    let client = await this.getClient();
    let base64Data = await this.blob2base64(file);

    return await client.request({
      path: '/upload/drive/v3/files',
      method: 'POST',
      params: {
        uploadType: 'multipart'
      },
      headers: {
        'Content-Type': 'multipart/mixed; boundary="' + boundary + '"'
      },
      body: this.generateMultipartRequestBody(name, file.type, base64Data)
    });
  },

  generateMultipartRequestBody(name, mimeType, base64Data) {
    var pos = base64Data.indexOf('base64,');
    return (
      `${this.delimiter}Content-Type: application/json\n\n` +
      `${JSON.stringify({ name, mimeType })}\n${this.delimiter}` +
      `Content-Type: ${mimeType}\nContent-Transfer-Encoding: base64\n\n` +
      `${base64Data.slice(pos < 0 ? 0 : pos + 'base64,'.length)}\n` +
      `${this.close_delim}`
    ).replace(/\r?\n/g, '\r\n');
  },

  blob2base64(blob) {
    return new Promise(resolve => {
      var reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  },

  _loadAccountInfo() {
    this.gapi.client.drive.about
      .get({ fields: 'user,storageQuota' })
      .then(res => {
        this.gDrive.account = res.result.user;
        this.gDrive.quota = res.result.storageQuota;
      });
  },

  _registerHandlers() {
    this._handleSigninChange(
      this.gapi.auth2.getAuthInstance().isSignedIn.get()
    );
    this.gapi.auth2
      .getAuthInstance()
      .isSignedIn.listen(state => this._handleSigninChange(state));
  },

  _handleSigninChange(isSignedIn) {
    this.isSignedIn = isSignedIn;
    if (isSignedIn) {
      this._loadAccountInfo();
    } else {
      this.gDrive.account = null;
      this.gDrive.quota = null;
    }
  }
};
