import React, { Component } from 'react';
import InvoiceExportBase from '../../sites/Invoice/export';
import Overlay from '../../components/Overlay';
import CircularProgress from '@material-ui/core/CircularProgress';
import grey from '@material-ui/core/colors/grey';
import CloseIcon from '@material-ui/icons/Close';
import InvoicesTimeSpanCard from '../../components/Cards/InvoiceTimeSpanCard';
import ErrorCard from '../../components/Cards/ErrorCard';

class InvoiceExport extends InvoiceExportBase {
  render() {
    // Error
    let { error } = this.state;

    // BackIcon
    let backIcon = <CloseIcon />;

    return (
      <Overlay
        title="Rechnungsexport"
        backgroundColor={grey[400]}
        backIcon={backIcon}
        onBack={this.goBack.bind(this)}
        rightButtonText={
          this.state.inProgress ? (
            <CircularProgress size={24} className="button-preloader" />
          ) : this.state.success ? (
            'Download ...'
          ) : (
            'Exportieren'
          )
        }
        rightButtonDisabled={
          this.state.inProgress || this.state.success ? true : false
        }
        rightButtonEvent={this.exportAndClose.bind(this)}
        realAppBar
      >
        {error ? <ErrorCard text={error} onOk={this.resetError} /> : null}
        <InvoicesTimeSpanCard
          onChange={this.timespanChanged.bind(this)}
          timespan={this.state.timespan}
        />
      </Overlay>
    );
  }
}

export default InvoiceExport;
