export function gotoCustomerAdd(history, state = null) {
  history.push('/customers/add', state);
}

export function gotoCustomerEdit(history, customerId, state = null) {
  history.push(`/customer/${customerId}/edit`, state);
}

export function gotoCustomer(history, customerId, state = null) {
  history.push(`/customer/${customerId}`, state);
}

export function gotoCustomers(history, state = null) {
  history.push('/customers', state);
}

export function gotoInvoiceAdd(history, state = null) {
  history.push('/invoices/add', state);
}

export function gotoInvoiceStart(history, invoiceId, state = null) {
  history.push(`/invoice/${invoiceId}/start`, state);
}

export function gotoInvoiceMarkPayed(history, invoiceId, state = null) {
  history.push(`/invoice/${invoiceId}/mark-payed`, state);
}

export function gotoInvoice(history, invoiceId, state = null) {
  history.push(`/invoice/${invoiceId}`, state);
}

export function gotoInvoices(history, state = null) {
  history.push('/invoices', state);
}

export function gotoDriveSetup(history, state = null) {
  history.push('/settings/accounts', state);
}
