import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SettingsService from '../../../../services/Settings';

const styles = theme => ({
  dialog: {},

  amountTypeButton: {
    textTransform: 'none'
  },

  amountTypeAdornment: {
    height: 32
  },

  deleteButton: {
    position: 'absolute',
    right: 10,
    top: 10
  }
});

class EuroCurrencyFormat extends React.Component {
  format = number => {
    let formatted = '' + this.convertToFloat(number, true);
    formatted += ' €';
    return formatted;
  };

  convertToFloat = (number, string = false) => {
    let padChar = string ? '-' : 0;
    let delChar = string ? ',' : '.';
    let formatted = '' + number;
    while (formatted.length < 3) {
      formatted = padChar + formatted;
    }
    formatted =
      formatted.substr(0, formatted.length - 2) +
      delChar +
      formatted.substr(-2, 1) +
      formatted.substr(-1, 1);
    return string ? formatted : parseFloat(formatted);
  };

  render() {
    // Ugly ass fix but working ...
    let props = Object.assign({}, this.props);
    if (props && props.inputRef) {
      delete props.inputRef;
    }

    return (
      <NumberFormat
        {...props}
        onValueChange={values => {
          this.props.onChange({
            target: {
              value: {
                float: this.convertToFloat(values.value)
              }
            }
          });
        }}
        decimalSeparator=","
        format={this.format}
        allowNegative={false}
      />
    );
  }
}

class ItemAddDialog extends React.Component {
  state = {
    anchorEl: null,
    amountTypeMenuOpen: false,
    _price: '',
    price: '',
    description: '',
    amount: '',
    taxes: 19,
    amountTypes: SettingsService.get('generalSettings.units', [
      'Stück',
      'Stunden',
      'Minuten'
    ]),
    amountType: SettingsService.get('generalSettings.units', [
      'Stück',
      'Stunden',
      'Minuten'
    ])[0]
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handlePriceChange = event => {
    if (_.isObject(event.target.value)) {
      this.setState({
        price: event.target.value.float
      });
    } else {
      this.setState({
        _price: event.target.value
      });
    }
  };

  handleAmountTypeClick = event => {
    this.setState({ amountTypeMenuOpen: true, anchorEl: event.currentTarget });
  };

  handleAmountTypeRequestClose = amountType => {
    if (!amountType) {
      amountType = this.state.amountType;
    }
    this.setState({ amountTypeMenuOpen: false, amountType });
  };

  submit = () => {
    if (this.props.onSubmit) {
      let { price, description, amount, amountType, taxes } = this.state;
      this.props.onSubmit({ price, description, amount, amountType, taxes });
    }
  };

  delete = () => {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.open !== nextProps.open && nextProps.open) {
      if (nextProps.editItem) {
        let {
          price,
          description,
          amount,
          amountType,
          taxes
        } = nextProps.editItem;
        price = Number.parseFloat(price).toFixed(2);
        this.setState({
          _price: price.toString().replace('.', ','),
          price,
          description,
          amount,
          amountType,
          taxes
        });
      } else {
        this.setState({
          _price: '',
          price: '',
          description: '',
          amount: '',
          taxes: 19,
          amountType: this.state.amountTypes[0]
        });
      }
    }
  }

  componentDidMount() {
    // document.querySelector('#price').addEventListener('focus', function(){
    //   this.setAttribute('type', 'number');
    // });
    // document.querySelector('#price').addEventListener('blur', function(){
    //   this.setAttribute('type', 'text');
    // });
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle>
          {this.props.editItem ? 'Eintrag ändern' : 'Neuer Eintrag'}
        </DialogTitle>
        {this.props.editItem ? (
          <IconButton
            onClick={this.delete}
            className={classes.deleteButton}
            aria-label="Delete"
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          ''
        )}
        <DialogContent className={classes.dialog}>
          <Input
            className="fullwidth"
            value={this.state.description}
            onChange={this.handleChange('description')}
            placeholder="Beschreibung"
          />
          <br />
          <br />
          <div className="flex-2">
            <FormControl>
              <InputLabel htmlFor="price">Einzelpreis</InputLabel>
              <Input
                id="price"
                value={this.state._price}
                onChange={this.handlePriceChange}
                inputComponent={EuroCurrencyFormat}
                type="tel"
                placeholder="15,99 €"
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="amount">Menge</InputLabel>
              <Input
                id="amount"
                value={this.state.amount}
                onChange={this.handleChange('amount')}
                placeholder="5"
                type="number"
                endAdornment={
                  <InputAdornment
                    className={classes.amountTypeAdornment}
                    position="end"
                  >
                    <Button
                      className={classes.amountTypeButton}
                      aria-owns={this.state.open ? 'simple-menu' : null}
                      aria-haspopup="true"
                      onClick={this.handleAmountTypeClick}
                    >
                      {this.state.amountType}
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={this.state.anchorEl}
                      open={this.state.amountTypeMenuOpen}
                      onClose={() => this.handleAmountTypeRequestClose(false)}
                    >
                      {this.state.amountTypes.map((at, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => this.handleAmountTypeRequestClose(at)}
                        >
                          {at}
                        </MenuItem>
                      ))}
                    </Menu>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <br />
          <br />
          <FormControl className="fullwidth">
            <InputLabel htmlFor="taxes">Umsatzsteuer</InputLabel>
            <Select
              value={this.state.taxes}
              onChange={this.handleChange('taxes')}
              input={<Input id="taxes" />}
            >
              <MenuItem value={0}>Keine Umsatzsteuer</MenuItem>
              <MenuItem value={19}>19% Umsatzsteuer</MenuItem>
              <MenuItem value={7}>7% Umsatzsteuer</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary">
            Abbrechen
          </Button>
          <Button onClick={this.submit} color="primary">
            {this.props.editItem ? 'Speichern' : 'Hinzufügen'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ItemAddDialog);
