import React, { Component } from 'react';
import ComponentWithHeader from '../../components/ComponentWithHeader';
import _ from 'lodash';
import { getRemainingInvoiceDays, getLatestPayment } from '../../helper/calc';
import request from '../../helper/request';
import {
  filterIncomingTransactions,
  filterForInvoiceId
} from '../../helper/bank-transfer';
import Settings from '../../services/Settings';
import { updateInvoice } from '../../actions/invoice';

export default class InvoiceIndexBase extends ComponentWithHeader {
  state = {
    syncBankDialogOpen: false,
    syncBankLoading: false,
    syncBankDialogLoading: false
  };

  getProcessedInvoices(type) {
    return this.sortInvoices(this.getFilteredInvoices(type), type);
  }

  getFilteredInvoices(type) {
    return this.props.invoices.filter(invoice => {
      return invoice.data().type === type;
    });
  }

  sortInvoices(invoices, type) {
    if (type === 'draft') {
      return invoices; // todo: add sort after last update of draft
    } else if (type === 'running') {
      return _.sortBy(invoices, [
        inv => {
          return getRemainingInvoiceDays(inv.data());
        },
        inv => {
          return inv.data().invoiceId;
        }
      ]);
    } else if (type === 'finished') {
      return _.sortBy(invoices, [
        inv => {
          let payment = getLatestPayment(inv.data());
          return payment ? payment.date : 0;
        }
      ]).reverse();
    }
  }

  getInvoicesSorted() {
    let drafts = this.sortInvoices(this.getFilteredInvoices('draft'), 'draft');
    let running = this.sortInvoices(
      this.getFilteredInvoices('running'),
      'running'
    );
    let finished = this.sortInvoices(
      this.getFilteredInvoices('finished'),
      'finished'
    );
    return drafts.concat(running, finished);
  }

  findInvoice(id) {
    return _.find(this.props.invoices, { id });
  }

  findCustomer(id) {
    return _.find(this.props.customers, { id });
  }

  bankSyncDialogSubmit = transaction => {
    this.setState({ syncBankDialogLoading: true });
    return new Promise(resolve => {
      let updatePayload = {
        payments: [
          {
            amount: _.sumBy(transaction.transactions, 'amount') / 100,
            date: new Date(transaction.transactions[0].date),
            notice: transaction.transactions
              .map(p => p.name + ': ' + p.purpose)
              .join('\n')
          }
        ],
        type: 'finished'
      };
      this.setState({ markingInvoicePayed: true });
      this.props.dispatch(
        updateInvoice(transaction.invoice.id, updatePayload, err => {
          resolve();
        })
      );
    }).then(() => {
      this.setState({ syncBankDialogLoading: false });
      return;
    });
  };

  bankSyncDialogClose = () => {
    if (this.state.syncBankDialogLoading) {
      return;
    }
    this.setState({ syncBankDialogOpen: false });
  };

  async syncBankAccount() {
    let bankInfo = Settings.getBankData('kontist');
    if (!bankInfo) {
      return;
    }
    let { additionalData, bankCredentials } = bankInfo;
    this.setState({
      syncBankError: null,
      syncBankLoading: true,
      relevantBankTransactions: null
    });
    let running = this.getFilteredInvoices('running');
    if (running && running.length > 0) {
      try {
        let client = await request.getClient();
        let res = await client.__cloudfunctions.bank.getTransactions(
          bankCredentials,
          additionalData.selectedAccountId
        );
        let incomming = filterIncomingTransactions(res.data.transactions);
        let relevantBankTransactions = running
          .map(invoice => {
            return {
              invoice,
              transactions: filterForInvoiceId(
                incomming,
                invoice.data().invoiceId
              )
            };
          })
          .filter(t => t.transactions);
        this.setState({
          syncBankDialogOpen: true,
          syncBankLoading: false,
          relevantBankTransactions
        });
      } catch (e) {
        this.setState({
          syncBankLoading: false,
          syncBankError: e,
          relevantBankTransactions: null
        });
      }
    }
  }
}

export function mapStateToProps(state, ownProps) {
  return {
    initialAccountLoadDone: state.account.initialLoadDone,
    account: state.account.account,
    initialLoadDone: state.invoice.initialLoadDone,
    invoices: state.invoice.invoices,
    initialCustomerLoadDone: state.customer.initialLoadDone,
    customers: state.customer.customers
  };
}

export function mapDispatchToProps(dispatch) {
  return { dispatch };
}
