import React, { Component } from 'react';
import firebase from '../../../../services/firebase';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { DatePicker } from 'material-ui-pickers';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const styles = theme => ({
  card: {
    position: 'relative'
  },

  modal: {
    boxShadow: 'none'
  }
});

class PayDateCard extends Component {
  handleChange = moment => {
    this.props.onChange(moment.toDate());
  };

  render() {
    const { classes } = this.props;

    return (
      <Card
        className={
          classes.card +
          (this.props.modal ? ' ' + classes.modal : ' simple-card')
        }
      >
        <CardContent>
          <DatePicker
            leftArrowIcon={<KeyboardArrowLeft />}
            rightArrowIcon={<KeyboardArrowRight />}
            className="fullwidth"
            label="Zahlungsdatum"
            format="LL"
            value={this.props.date}
            onChange={this.handleChange}
          />
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(PayDateCard);
