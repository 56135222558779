import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from 'material-ui-pickers';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default class InvoiceTimeSpanCard extends Component {
  changeFromDate = date => {
    if (this.props.onChange) {
      this.props.onChange(
        Object.assign({}, this.props.timespan, { from: date.toDate() })
      );
    }
  };

  changeToDate = date => {
    if (this.props.onChange) {
      this.props.onChange(
        Object.assign({}, this.props.timespan, { to: date.toDate() })
      );
    }
  };

  toggleFromDate = () => {
    if (this.props.onChange) {
      this.props.onChange(
        Object.assign({}, this.props.timespan, {
          from: this.props.timespan.from ? null : new Date()
        })
      );
    }
  };

  toggleToDate = () => {
    if (this.props.onChange) {
      this.props.onChange(
        Object.assign({}, this.props.timespan, {
          to: this.props.timespan.to ? null : new Date()
        })
      );
    }
  };

  render() {
    let { timespan } = this.props;

    let content = (
      <React.Fragment>
        <div className="flex-2">
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={!timespan.from}
                  onChange={this.toggleFromDate}
                  value="checkedA"
                />
              }
              label="Von Anfang"
            />
            <DatePicker
              disabled={!timespan.from}
              leftArrowIcon={<KeyboardArrowLeft />}
              rightArrowIcon={<KeyboardArrowRight />}
              label="Von"
              format="LL"
              value={timespan.from}
              onChange={this.changeFromDate}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={!timespan.to}
                  onChange={this.toggleToDate}
                  value="checkedA"
                />
              }
              label="Bis Ende"
            />
            <DatePicker
              disabled={!timespan.to}
              leftArrowIcon={<KeyboardArrowLeft />}
              rightArrowIcon={<KeyboardArrowRight />}
              label="Bis"
              format="LL"
              value={timespan.to}
              onChange={this.changeToDate}
            />
          </div>
        </div>
      </React.Fragment>
    );

    if (this.props.modal) {
      return <React.Fragment>{content}</React.Fragment>;
    }

    return (
      <Card className="simple-card">
        <CardContent>
          <Typography variant="h5" component="h2">
            Zeitspanne
          </Typography>
          {content}
        </CardContent>
      </Card>
    );
  }
}
