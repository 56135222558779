import React, { Component } from 'react';
import firebase from '../../../services/firebase';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Typography from '@material-ui/core/Typography';
import { gotoInvoiceAdd, gotoCustomerAdd } from '../../../helper/transition';
import './styles.css';

export default class ShareCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: firebase.auth().currentUser
    };
  }

  share() {
    // WEB SHARE API
    if (navigator.share) {
      navigator
        .share({
          title: 'MyVoiz - Invoice Management',
          text: 'Watch that! This shit´s lit asf!',
          url: window.location.origin
        })
        .then(() => console.log('Successfully shared'))
        .catch(error => console.log('Error sharing:' + error));
    } else {
      alert('Diese Funktion ist auf deinem Gerät nicht verfügbar.');
    }
  }

  render() {
    return (
      <Card className="simple-card">
        <CardContent>
          <Typography variant="h5" component="h2">
            Hey {this.state.currentUser.displayName}!
          </Typography>
          <Typography variant="body1">
            Bereit ?<br />
            Du kannst direkt loslegen, einen neuen Kunden anlegen oder eine neue
            Rechnung erstellen!
          </Typography>
        </CardContent>
        <CardActions className="share-action-bar">
          <IconButton
            onClick={this.share.bind(this)}
            className="icon-button"
            color="secondary"
            aria-label="Menu"
          >
            <ShareIcon />
          </IconButton>
          <IconButton
            onClick={() => gotoInvoiceAdd(this.props.history)}
            className="icon-button"
            color="secondary"
            aria-label="Menu"
          >
            <NoteAddIcon />
          </IconButton>
          <IconButton
            onClick={() => gotoCustomerAdd(this.props.history)}
            className="icon-button"
            color="secondary"
            aria-label="Menu"
          >
            <PersonAddIcon />
          </IconButton>
        </CardActions>
      </Card>
    );
  }
}
