import React, { Component } from 'react';
import AccountsBase from '../../sites/Settings/accounts';
import Overlay from '../../components/Overlay';
import KontistSetupDialog from '../../components/Dialogs/KontistSetupDialog';
import KontistDisconnectModal from '../../components/Modal/KontistDisconnectModal';
import LinkedAccountCard from '../../components/Cards/LinkedAccountCard';
import kontist from '../../img/kontist.svg';
import n26 from '../../img/n26.png';
import gdrive from '../../img/gdrive.svg';

export default class Accounts extends AccountsBase {
  render() {
    return (
      <Overlay title="Verlinkte Accounts" onBack={this.goBack} realAppBar>
        <LinkedAccountCard
          title="Kontist"
          image={kontist}
          onChange={this.toggleKontist}
          checked={this.state.kontist ? true : false}
        />
        <KontistSetupDialog
          open={this.state.kontistSetupModalOpen}
          onCancel={this._setState('kontistSetupModalOpen', false)}
          onSubmit={this.saveKontist}
        />

        <LinkedAccountCard
          title="Google Drive"
          image={gdrive}
          onChange={this.toggleGDrive}
          onSettings={this.state.gdrive ? this.openGDriveSettings : false}
          checked={this.state.gdrive ? true : false}
        />

        {/* <LinkedAccountCard title="N26" image={n26} onChange={this.toggleN26} checked={this.state.n26 ? true : false}/> */}
        {/* <KontistDisconnectModal open={this.state.kontistRemoveModalOpen} ok={this.disconnectKontist} cancel={this._setState('kontistRemoveModalOpen', false)} /> */}
      </Overlay>
    );
  }
}
