import React, { Component } from 'react';
import MUIAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  menuButton: {
    marginLeft: '-14px'
  },
  grow: {
    flexGrow: 1
  },
  toolbar: {
    paddingRight: 0
  }
});

class AppBar extends Component {
  render() {
    let { menuButton, grow, toolbar } = this.props.classes;

    return (
      <React.Fragment>
        <MUIAppBar className="app-bar" position="fixed" color="primary">
          <Toolbar className={toolbar}>
            <IconButton
              onClick={this.props.menuToggle}
              className={menuButton}
              color="inherit"
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={grow}>
              MyVoiz
            </Typography>
            {this.props.menu}
          </Toolbar>
        </MUIAppBar>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AppBar);
