import axios from 'axios';
import config from '../config/constants';
import firebase from '../services/firebase';

export default {
  getClient() {
    return new Promise(resolve => {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(data => {
          resolve(this.genClient(data));
        });
    });
  },

  genClient(authToken) {
    // Generate Client with Configuration
    let client = axios.create({
      baseURL: config.firebase.httpBase,
      timeout: 10000,
      headers: { Authorization: 'Bearer ' + authToken }
    });

    // Add Helper specific for our backend
    client.__cloudfunctions = {
      // Initial Verification
      initialVerify() {
        return client.post('initial-verify');
      },

      // Export invoices
      exportInvoices: (from = null, to = null) => {
        return client.post('export-invoices', { from, to });
      },

      // Gernerate API Key
      createAPIToken: () => {
        return client.post('create-api-token');
      },

      // Encrypt Bank Credentials
      encryptBankCredentials: (username, password, provider) => {
        return client.post('encrypt-bank-credentials', {
          username,
          password,
          provider
        });
      },

      // Functions wich need encrypted bank credential string
      bank: {
        getAccounts: encryptedBankCredentials => {
          return client.post('bank/get-accounts', {
            bankCredentials: encryptedBankCredentials
          });
        },

        getTransactions: (encryptedBankCredentials, accountId) => {
          return client.post('bank/get-transactions', {
            bankCredentials: encryptedBankCredentials,
            accountId
          });
        }
      },

      // Invoice Functions
      invoice: {
        getShareLink: invoiceRecordId => {
          return client.get(`/invoice/${invoiceRecordId}/share-link`);
        }
      }
    };

    return client;
  }
};
