import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { checkPasswordSafe } from '../../../helper/calc';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class BetaDialog extends Component {

  onClose = () => {
    this.props.onClose();
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Alpha Version</DialogTitle>
        <DialogContent>
          <p>
            Hey Benutzer, cool dich hier zu sehen. MyVoiz ist im moment noch in der <b>Alpha-Phase</b>, was für dich
            konkret bedeutet:<br /><br />
            - <b>Daten oder Accounts</b> können jederzeit <b>gelöscht</b> werden<br />
            - es können <b>viele Fehler</b> auftrehten<br />
            - es gibt noch potenzielle <b>Sicherheitslücken</b><br /><br />
            Für die beste erfahrung benutze eine aktuelle Version von <b>Google Chrome</b>
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color="primary">
            Verstanden
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
