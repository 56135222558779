import React, { Component } from 'react';
import firebase from '../../../../services/firebase';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EmailIcon from '@material-ui/icons/Email';
import Preloader from '../../../Preloader';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
  card: {
    position: 'relative'
  },
  modal: {
    boxShadow: 'none'
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.palette.text.secondary
  }
});

class EmailCard extends Component {
  state = {
    customer: null,
    loading: false
  };

  handleChange = (event, checked) => {
    this.props.onChange(checked);
  };

  render() {
    const { classes, modal } = this.props;

    return (
      <Card
        className={
          classes.card + (modal ? ' ' + classes.modal : ' simple-card')
        }
      >
        <CardHeader
          avatar={
            <Avatar>
              <EmailIcon />
            </Avatar>
          }
          action={
            <Switch
              checked={!this.props.disabled && this.props.active}
              onChange={this.handleChange}
              disabled={this.props.disabled}
            />
          }
          title="Email versenden"
          subheader="Kunden jetzt per Email informieren"
        />
      </Card>
    );
  }
}

export default withStyles(styles)(EmailCard);
