import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import FolderIcon from '@material-ui/icons/Folder';
import SettingsIcon from '@material-ui/icons/Settings';
import './styles.css';

export default class MenuList extends Component {
  transition(path) {
    this.props.history.push(path);
  }

  render() {
    const gotoDashbaord = () => this.transition('/dashboard');
    const gotoCustomers = () => this.transition('/customers');
    const gotoInvoices = () => this.transition('/invoices');
    const gotoSettings = () => this.transition('/settings');

    return (
      <div className="MenuList">
        <List>
          <ListItem button onClick={gotoDashbaord}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={gotoCustomers}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Kunden" />
          </ListItem>
          <ListItem button onClick={gotoInvoices}>
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText primary="Rechnungen" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={gotoSettings}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Einstellungen" />
          </ListItem>
        </List>
      </div>
    );
  }
}
