import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { updateInvoice } from '../../actions/invoice';
import {
  getDueWarnLevel,
  getRemainingInvoiceDays,
  getInvoiceDueDate,
  getLatestPayment
} from '../../helper/calc';
import Moment from 'react-moment';
import InvoicePDFStatus from '../InvoicePDFStatus';
const PDF_STATUS_ERROR = 'error';

const styles = theme => ({
  root: {
    position: 'relative',
    zIndex: 2
  },
  status: {
    textAlign: 'center',
    color: 'white',
    padding: '20px 0',
    margin: '0 0 20px 0'
  },
  state: {
    position: 'absolute',
    left: 10,
    top: -35
  },
  dueInfoSuccess: {
    backgroundColor: '#a0d2b0'
  },

  dueInfoWarning: {
    backgroundColor: '#d2c7a0'
  },

  dueInfoDanger: {
    backgroundColor: '#d2a0a0'
  },
  infoDraft: {
    backgroundColor: '#6b7876'
  },
  infoDone: {
    backgroundColor: '#59c27b'
  }
});

class InvoiceTopInfoBar extends Component {
  renderStatusClick = currentStatusType => {
    let { invoice } = this.props;
    if (
      currentStatusType === PDF_STATUS_ERROR &&
      !this.state.startReRenderInProgress
    ) {
      this.setState({ startReRenderInProgress: true });
      this.props.dispatch(
        updateInvoice(invoice.id, { _reRender: true }, () => {
          this.setState({ startReRenderInProgress: false });
        })
      );
    }
  };

  render() {
    if (!this.props.invoice) return null;
    const { classes, invoice } = this.props;

    let content = null;
    let colorClass = null;
    let type = invoice.data().type;

    if (type === 'running') {
      // DueColor
      let dueDate = getInvoiceDueDate(invoice.data());
      let remainingDueDays = getRemainingInvoiceDays(invoice.data());
      let warnLevel = getDueWarnLevel(remainingDueDays);
      switch (warnLevel) {
        case 2:
          colorClass = classes.dueInfoDanger;
          break;

        case 1:
          colorClass = classes.dueInfoWarning;
          break;

        case 0:
        default:
          colorClass = classes.dueInfoSuccess;
      }
      content = (
        <span>
          Fällig{' '}
          <Moment interval={60000} fromNow locale="de">
            {dueDate}
          </Moment>
        </span>
      );
    }

    if (type === 'draft') {
      content = <span>Entwurf</span>;
      colorClass = classes.infoDraft;
    }

    if (type === 'finished') {
      let latestPayment = getLatestPayment(invoice.data());
      content = (
        <span>
          Bezahlt{' '}
          <Moment interval={60000} fromNow locale="de">
            {latestPayment.date.toDate()}
          </Moment>
        </span>
      );
      colorClass = classes.infoDone;
    }

    return (
      <div className={classes.root}>
        <InvoicePDFStatus
          invoice={invoice}
          className={classes.state}
          onClick={this.renderStatusClick}
        />
        <div className={classes.status + ' ' + colorClass}>{content}</div>
      </div>
    );
  }
}

export default withStyles(styles)(InvoiceTopInfoBar);
