import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import SubscriptionBase, {
  mapStateToProps,
  mapDispatchToProps
} from '../../sites/Settings/subscription';
import QuotaCards from '../../components/Cards/QuotaCards';
import SubscriptionCard from '../../components/Cards/SubscriptionCard';

const styles = theme => ({
  wrapper: {
    width: 400,
    margin: '60px auto 10px auto'
  }
});

class Subscription extends SubscriptionBase {
  render() {
    let { classes, stats, account } = this.props;
    let statsData = stats.data();
    let acc = account.data();
    let subData = acc.subscriptionData;

    return (
      <div className={classes.wrapper}>
        <SubscriptionCard type={acc.subscriptionType} />
        <QuotaCards
          emailsEnabled={subData.emailsEnabled}
          postMailsEnabled={subData.postMailsEnabled}
          maxCustomersMonthly={subData.monthlyCustomersCount}
          maxCustomersTotal={subData.totalCustomersCount}
          customersCreated={statsData.customersCreated}
          customersTotal={statsData.customersTotal}
          maxInvoicesMonthly={subData.monthlyInvoicesCount}
          maxInvoicesTotal={subData.totalInvoicesCount}
          invoicesCreated={statsData.invoicesCreated}
          invoicesTotal={statsData.invoicesTotal}
        />
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Subscription)
);
