import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PayDateCard from '../Cards/InvoiceMarkPayedCards/PayDateCard';
import PayNoticeCard from '../Cards/InvoiceMarkPayedCards/PayNoticeCard';
import PayAmountCard from '../Cards/InvoiceMarkPayedCards/PayAmountCard';
import { nextInvoiceNumber } from '../../helper/number-gen';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InvoiceMarkPayedBase from './index';

const styles = theme => ({
  dialog: {
    minWidth: 400
  },

  amountTypeButton: {
    textTransform: 'none'
  },

  amountTypeAdornment: {
    height: 32
  },

  deleteButton: {
    position: 'absolute',
    right: 10,
    top: 10
  }
});

class InvoiceMarkPayedModal extends InvoiceMarkPayedBase {
  render() {
    let { classes } = this.props;
    if (!this.props.invoice) return;

    return (
      <Dialog
        className={classes.dialog}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle>Als bezahlt markieren</DialogTitle>

        <DialogContent className={classes.dialog}>
          <PayAmountCard modal amount={this.state.amount} />
          <PayDateCard
            modal
            date={this.state.date}
            onChange={this.onChange('date')}
          />
          <PayNoticeCard
            modal
            notice={this.state.notice}
            onChange={this.onChange('notice')}
          />
        </DialogContent>

        <DialogActions>
          <Button
            disabled={this.props.markingAsPayed}
            onClick={() => this.props.onClose()}
            color="secondary"
          >
            Abbrechen
          </Button>
          <Button
            disabled={this.props.markingAsPayed}
            onClick={() => this.props.onMarkPayed(this.state)}
            color="primary"
          >
            Als bezahlt markieren
            {this.props.markingAsPayed ? (
              <CircularProgress size={24} className="button-preloader gray" />
            ) : (
              ''
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(InvoiceMarkPayedModal);
