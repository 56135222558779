import React, { Component } from 'react';
import firebase from '../../services/firebase';
import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  root: {
    padding: '0 10px'
  }
});

class CurrentInvoiceStats extends Component {
  getRunningText() {
    if (this.props.running > 1) {
      return `${this.props.running} laufende Rechnungen`;
    } else {
      return `${this.props.running} laufende Rechnung`;
    }
  }

  getOverdueText() {
    if (this.props.overdue > 1) {
      return `${this.props.overdue} überfällige Rechnungen`;
    } else {
      return `${this.props.overdue} überfällige Rechnung`;
    }
  }

  render() {
    const { classes } = this.props;

    let overdue;
    if (this.props.overdue) {
      overdue = (
        <ListItem
          button
          onClick={
            this.props.onOverdueClick ? this.props.onOverdueClick : () => {}
          }
        >
          <ListItemIcon>
            <WarningIcon />
          </ListItemIcon>
          <ListItemText inset primary={this.getOverdueText()} />
        </ListItem>
      );
    }

    let running;
    if (this.props.running) {
      running = (
        <ListItem
          button
          onClick={
            this.props.onRunningClick ? this.props.onRunningClick : () => {}
          }
        >
          <ListItemIcon>
            <DoneIcon />
          </ListItemIcon>
          <ListItemText inset primary={this.getRunningText()} />
        </ListItem>
      );
    }

    let createInvoices;
    if (!this.props.overdue && !this.props.running) {
      createInvoices = ''; // Keine laufenden Rechnungen View
    }

    return (
      <div className={classes.root}>
        <List>
          {overdue}
          {running}
          {createInvoices}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(CurrentInvoiceStats);
