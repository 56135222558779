import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Preloader from '../../components/Preloader';
import Overlay from '../../components/Overlay';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as LI from '../../components/ListItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomerEditBase, {
  mapStateToProps,
  mapDispatchToProps
} from '../../sites/Customer/edit';
import AddressList from '../../components/AddressList';
import { gotoCustomers, gotoCustomer } from '../../helper/transition';
import ContentCard from '../../components/Desktop/ContentCard';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = theme => ({
  btn: {
    marginTop: '-12px',
    marginRight: '10px'
  },

  container: {
    display: 'flex'
  },

  pane: {
    width: '50%'
  },

  flex: {
    flex: 1
  }
});

class CustomerEdit extends CustomerEditBase {
  render() {
    let { classes } = this.props;

    // If loading
    if (!this.props.initialLoadDone) {
      return <Preloader />;
    }

    // Get Customer
    let customer =
      this.state.mode === 'edit' ? this.state.customer : this.state.newData;

    // If not found
    if (!customer && this.props.initialLoadDone) {
      return (
        <Redirect
          to={{ pathname: '/404', state: { from: this.props.location } }}
        />
      );
    } else if (!customer) {
      return <Preloader />;
    }
    let data = this.state.mode === 'edit' ? customer.data() : customer;

    let savePreloader = this.state.saving && (
      <CircularProgress size={24} className="button-preloader gray" />
    );

    return (
      <ContentCard
        topBarContent={
          <React.Fragment>
            {this.state.mode === 'edit' ? (
              ''
            ) : (
              <IconButton
                className={classes.btn}
                color="primary"
                onClick={() => gotoCustomers(this.props.history)}
              >
                <ArrowBackIcon />
              </IconButton>
            )}

            <Typography variant="h6" color="secondary">
              {this.state.mode === 'edit'
                ? 'Kunden editieren'
                : 'Neuen Kunden hinzufügen'}
            </Typography>

            <span className={classes.flex} />

            {this.state.mode === 'edit' && !this.state.saving ? (
              <Button
                color="secondary"
                onClick={() => gotoCustomer(this.props.history, customer.id)}
              >
                Abbrechen
              </Button>
            ) : (
              ''
            )}

            <Button
              color="primary"
              disabled={this.state.saving}
              onClick={
                this.state.mode === 'edit'
                  ? this.saveAndClose.bind(this)
                  : this.addAndClose.bind(this)
              }
            >
              {this.state.saving
                ? ''
                : this.state.mode === 'edit'
                ? 'Speichern'
                : 'Hinzufügen'}
              {savePreloader}
            </Button>
          </React.Fragment>
        }
        content={
          <div className={classes.container}>
            <div className={classes.pane}>
              <List
                subheader={
                  <ListSubheader disableSticky>
                    Kundeninformationen
                  </ListSubheader>
                }
              >
                <LI.KundennummerListItem
                  data={data.cid}
                  editMode
                  noBtn
                  onChange={this.handleChange('cid')}
                />
                <LI.TitleListItem
                  data={data.title}
                  editMode
                  noBtn
                  onChange={this.handleChange('title')}
                />
                <LI.FirstnameListItem
                  data={data.firstname}
                  editMode
                  noBtn
                  onChange={this.handleChange('firstname')}
                />
                <LI.LastnameListItem
                  data={data.lastname}
                  editMode
                  noBtn
                  onChange={this.handleChange('lastname')}
                />
                <LI.CompanyListItem
                  data={data.company}
                  editMode
                  noBtn
                  onChange={this.handleChange('company')}
                />
                <LI.EmailListItem
                  data={data.email}
                  editMode
                  noBtn
                  onChange={this.handleChange('email')}
                />
                <LI.PhoneListItem
                  data={data.phone}
                  editMode
                  noBtn
                  onChange={this.handleChange('phone')}
                />
                <LI.MobileListItem
                  data={data.mobile}
                  editMode
                  noBtn
                  onChange={this.handleChange('mobile')}
                />
              </List>
            </div>
            <div className={classes.pane}>
              <AddressList
                addresses={data.addresses}
                editMode
                noBtn
                onChange={this.handleChange('addresses')}
              />
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomerEdit)
);
