import React, { Component } from 'react';

export default class SubscriptionBase extends Component {}

export function mapStateToProps(state, ownProps) {
  return {
    initialLoadDone:
      state.stats.initialLoadDone && state.account.initialLoadDone,
    account: state.account.account,
    stats: state.stats.stats
  };
}

export function mapDispatchToProps(dispatch) {
  return { dispatch };
}
