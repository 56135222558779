import React, { Component } from 'react';
import SettingsService from '../../services/Settings';
import GApiService from '../../services/GApi';
import { bindToState } from '../../helper/binding';

export default class AccountsBase extends Component {
  constructor(params) {
    super(params);
    this.state = {
      n26: SettingsService.getBankData('n26'),
      n26SetupModalOpen: false,
      n26RemoveModalOpen: false,
      kontist: SettingsService.getBankData('kontist'),
      kontistSetupModalOpen: false,
      kontistRemoveModalOpen: false,
      gdrive: GApiService.isSignedIn,
      gDriveAccount: null
    };
  }

  componentDidMount() {
    bindToState(GApiService, 'isSignedIn', this, 'gdrive');
    bindToState(GApiService.gDrive, 'account', this, 'gDriveAccount');
  }

  goBack = () => {
    this.props.history.goBack();
  };

  _setState = (key, val) => () => {
    this.setState({
      [key]: val
    });
  };

  updateBankData = () => {
    this.setState({
      n26: SettingsService.getBankData('n26'),
      kontist: SettingsService.getBankData('kontist')
    });
  };

  toggleGDrive = () => {
    if (this.state.gdrive) {
      GApiService.gapi.auth2.getAuthInstance().signOut();
    } else {
      GApiService.gapi.auth2.getAuthInstance().signIn();
    }
  };

  openGDriveSettings() {}

  toggleN26 = () => {
    if (this.state.kontist) {
      this.setState({ n26RemoveModalOpen: true });
    } else {
      this.setState({ n26SetupModalOpen: true });
    }
  };

  toggleKontist = () => {
    if (this.state.kontist) {
      this.setState({ kontistRemoveModalOpen: true });
    } else {
      this.setState({ kontistSetupModalOpen: true });
    }
  };

  saveKontist = ({
    encryptedBankCredentials,
    selectedAccountId,
    selectedAccount
  }) => {
    let { iban, bankName } = selectedAccount;
    SettingsService.saveBankData('kontist', encryptedBankCredentials, {
      selectedAccountId,
      iban,
      bankName
    });
    this.updateBankData();
    this.setState({ kontistSetupModalOpen: false });
  };

  disconnectKontist = () => {
    SettingsService.removeBankData('kontist');
    this.updateBankData();
    this.setState({ kontistRemoveModalOpen: false });
  };
}
