import React, { Component } from 'react';
import MenuList from './MenuList';
import UserMenuList from './UserMenuList';
import UserInfo from './UserInfo';
import './styles.css';

export default class Drawer extends Component {
  state = {
    open: false,
    userMenuOpen: false
  };

  open() {
    this.menu.open();
  }

  close() {
    this.menu.close();
  }

  componentDidMount() {
    let fullWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    let width = fullWidth - 56;
    width = width > 280 ? 280 : width;
    this.menu = window.TouchMenuLA({
      target: this.refs.drawer,
      width,
      zIndex: 2,
      onOpen: () => {
        this.setState({ open: true, userMenuOpen: false });
      },
      onClose: () => {
        this.setState({ open: false });
      }
    });
  }

  render() {
    let menu = this.state.userMenuOpen ? (
      <UserMenuList history={this.props.history} />
    ) : (
      <MenuList history={this.props.history} />
    );

    return (
      <div className="drawer touch-menu-la" ref="drawer">
        <header>
          <UserInfo
            open={this.state.userMenuOpen}
            onToggle={userMenuOpen => {
              this.setState({ userMenuOpen });
            }}
          />
        </header>
        <section>{menu}</section>
      </div>
    );
  }
}
