import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AccountsBase from '../../sites/Settings/accounts';
import Overlay from '../../components/Overlay';
import KontistSetupDialog from '../../components/Dialogs/KontistSetupDialog';
import KontistDisconnectModal from '../../components/Modal/KontistDisconnectModal';
import LinkedAccountCard from '../../components/Cards/LinkedAccountCard';
import kontist from '../../img/kontist.svg';
import n26 from '../../img/n26.png';
import gdrive from '../../img/gdrive.svg';

const styles = theme => ({
  // Global overwritten styles from index.js
  desktopWrapper: {},
  wrap: {},
  actionBar: {},
  noChange: {},
  content: {},

  // Custom Styles
  flex: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '20px 0'
  },

  accCard: {
    width: '45%'
  }
});

class Accounts extends AccountsBase {
  render() {
    let { saveVisible } = this.state;
    let { classes } = this.props;
    return (
      <div className={classes.wrap}>
        <div className={classes.actionBar}>
          <span className={classes.noChange}>Einstellungen aktuell</span>
        </div>

        <div className={classes.flex}>
          <LinkedAccountCard
            noCard
            className={classes.accCard}
            title="Kontist"
            image={kontist}
            onChange={this.toggleKontist}
            checked={this.state.kontist ? true : false}
          />
          {/* <LinkedAccountCard noCard className={classes.accCard} title="N26" image={n26} onChange={this.toggleN26} checked={this.state.n26 ? true : false}/> */}
          <LinkedAccountCard
            noCard
            className={classes.accCard}
            title="Google Drive"
            image={gdrive}
            onSettings={this.state.gdrive ? this.openGDriveSettings : false}
            onChange={this.toggleGDrive}
            checked={this.state.gdrive ? true : false}
          />
        </div>

        <KontistSetupDialog
          open={this.state.kontistSetupModalOpen}
          onCancel={this._setState('kontistSetupModalOpen', false)}
          onSubmit={this.saveKontist}
        />
        <KontistDisconnectModal
          open={this.state.kontistRemoveModalOpen}
          ok={this.disconnectKontist}
          cancel={this._setState('kontistRemoveModalOpen', false)}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Accounts);
