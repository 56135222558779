import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SettingsService from '../../../../services/Settings';
import { bicCodes } from '../../../../config/constants';

class PaymentDataDialog extends Component {
  state = {
    kontist: SettingsService.getBankData('kontist'),
    n26: SettingsService.getBankData('n26')
  };

  handleClose = () => {
    this.props.onClose(null);
  };

  handleListItemClick = value => {
    if (!value) return this.props.onClose(null);
    let { iban, bankName: institute } = this.state[value].additionalData;
    let bic = bicCodes[institute];
    this.props.onClose({ iban, bic, institute });
  };

  render() {
    const { classes, onClose, selectedValue, ...other } = this.props;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        {...other}
      >
        <DialogTitle id="simple-dialog-title">
          Kontodaten importieren
        </DialogTitle>
        <div>
          <List>
            <ListItem
              disabled={this.state.kontist ? false : true}
              button
              onClick={() => this.handleListItemClick('kontist')}
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountBalanceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Kontist" />
            </ListItem>

            <ListItem
              disabled={this.state.n26 ? false : true}
              button
              onClick={() => this.handleListItemClick('N26')}
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountBalanceIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="N26" />
            </ListItem>

            <ListItem button onClick={() => this.handleListItemClick(null)}>
              <ListItemAvatar>
                <Avatar>
                  <CloseIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Nichts importieren" />
            </ListItem>
          </List>
        </div>
      </Dialog>
    );
  }
}

export default PaymentDataDialog;
