import _ from 'lodash';

export default function(
  state = {
    initialLoadDone: false,
    stats: null
  },
  action
) {
  // new state object
  state = { ...state };

  switch (action.type) {
    case 'FETCH_STATS_AUTO_UPDATE':
      state.initialLoadDone = true;
      state.stats = action.payload;
      break;
  }
  return state;
}
