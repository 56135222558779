import React, { Component } from 'react';
import _ from 'lodash';
import ListSubheader from '@material-ui/core/ListSubheader';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { connect } from 'react-redux';
import { gotoInvoice, gotoCustomer } from '../../helper/transition';
import './styles.css';

const loadingSteps = 3;
class CustomerInvoiceList extends Component {
  state = {
    visibleItems: 5
  };

  invoices() {
    if (!this.props.customer || !this.props.invoices) {
      return [];
    }
    return _.sortBy(
      this.props.invoices.filter(inv => {
        return (
          inv.data().type !== 'draft' &&
          inv.data().customer &&
          inv.data().customer.id === this.props.customer.id
        );
      }),
      [
        inv => {
          let { type } = inv.data();
          return type === 'draft' ? 0 : type === 'running' ? 1 : 2;
        },
        inv => {
          return inv.data().invoiceId || 0;
        }
      ]
    ).reverse();
  }

  allVisible(invoices) {
    return this.state.visibleItems >= invoices.length;
  }

  loadMore = () => {
    this.setState({ visibleItems: this.state.visibleItems + loadingSteps });
  };

  render() {
    let { history, customer } = this.props;

    let customerInvoices = this.invoices();

    // Addresses // On draft just replace created by draft
    let invoices = _.take(customerInvoices, this.state.visibleItems).map(
      invoice => {
        let invoiceData = invoice.data();
        return (
          <ListItem
            button
            onClick={() => {
              gotoInvoice(history, invoice.id, { goBack: true });
            }}
            key={invoice.id}
          >
            <ListItemIcon>
              {invoiceData.type === 'running' ? <DoneIcon /> : <DoneAllIcon />}
            </ListItemIcon>
            <ListItemText
              inset
              primary={
                invoiceData.type === 'draft'
                  ? 'Entwurf'
                  : 'Rechnung #' + invoiceData.invoiceId
              }
              secondary={invoiceData.created}
            />
          </ListItem>
        );
      }
    );

    if (!this.props.initialLoadDone) {
      invoices.push(
        <center key="loader">
          <CircularProgress color="primary" className="" size={30} />
        </center>
      );
    }

    if (!this.allVisible(customerInvoices)) {
      invoices.push(
        <ListItem button onClick={this.loadMore} key="load-more">
          <ListItemIcon>
            <ExpandMoreIcon />
          </ListItemIcon>
          <ListItemText inset primary="Mehr anzeigen ..." />
        </ListItem>
      );
    }

    return (
      <List
        subheader={
          <ListSubheader disableSticky>{this.props.title}</ListSubheader>
        }
      >
        {invoices}
      </List>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    initialLoadDone: state.invoice.initialLoadDone,
    invoices: state.invoice.invoices
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerInvoiceList);
