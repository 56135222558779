import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';

import SideNav from '../SideNav';
import UserButton from '../UserButton';

const styles = theme => ({
  wrap: {
    width: '100vw',
    position: 'relative',
    overflow: 'hidden'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
    color: theme.palette.secondary.main,
    transition: 'all ease-in-out 0.35s',
    width: 'auto',
    left: 70,
    right: 0
  },
  appBarOnExpanded: {
    left: 260
  },
  content: {
    marginLeft: 70,
    transition: 'all ease-in-out 0.35s'
  },
  contentOnForceExpanded: {
    marginLeft: 260
  },
  flex: {
    flex: 1
  }
});

class App extends Component {
  state = {
    forceExpanded: false,
    menuExpanded: false
  };

  onExpandChange = menuExpanded => () => {
    this.setState({ menuExpanded });
  };

  toggleForceExtension = () => {
    this.setState({ forceExpanded: !this.state.forceExpanded });
  };

  render() {
    let { classes } = this.props;

    return (
      <div className={classes.wrap}>
        <SideNav
          history={this.props.history}
          forceExpanded={this.state.forceExpanded}
          onExpand={this.onExpandChange(true)}
          onReduce={this.onExpandChange(false)}
        />

        <AppBar
          position="absolute"
          className={
            classes.appBar +
            (this.state.menuExpanded ? ' ' + classes.appBarOnExpanded : '')
          }
        >
          <Toolbar>
            <IconButton
              onClick={this.toggleForceExtension}
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
            {this.props.headerContent || <span className={classes.flex} />}
            <UserButton history={this.props.history} />
          </Toolbar>
        </AppBar>

        <div
          className={
            classes.content +
            (this.state.forceExpanded
              ? ' ' + classes.contentOnForceExpanded
              : '')
          }
        >
          {this.props.content}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(App);
