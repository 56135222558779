import React, { Component } from 'react';
import InvoiceIndexBase, {
  mapStateToProps,
  mapDispatchToProps
} from '../../sites/Invoice';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ContentCard from '../../components/Desktop/ContentCard';
import BankSyncDialog from '../../components/Dialogs/BankSyncDialog';
import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import DoneIcon from '@material-ui/icons/Done';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { CustomerModel } from '../../helper/models';
import { gotoInvoice, gotoInvoiceAdd } from '../../helper/transition';
import {
  getItemsTotal,
  convertToCash,
  getLatestPayment,
  getInvoiceDueDate,
  getDueWarnLevel,
  getRemainingInvoiceDays
} from '../../helper/calc';

const styles = theme => ({
  flex: {
    flex: 1
  },

  addBtn: {
    marginLeft: '10px'
  },

  leftIcon: {
    marginRight: 10
  },

  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    }
  },

  usercell: {
    display: 'flex',
    margin: '10px 10px 10px 0'
  },

  username: {
    padding: '6px 15px',
    fontSize: '20px',
    fontWeight: 200
  },

  avatar: {
    width: 35,
    height: 35,
    fontSize: 14
  },

  typeAvatar: {
    color: 'inherit',
    backgroundColor: 'transparent'
  },

  dueChipFinished: {
    color: 'rgb(142, 199, 155)',
    border: '1px dashed rgb(142, 199, 155)',
    background: 'transparent'
  },

  dueChip: {
    color: 'white'
  },

  dueInfoSuccess: {
    backgroundColor: '#a0d2b0',
    border: '1px solid #a0d2b0'
  },

  dueInfoWarning: {
    backgroundColor: '#d2c7a0',
    border: '1px solid #d2c7a0'
  },

  dueInfoDanger: {
    backgroundColor: '#d2a0a0',
    border: '1px solid #d2a0a0'
  },

  amount: {
    fontSize: '24px',
    fontWeight: 200
  },

  invoiceId: {
    fontSize: '18px',
    fontWeight: 200
  },

  chipDraft: {
    color: 'rgb(167, 167, 167)',
    border: '1px dashed rgb(167, 167, 167)',
    background: 'transparent'
  },

  btnpreloader: {
    marginRight: 10
  }
});

class Invoice extends InvoiceIndexBase {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, this.state, {
      search: ''
    });
    this.headerProps = {
      searchKey: 'search',
      updateStateKeys: ['syncBankLoading']
    };
  }

  getTypeAvatar(type, className) {
    switch (type) {
      case 'draft':
        return (
          <Avatar className={className}>
            <InsertDriveFileIcon />
          </Avatar>
        );
      case 'running':
        return (
          <Avatar className={className}>
            <DoneIcon />
          </Avatar>
        );
      case 'finished':
        return (
          <Avatar className={className}>
            <DoneAllIcon />
          </Avatar>
        );
    }
  }

  getDueColorClass(remainingDueDays, classes) {
    let warnLevel = getDueWarnLevel(remainingDueDays);
    switch (warnLevel) {
      case 2:
        return classes.dueInfoDanger;

      case 1:
        return classes.dueInfoWarning;

      case 0:
      default:
        return classes.dueInfoSuccess;
    }
  }

  getDueChip(invoiceData, classes) {
    let content;
    let avatar = this.getTypeAvatar(invoiceData.type, classes.typeAvatar);
    switch (invoiceData.type) {
      case 'draft':
        return (
          <Chip
            avatar={avatar}
            label={'Entwurf'}
            className={classes.dueChip + ' ' + classes.chipDraft}
          />
        );

      case 'running':
        let dueDate = getInvoiceDueDate(invoiceData);
        let remainingDueDays = getRemainingInvoiceDays(invoiceData);
        let colorClass = this.getDueColorClass(remainingDueDays, classes);
        content = (
          <div>
            Fällig{' '}
            <Moment interval={60000} fromNow locale="de">
              {dueDate}
            </Moment>
          </div>
        );
        return (
          <Chip
            avatar={avatar}
            label={content}
            className={classes.dueChip + ' ' + colorClass}
          />
        );

      case 'finished':
        let latestPayment = getLatestPayment(invoiceData);
        content = <div>Bezahlt</div>;
        return (
          <Chip
            avatar={avatar}
            label={content}
            className={classes.dueChipFinished}
          />
        );
    }
  }

  getInvoicesSearchFiltered() {
    let { search } = this.state;
    return this.getInvoicesSorted().filter(i => {
      let customerData = this.findCustomer(i.data().customer.id).data();
      let fullName = (
        customerData.firstname +
        ' ' +
        customerData.lastname
      ).toLowerCase();
      return (
        fullName.indexOf(search.toLowerCase()) > -1 ||
        customerData.cid.indexOf(search) > -1 ||
        i.data().invoiceId.indexOf(search) > -1
      );
    });
  }

  renderHeader() {
    let { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant="h6" color="secondary">
          Rechnungen
        </Typography>
        <Button
          className={classes.addBtn}
          color="primary"
          onClick={() => {
            gotoInvoiceAdd(this.props.history);
          }}
        >
          <NoteAddIcon className={classes.leftIcon} />
          Neue Rechnung
        </Button>
        <Button
          className={classes.addBtn}
          color="secondary"
          disabled={this.state.syncBankLoading}
          onClick={() => {
            this.syncBankAccount();
          }}
        >
          {this.state.syncBankLoading ? (
            <CircularProgress
              color="secondary"
              className={classes.btnpreloader}
              size={20}
            />
          ) : (
            <AccountBalanceIcon className={classes.leftIcon} />
          )}
          Sync Bankkonto
        </Button>
      </React.Fragment>
    );
  }

  render() {
    const {
      classes,
      theme,
      initialLoadDone,
      initialCustomerLoadDone
    } = this.props;

    if (!initialLoadDone || !initialCustomerLoadDone) {
      return (
        <CircularProgress color="secondary" className="preloader" size={60} />
      );
    }

    return (
      <ContentCard
        content={
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Kunde</TableCell>
                <TableCell>Rechnungsnummer</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Gesamtsumme</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.getInvoicesSearchFiltered().map(i => {
                let d = i.data();
                let c = d.customer
                  ? CustomerModel(this.findCustomer(d.customer.id).data())
                  : null;
                let { color: backgroundColor = 'gray' } = c ? c : {};

                return (
                  <TableRow
                    className={classes.row}
                    key={i.id}
                    onClick={() => gotoInvoice(this.props.history, i.id)}
                  >
                    <TableCell>
                      <div className={classes.usercell}>
                        <Avatar
                          style={{ backgroundColor }}
                          className={classes.avatar}
                        >
                          {c ? c.char : '?'}
                        </Avatar>
                        <div className={classes.username}>
                          {c
                            ? c.firstname + ' ' + c.lastname
                            : 'Kein Kunde ausgewählt'}
                        </div>
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className={classes.invoiceId}>{d.invoiceId}</div>
                    </TableCell>

                    <TableCell>{this.getDueChip(d, classes)}</TableCell>

                    <TableCell>
                      <div className={classes.amount}>
                        {convertToCash(getItemsTotal(d.data.items))} €
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <BankSyncDialog
              onSubmit={this.bankSyncDialogSubmit}
              loading={this.state.syncBankDialogLoading}
              onClose={this.bankSyncDialogClose}
              open={this.state.syncBankDialogOpen}
              transactions={this.state.relevantBankTransactions}
            />
          </Table>
        }
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Invoice));
