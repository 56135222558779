import axios from 'axios';
import FileSaver from 'file-saver';

export default function download(url, fileName) {
  let client = axios.create({
    timeout: 10000
  });

  return new Promise((resolve, reject) => {
    client
      .get(url, { responseType: 'blob' })
      .then(res => {
        FileSaver.saveAs(res.data, fileName);
        resolve();
      })
      .catch(reject);
  });
}
