import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import logo from '../../../img/logo.svg';
import Menu from './Menu';

const styles = theme => ({
  sideNav: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    width: 70,
    transition: 'all ease-in-out 0.35s',
    boxShadow: '#d0d0d0 0px 0px 6px',
    background: 'white'
  },
  expanded: {
    width: 260
  },
  header: {
    height: 64,
    background: theme.palette.secondary.dark,
    display: 'flex'
  },
  img: {
    boxSizing: 'border-box',
    width: 64,
    padding: 18
  },
  text: {
    opacity: 0,
    color: 'white',
    transition: 'opacity 0.25s ease',
    transitionDelay: '0.1s',
    padding: '20px 0'
  },
  visible: {
    opacity: 1
  }
});

class SideNav extends Component {
  state = {
    expanded: false
  };

  mouseleave = e => {
    let elem = document.elementFromPoint(e.clientX, e.clientY);
    if (this.checkIfHasChild(this.refs.me, elem)) {
      return;
    }
    this.reduce();
  };

  checkIfHasChild(parentNode, childNode) {
    if ('contains' in parentNode) {
      return parentNode.contains(childNode);
    } else {
      return parentNode.compareDocumentPosition(childNode) % 16;
    }
  }

  expand = () => {
    this.setState({ expanded: true });
    if (this.props.onExpand) this.props.onExpand();
  };

  reduce = () => {
    if (this.props.forceExpanded) {
      return;
    }
    this.setState({ expanded: false });
    if (this.props.onReduce) this.props.onReduce();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.forceExpanded && !this.props.forceExpanded) {
      this.expand();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.forceExpanded && !this.props.forceExpanded) {
      this.reduce();
    }
  }

  render() {
    let { classes } = this.props;
    let { expanded } = this.state;

    return (
      <div
        ref="me"
        className={classes.sideNav + (expanded ? ' ' + classes.expanded : '')}
        onMouseEnter={this.expand}
        onMouseLeave={this.mouseleave}
      >
        <header className={classes.header}>
          <img className={classes.img} src={logo} />
          <Typography
            variant="h6"
            color="inherit"
            className={classes.text + (expanded ? ' ' + classes.visible : '')}
          >
            MyVoiz.de
          </Typography>
        </header>

        <Menu expanded={expanded} history={this.props.history} />
      </div>
    );
  }
}

export default withStyles(styles)(SideNav);
