import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class ChangeMailDialog extends Component {
  state = {
    loading: false,
    password: '',
    email: '',
    wrongPassErr: false
  };

  onChange = event => {
    this.setState({ email: event.target.value });
  };

  onChangePassword = event => {
    this.setState({ password: event.target.value });
  };

  onClose = () => {
    if (this.state.loading) {
      return;
    }
    this.setState({ password: '', email: '', wrongPassErr: false });
    this.props.onClose();
  };

  onSubmit = () => {
    let { email, password } = this.state;
    this.setState({ loading: true, wrongPassErr: false });
    this.props
      .onAuth(password)
      .then(() => {
        this.setState({ password: '', email: '', loading: false });
        this.props.onSubmit(email);
      })
      .catch(err => {
        this.setState({ loading: false, wrongPassErr: true });
      });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">E-Mail ändern</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Neue E-Mail Addresse"
            type="email"
            fullWidth
            value={this.state.email}
            onChange={this.onChange}
          />
          <TextField
            error={this.state.wrongPassErr}
            margin="dense"
            id="password"
            label="Dein Passwort"
            type="password"
            fullWidth
            value={this.state.password}
            onChange={this.onChangePassword}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.loading}
            onClick={this.onClose}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            disabled={this.state.loading}
            onClick={this.onSubmit}
            color="primary"
          >
            Ändern
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
