import React, { Component } from 'react';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '../default';

export default class KundennummerListItem extends Component {
  render() {
    let kundenummer = Object.assign(
      {
        icon: <PermIdentityIcon />,
        primary: this.props.data,
        placeholder: 'Kundennummer',
        secondary: ''
      },
      this.props
    );

    return <ListItem {...kundenummer} />;
  }
}
