import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import request from '../../../helper/request';

const INITIAL_STATE = {
  email: '',
  password: '',
  encryptedBankCredentials: null,
  accounts: null,
  loading: false,
  step: 0,
  step1err: false,
  selectedAccount: null
};

export default class KontistSetupDialog extends Component {
  state = INITIAL_STATE;

  componentWillReceiveProps(nextProps) {
    if (nextProps.open == true && this.props.open == false) {
      this.setState(INITIAL_STATE);
    }
  }

  connectAccount = () => {
    this.setState({ loading: true, step1err: false });
    request.getClient().then(client => {
      client.__cloudfunctions
        .encryptBankCredentials(
          this.state.email,
          this.state.password,
          'kontist'
        )
        .then(({ data }) => {
          // Got Encrypted Token
          let { encrypted: encryptedBankCredentials } = data;

          // Load Accounts
          client.__cloudfunctions.bank
            .getAccounts(encryptedBankCredentials)
            .then(({ data }) => {
              // Got BankAccounts
              let { accounts } = data;

              if (!accounts || accounts.length === 0) {
                return this.setState({
                  loading: false,
                  step1err: 'Account besitzt keine Konten'
                });
              }
              let selectedAccount = accounts[0].id;
              this.setState({
                encryptedBankCredentials,
                accounts,
                selectedAccount,
                loading: false,
                step: 1
              });
            })
            .catch(err => {
              // Todo: error handling
              this.setState({
                loading: false,
                step1err: 'Login Daten sind inkorrekt.'
              });
            });
        })
        .catch(err => {
          // Todo: error handling
          this.setState({
            loading: false,
            step1err: 'Verschlüsselung der Login-Daten fehlgeschlagen'
          });
        });
    });
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  onSubmit = () => {
    let {
      encryptedBankCredentials,
      selectedAccount: selectedAccountId,
      accounts
    } = this.state;
    let selectedAccount = _.find(accounts, { id: selectedAccountId });
    this.props.onSubmit({
      encryptedBankCredentials,
      selectedAccountId,
      selectedAccount
    });
  };

  render() {
    let content;
    if (this.state.loading) {
      content = (
        <DialogContent>
          <LinearProgress color="secondary" />
        </DialogContent>
      );
    } else if (this.state.step === 0) {
      content = (
        <DialogContent>
          <TextField
            autoFocus
            error={this.state.step1err}
            margin="dense"
            label={this.state.step1err || 'Email Address'}
            type="email"
            value={this.state.email}
            onChange={this.handleChange('email')}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Passwort"
            type="password"
            value={this.state.password}
            onChange={this.handleChange('password')}
            fullWidth
          />
        </DialogContent>
      );
    } else if (this.state.step === 1) {
      content = (
        <DialogContent>
          <FormControl className="fullwidth">
            <InputLabel htmlFor="selected-accoount">Account</InputLabel>
            <Select
              value={this.state.selectedAccount}
              onChange={this.handleChange('selectedAccount')}
              input={<Input name="Account" id="selected-accoount" />}
            >
              {this.state.accounts.map(account => {
                return (
                  <MenuItem key={account.id} value={account.id}>
                    {account.iban + ' (' + account.bankName + ')'}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
      );
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.state.loading ? () => {} : this.props.onCancel}
      >
        <DialogTitle>Mit Kontist verknüpfen</DialogTitle>
        {content}
        <DialogActions>
          <Button
            disabled={this.state.loading}
            onClick={this.props.onCancel}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            disabled={this.state.loading}
            onClick={
              this.state.step === 0 ? this.connectAccount : this.onSubmit
            }
            color="primary"
          >
            {this.state.step === 0 ? 'Weiter' : 'Fertig'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
