const CONSTANTS = {
  firebase: {
    apiKey: 'AIzaSyDdQEABW3iy2yFsZWr6WMRAyx2YGdffHQA',
    authDomain: 'billy-d1e12.firebaseapp.com',
    databaseURL: 'https://billy-d1e12.firebaseio.com/',
    storageBucket: 'billy-d1e12.appspot.com',
    projectId: 'billy-d1e12',
    httpBase: 'https://europe-west1-billy-d1e12.cloudfunctions.net/app'
  },

  gapi: {
    apiKey: 'AIzaSyBqjp2fDs57E_PQl2Koi131XFuajpcf6Uo',
    clientId:
      '182776835076-56m62bo40irjja79dl9sml8g1mhitsi3.apps.googleusercontent.com',
    scope: 'https://www.googleapis.com/auth/drive',
    discoveryDocs: [
      'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
    ]
  },

  googleMap: {
    apiKey: 'AIzaSyBqjp2fDs57E_PQl2Koi131XFuajpcf6Uo',
    libraries: ['places']
  },

  bicCodes: {
    solarisBank: 'SOBKDEBBXXX',
    n26: 'NTSBDEB1XXX'
  }
};

export default CONSTANTS;
export const bicCodes = CONSTANTS.bicCodes;
