import firebase from '../services/firebase';
import ColorHash from 'color-hash';
var colorHash = new ColorHash();

export function CleanupPayload(payload) {
  payload = Object.assign({}, payload);
  Object.keys(payload).forEach(key => {
    if (key.startsWith('__')) {
      delete payload[key];
    }
  });
  return payload;
}

export function CustomerModel(payload = {}) {
  let obj = Object.assign(
    {
      _userid: '',
      cid: '',
      title: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      mobile: '',
      company: '',
      taxnr: '',
      ustidnr: '',
      addresses: [],
      invoices: [],
      payments: [],
      updatedAt: null,
      createdAt: null,
      get color() {
        return colorHash.hex(
          this.email + this.firstname + this.lastname + this.cid
        );
      },
      set color(color) {},
      get char() {
        return this.firstname ? this.firstname.substr(0, 1) : '?';
      },
      set char(color) {}
    },
    payload
  );

  obj._userid = firebase.auth().currentUser.uid;
  obj.addresses = obj.addresses.map(address => {
    return AddressModel(address);
  });

  return obj;
}

export function AddressModel(payload = {}) {
  let obj = Object.assign(
    {
      streetnr: '',
      street: '',
      zip: '',
      city: '',
      country: '',
      description: 'Privat',
      raw: ''
      // get raw(){
      //   return (this.street || this.streetnr) ? this.street + " " + this.streetnr : "";
      // }
    },
    payload
  );

  if (!('raw' in payload)) {
    obj.raw = obj.street || obj.streetnr ? obj.street + ' ' + obj.streetnr : '';
  }

  return obj;
}

export function InvoiceModel(payload = {}) {
  let obj = Object.assign(
    {
      _userid: '',
      renderStatus: null,
      additionalData: {},
      customer: null,
      invoiceId: '',
      invoiceDate: '',
      invoiceDueDays: 0,
      type: 'draft',
      data: {
        customer: {},
        selectedAddress: {},
        items: []
      },
      mails: [],
      thumbnail: false,
      pdf: false,
      updatedAt: null,
      createdAt: null
    },
    payload
  );
  obj._userid = firebase.auth().currentUser.uid;
  return obj;
}
