import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { convertToCash } from '../../helper/calc';

const styles = theme => ({
  root: {
    textAlign: 'center'
  },
  number: {
    textAlign: 'center',
    minWidth: 150,
    display: 'inline-block',
    padding: '30px 0 3px 0',
    fontSize: 36,
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold',
    borderBottom: '4px solid rgba(0, 0, 0, 0.54)'
  },
  description: {
    padding: '10px 0 20px 0',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)'
  }
});

class BigCashNumber extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.number}>
          {convertToCash(this.props.value)} €
        </div>
        <div className={classes.description}>{this.props.title}</div>
      </div>
    );
  }
}

export default withStyles(styles)(BigCashNumber);
