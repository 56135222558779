import React, { Component } from 'react';
import firebase from '../../../services/firebase';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Avatar from '@material-ui/core/Avatar';
import defaultUserLogo from '../../../img/user.png';
import './styles.css';

export default class UserInfo extends Component {
  state = {
    currentUser: firebase.auth().currentUser
  };

  toggleUserMenu() {
    let newOpenState = !this.props.open;
    if (this.props.onToggle) this.props.onToggle(newOpenState);
  }

  render() {
    let logo = this.state.currentUser.photoURL || defaultUserLogo;
    return (
      <div className="user-info">
        <Avatar src={logo} className="avatar" />
        <div className="text-area">
          <Typography
            className="title"
            onClick={this.toggleUserMenu.bind(this)}
          >
            {this.state.currentUser.email}
          </Typography>
          <IconButton
            className="more-button"
            onClick={this.toggleUserMenu.bind(this)}
          >
            {this.props.open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </div>
      </div>
    );
  }
}
