import firebase from '../services/firebase';
import Firestore from '../services/Firestore';

export function fetchStatsAuto(initialCb) {
  return function(dispatch) {
    Firestore.db()
      .collection('stats')
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot(docSnapshot => {
        dispatch({ type: 'FETCH_STATS_AUTO_UPDATE', payload: docSnapshot });
        if (initialCb) {
          initialCb(null, docSnapshot);
          initialCb = null;
        }
      });
  };
}
