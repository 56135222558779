import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

const styles = theme => ({
  wrap: {
    // background: theme.palette.primary.main,
    margin: 10,
    height: 120
  },

  button: {
    display: 'block',
    background: theme.palette.primary.light,
    color: 'white',
    borderRadius: 30,
    height: 60,
    width: '90%',
    margin: '30px auto'
  },

  title: {
    color: 'white',
    fontSize: 18
  }
});

class ActionButton extends Component {
  onChange = key => event => {
    this.props.onChange(key, event.target.value);
  };

  render() {
    const {
      className = '',
      classes,
      title = '',
      onClick = () => {}
    } = this.props;

    if (this.props.hidden) {
      return null;
    }

    return (
      <div className={classes.wrap + (className ? ' ' + className : '')}>
        <ButtonBase onClick={onClick} className={classes.button}>
          <span className={classes.title}>{title}</span>
        </ButtonBase>
      </div>
    );
  }
}

export default withStyles(styles)(ActionButton);
