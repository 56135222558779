import React, { Component } from 'react';
import InvoiceAddBase, {
  mapStateToProps,
  mapDispatchToProps
} from '../../sites/Invoice/add';
import Preloader from '../../components/Preloader';
import Overlay from '../../components/Overlay';
import CircularProgress from '@material-ui/core/CircularProgress';
import grey from '@material-ui/core/colors/grey';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import SelectCustomerCard from '../../components/Cards/SelectCustomerCard';
import InvoiceItemsCard from '../../components/Cards/InvoiceItemsCard';

class InvoiceAdd extends InvoiceAddBase {
  render() {
    // If loading
    if (!this.props.initialLoadDone) {
      return <Preloader />;
    }

    // BackIcon
    let backIcon = <CloseIcon />;

    return (
      <Overlay
        title="Neuer Entwurf"
        backgroundColor={grey[400]}
        backIcon={backIcon}
        onBack={this.goBack.bind(this)}
        rightButtonText={
          this.state.saving ? (
            <CircularProgress size={24} className="button-preloader" />
          ) : (
            'Erstellen'
          )
        }
        rightButtonDisabled={this.state.saving ? true : false}
        rightButtonEvent={this.addAndClose.bind(this)}
        realAppBar
      >
        <SelectCustomerCard
          onChange={this.customerChanged.bind(this)}
          customers={this.props.customers}
        />
        <InvoiceItemsCard onChange={this.itemsChanged.bind(this)} />
      </Overlay>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceAdd);
