import firebase from 'firebase';
import 'firebase/firestore';
import config from '../config/constants';

// Init App
firebase.initializeApp(config.firebase);
firebase.auth().languageCode = 'de';
firebase.firestore().settings({
  timestampsInSnapshots: true
});
// To apply the default browser preference instead of explicitly setting it.
// firebase.auth().useDeviceLanguage();

export default firebase;
export const firebaseAuth = firebase.auth;
