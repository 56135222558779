import React, { Component } from 'react';
import Preloader from '../../components/Preloader';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import { gotoCustomer, gotoCustomerAdd } from '../../helper/transition';
import { CustomerModel } from '../../helper/models';
import ColorHash from 'color-hash';
var colorHash = new ColorHash();

class Customer extends Component {
  openCustomer(id) {
    gotoCustomer(this.props.history, id);
  }

  render() {
    if (!this.props.initialLoadDone) {
      return <Preloader />;
    }

    let lastChar = '';
    let listItems = this.props.customers
      .map(record => {
        return { record, data: CustomerModel(record.data()) };
      })
      .sort((a, b) => {
        if (a.data.char > b.data.char) {
          return 1;
        }
        if (a.data.char < b.data.char) {
          return -1;
        }
        return 0;
      })
      .map(customerObject => {
        console.log(customerObject);
        let { record: customer, data } = customerObject;
        let { color: backgroundColor } = data;
        let currChar = data.char !== lastChar ? data.char : ' ';
        lastChar = data.char;
        return (
          <ListItem
            button
            key={customer.id}
            onClick={this.openCustomer.bind(this, customer.id)}
          >
            <Avatar className="char-overview-avatar">{currChar}</Avatar>
            <Avatar style={{ backgroundColor }}>{data.char}</Avatar>
            <ListItemText
              primary={data.firstname + ' ' + data.lastname}
              secondary={'Kundenummer: ' + data.cid}
            />
          </ListItem>
        );
      });

    return (
      <div>
        <List>{listItems}</List>
        <Fab
          onClick={() => gotoCustomerAdd(this.props.history)}
          className="default-fab"
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    initialLoadDone: state.customer.initialLoadDone,
    customers: state.customer.customers
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customer);
