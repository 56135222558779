import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { checkPasswordSafe } from '../../../helper/calc';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class DefaultInputDialog extends Component {
  onChange = e => {
    return this.props.onChange && this.props.onChange(e.target.value);
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          {this.props.description ? (
            <DialogContentText>{this.props.description}</DialogContentText>
          ) : null}
          <TextField
            autoFocus
            error={this.props.error}
            label={this.props.label || ''}
            placeholder={this.props.placeholder || ''}
            type={this.props.type || 'text'}
            fullWidth
            value={this.props.value || ''}
            onChange={this.onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.props.loading}
            onClick={this.props.onCancel}
            color="primary"
          >
            {this.props.cancelText || 'Cancel'}
          </Button>
          <Button
            disabled={this.props.loading}
            onClick={this.props.onSubmit}
            color="primary"
          >
            {this.props.submitText || 'Okay'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
