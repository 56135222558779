import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import logo from '../../img/logo.svg';

export default function loggedInAsNotRegisteredUserContent(classes) {
  return (
    <div className={classes.box}>
      <img className={classes.logo} src={logo} />
      <h1 className={classes.headline}>MyVoiz</h1>

      <h3 className={classes.hlLight}>Setze ein Password</h3>

      {this.state.realAccountError ? (
        <div className="login-error">{this.state.realAccountError || ''}</div>
      ) : null}

      <TextField
        id="raccpassword"
        value={this.state.realAccountPassword || ''}
        className={classes.textfield}
        label="Passwort"
        type="password"
        onChange={this.changeRealAccountPassword.bind(this)}
        margin="normal"
        error={this.state.realAccountError ? true : false}
      />
      <TextField
        id="raccpasswordrepeat"
        value={this.state.realAccountPasswordRepeat || ''}
        className={classes.textfield}
        label="Passwort wiederholen"
        type="password"
        onChange={this.changeRealAccountPasswordRepeat.bind(this)}
        margin="normal"
        error={this.state.realAccountError ? true : false}
      />

      <br />
      <br />
      <Button
        disabled={this.state.realAccountLoading}
        className={classes.loginButton}
        onClick={this.createRealAccount.bind(this)}
      >
        {this.state.realAccountLoading ? '...' : 'Registrieren'}
      </Button>
      <div className={classes.registeracccontainer}>
        <Button
          onClick={this.logoutAndDelete.bind(this)}
          disabled={this.state.realAccountLoading}
          color="secondary"
          className={classes.forgotPasswd}
        >
          Logout
        </Button>
      </div>
    </div>
  );
}
