import React, { Component } from 'react';
import _ from 'lodash';
import InvoiceTemplateBase from '../../sites/Settings/invoice-template';
import Firestore from '../../services/Firestore';
import Overlay from '../../components/Overlay';
import Preloader from '../../components/Preloader';
import InvoiceTemplateCard from '../../components/Cards/InvoiceTemplateCard';
import Settings from '../../services/Settings';

export default class InvoiceTemplate extends InvoiceTemplateBase {
  render() {
    let { initalLoadDone, templates } = this.state,
      content;

    if (!initalLoadDone) {
      content = <Preloader />;
    } else {
      content = (
        <div>
          {templates.map(template => {
            let d = template.data();
            return (
              <InvoiceTemplateCard
                used={this.isSelected(template)}
                key={template.id}
                img={d.thumbnail}
                title={d.title}
                description={d.description}
                onActivate={this.onActivate(template)}
                loading={this.state.loading}
              />
            );
          })}
        </div>
      );
    }

    return (
      <Overlay title="Rechnungsvorlage" onBack={this.goBack} realAppBar>
        {content}
      </Overlay>
    );
  }
}
