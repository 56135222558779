import React, { Component } from 'react';
import { connect } from 'react-redux';
import Preloader from '../../components/Preloader';
import Overlay from '../../components/Overlay';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import grey from '@material-ui/core/colors/grey';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as LI from '../../components/ListItem';
import AddressList from '../../components/AddressList';
import Input from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CustomerEditBase, {
  mapStateToProps,
  mapDispatchToProps
} from '../../sites/Customer/edit';

class CustomerEdit extends CustomerEditBase {
  render() {
    // If loading
    if (!this.props.initialLoadDone) {
      return <Preloader />;
    }

    // Get Customer
    let customer =
      this.state.mode === 'edit' ? this.state.customer : this.state.newData;

    // If not found
    if (!customer && this.props.initialLoadDone) {
      return (
        <Redirect
          to={{ pathname: '/404', state: { from: this.props.location } }}
        />
      );
    } else if (!customer) {
      return <Preloader />;
    }
    let data = this.state.mode === 'edit' ? customer.data() : customer;

    // BackIcon
    let backIcon = <CloseIcon />;

    let savePreloader = this.state.saving && (
      <CircularProgress size={24} className="button-preloader" />
    );

    // Buttons
    let rightButtons = [
      <div key="save" className="button-preloader-wrapper">
        <Button
          disabled={this.state.saving}
          onClick={
            this.state.mode === 'edit'
              ? this.saveAndClose.bind(this)
              : this.addAndClose.bind(this)
          }
          className="white-btn"
        >
          {this.state.mode === 'edit' ? 'Speichern' : 'Hinzufügen'}
        </Button>
        {savePreloader}
      </div>
    ];

    if (this.state.mode === 'edit') {
      rightButtons.push(
        <IconButton onClick={this.openMenu} className="white-btn" key="more">
          <MoreVertIcon />
        </IconButton>,
        <Menu
          key="more-menu"
          id="customer-menu"
          anchorEl={this.state.menuAnchor}
          open={this.state.menuOpen}
          onClose={this.closeMenu}
        >
          <MenuItem
            disabled={this.state.deleting}
            onClick={this.deleteCustomer}
          >
            {this.state.deleting ? 'Löschen...' : 'Löschen'}
          </MenuItem>
        </Menu>
      );
    }

    return (
      <Overlay
        backgroundColor={grey[400]}
        backIcon={backIcon}
        onBack={this.goBack.bind(this)}
        rightButtons={rightButtons}
      >
        <List subheader={<ListSubheader disableSticky>Kunde</ListSubheader>}>
          <LI.KundennummerListItem
            data={data.cid}
            editMode
            onChange={this.handleChange('cid')}
          />
          <LI.TitleListItem
            data={data.title}
            editMode
            onChange={this.handleChange('title')}
          />
          <LI.FirstnameListItem
            data={data.firstname}
            editMode
            onChange={this.handleChange('firstname')}
          />
          <LI.LastnameListItem
            data={data.lastname}
            editMode
            onChange={this.handleChange('lastname')}
          />
          <LI.CompanyListItem
            data={data.company}
            editMode
            onChange={this.handleChange('company')}
          />
          <LI.EmailListItem
            data={data.email}
            editMode
            onChange={this.handleChange('email')}
          />
          <LI.PhoneListItem
            data={data.phone}
            editMode
            onChange={this.handleChange('phone')}
          />
          <LI.MobileListItem
            data={data.mobile}
            editMode
            onChange={this.handleChange('mobile')}
          />
        </List>
        <Divider />
        <AddressList
          addresses={data.addresses}
          editMode
          onChange={this.handleChange('addresses')}
        />
      </Overlay>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerEdit);
