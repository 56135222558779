export default () => ({
  image: {
    display: 'block',
    width: 300,
    height: 300,
    borderRadius: '50%',
    margin: '60px auto 40px auto'
  },

  h1: {
    textAlign: 'center',
    fontSize: 24
  },

  // h2: {
  //   textAlign: 'center',
  //   fontSize: 18,
  //   fontWeight: 'normal',
  //   marginBottom: 30
  // }
  h2: {
    textAlign: 'center',
    fontSize: 16,
    lineHeight: '28px',
    fontWeight: 'normal'
  }
});
