import React, { Component } from 'react';
import ListItem from './default';
import _KundennummerListItem from './KundennummerListItem';
import _EmailListItem from './EmailListItem';
import _PhoneListItem from './PhoneListItem';
import _MobileListItem from './MobileListItem';
import _TitleListItem from './TitleListItem';
import _FirstnameListItem from './FirstnameListItem';
import _LastnameListItem from './LastnameListItem';
import _CompanyListItem from './CompanyListItem';

export default ListItem;
export const KundennummerListItem = _KundennummerListItem;
export const EmailListItem = _EmailListItem;
export const PhoneListItem = _PhoneListItem;
export const MobileListItem = _MobileListItem;
export const TitleListItem = _TitleListItem;
export const FirstnameListItem = _FirstnameListItem;
export const LastnameListItem = _LastnameListItem;
export const CompanyListItem = _CompanyListItem;
