import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LoginBase from '../../sites/Login';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import loginContent from './login';
import registerContent from './register';
import resetPasswordContent from './resetPassword';
import resetPasswordDoneContent from './resetPasswordDone';
import loggedInAsNotRegisteredUserContent from './loggedInAsNotRegisteredUser';
import unverifiedContent from './unverified';

const styles = theme => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
    padding: '10vh 0 20px 0',
    boxSizing: 'border-box'
  },

  hlLight: {
    color: theme.palette.secondary.main
  },

  box: {
    position: 'relative',
    background: 'white',
    margin: '0 auto',
    width: '340px',
    borderRadius: '4px',
    padding: '60px 40px 20px 40px',
    textAlign: 'center'
  },

  logo: {
    width: '40px'
  },

  socialButton: {
    width: '60px !important',
    height: '60px !important',
    borderRadius: '50%',
    padding: '15px',
    margin: '0 15px'
  },

  spinner: {
    position: 'absolute',
    top: 'calc(50% - 30px)',
    left: 'calc(50% - 30px)'
  },

  socialButtonImg: {
    width: '30px',
    height: '30px',
    margin: '0'
  },

  gplus: {
    background: 'transparent'
  },

  fb: {
    background: 'transparent'
  },

  headline: {
    fontSize: '16px',
    color: theme.palette.secondary.main,
    marginBottom: '55px'
  },

  textfield: {
    marginBottom: '25px',
    width: '100%'
  },

  loginButton: {
    width: '100%',
    fontSize: 18,
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.primary.light
    }
  },

  forgotPasswd: {
    textTransform: 'none'
  },

  registeracccontainer: {
    marginTop: '20px'
  },

  optcontainer: {
    marginTop: '-10px',
    marginBottom: '30px',
    textAlign: 'right'
  }
});

class Login extends LoginBase {
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    let { classes } = this.props;
    let content;
    let currentStatus = this.getCurrentStatus();

    switch (currentStatus) {
      case this.status.loading:
        content = (
          <div className={classes.box}>
            <CircularProgress
              color="secondary"
              className={classes.spinner}
              size={60}
            />
          </div>
        );
        break;

      case this.status.login:
        content = loginContent.call(this, classes);
        break;

      case this.status.register:
        content = registerContent.call(this, classes);
        break;

      case this.status.resetPassword:
        content = resetPasswordContent.call(this, classes);
        break;

      case this.status.resetPasswordDone:
        content = resetPasswordDoneContent.call(this, classes);
        break;

      case this.status.loggedInAsNotRegisteredUser:
        content = loggedInAsNotRegisteredUserContent.call(this, classes);
        break;

      case this.status.unverified:
        content = unverifiedContent.call(this, classes);
        break;
    }

    return <div className={classes.wrapper}>{content}</div>;
  }
}

export default withStyles(styles, { withTheme: true })(Login);
