import React, { Component } from 'react';
import Settings from '../../../services/Settings';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const styles = theme => ({
  card: {
    cursor: 'pointer',
    position: 'relative',
    height: '120px',
    color: theme.palette.primary.main
  },

  icon: {
    position: 'absolute',
    left: 10,
    top: 10,
    width: 32,
    height: 32,
    padding: 10,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: 'white'
  },

  title: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    fontSize: 18,
    right: 15,
    bottom: 10
  },

  number: {
    color: theme.palette.primary.main,
    position: 'absolute',
    fontSize: 45,
    right: 15,
    top: 10
  }
});

class IconNumberCard extends Component {
  state = {
    hover: false
  };

  onChange = key => event => {
    this.props.onChange(key, event.target.value);
  };

  onHover = () => {
    this.setState({ hover: true });
  };

  onHoverEnd = () => {
    this.setState({ hover: false });
  };

  render() {
    const { hover } = this.state;
    const {
      theme,
      className = '',
      classes,
      icon: Icon,
      number = '',
      title = '',
      color = theme.palette.primary.main,
      onClick = () => {}
    } = this.props;

    if (this.props.hidden) {
      return null;
    }

    let colorStyle = hover ? { color: 'white' } : color ? { color } : {};
    let bgColorStyle = hover
      ? { background: 'white', color }
      : color
        ? { background: color }
        : {};
    let cardStyle = hover ? { background: color } : {};
    let titleStyle = hover ? { color: 'white' } : {};

    return (
      <Card
        onClick={onClick}
        style={cardStyle}
        onMouseOver={this.onHover}
        onMouseOut={this.onHoverEnd}
        className={
          'simple-card ' + classes.card + (className ? ' ' + className : '')
        }
      >
        <CardContent className={classes.text}>
          {Icon ? <Icon style={bgColorStyle} className={classes.icon} /> : null}
          <span style={colorStyle} className={classes.number}>
            {number}
          </span>
          <span style={titleStyle} className={classes.title}>
            {title}
          </span>
        </CardContent>
      </Card>
    );
  }
}

export default withTheme()(withStyles(styles)(IconNumberCard));
