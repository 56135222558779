import React, { Component } from 'react';
import Preloader from '../../components/Preloader';
import Overlay from '../../components/Overlay';
import CustomerInvoiceList from '../../components/CustomerInvoiceList';
import {
  KundennummerListItem,
  EmailListItem,
  PhoneListItem,
  MobileListItem
} from '../../components/ListItem';
import AddressList from '../../components/AddressList';
import DeleteModal from '../../components/Modal/DeleteModal';
import { Redirect } from 'react-router-dom';
import { deleteCustomer } from '../../actions/customer';
import { connect } from 'react-redux';
import { gotoCustomers, gotoCustomerEdit } from '../../helper/transition';
import { generateCustomerVCard } from '../../helper/vcard';

export default class CustomerDetailsBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuAnchor: null,
      menuOpen: false,
      deleteModalOpen: false,
      deleting: false
    };
  }

  componentWillMount() {
    if (this.props.initialLoadDone) {
      this.updateStateCustomer(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialLoadDone) {
      this.updateStateCustomer(nextProps);
    }
  }

  deleteCustomer = event => {
    this.setState({ deleting: true, deleteModalOpen: false });
    this.props.dispatch(
      deleteCustomer(this.state.customer.id, err => {
        if (err) {
          this.setState({ deleting: false });
        } else {
          gotoCustomers(this.props.history);
        }
      })
    );
  };

  exportCustomer = event => {
    // WEB SHARE API
    let data = generateCustomerVCard(this.state.customer.data());
    window.download(data, this.state.customer.id + '.vcf', 'text/x-vcard');
  };

  openMenu = event => {
    this.setState({
      menuAnchor: event.currentTarget,
      menuOpen: true
    });
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  updateStateCustomer(props) {
    // Search Customer and set to state.customer
    let customer = props.customers.find(customer => {
      return customer.id === props.match.params.id;
    });
    if (customer) {
      this.setState({ customer });
    } else {
      // Customer does not exists bro :P todo: some kinda fallback
    }
  }

  goBack() {
    gotoCustomers(this.props.history, { animate: true });
  }
}

export function mapStateToProps(state, ownProps) {
  return {
    initialLoadDone: state.customer.initialLoadDone,
    customers: state.customer.customers
  };
}

export function mapDispatchToProps(dispatch) {
  return { dispatch };
}
